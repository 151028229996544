import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../Assets/Styles/pages/login.css';
import { Form, Input, message, Alert } from 'antd';
import logoBH from '../Assets/Images/bhtrade.png';
import logoBPMS from '../Assets/Images/Logo-BPMS-4x-white-background.webp';
import logoPromoDreams from '../Assets/Images/promodreams.png';
import logoPromoLife from '../Assets/Images/logo-promolife.png';
import logoTradeMarket from '../Assets/Images/trademarket.png';
import Swal from 'sweetalert2';
import clienteAxios from '../Utils/clienteAxios';
import setToken from '../Utils/setToken';
import Boton from '../Components/Atoms/Boton';
import decodeToken from '../Utils/decodeToken';
const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [sucess, setSuccess] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  function usuario(event) {
    const value = event.target.value;
    setUsername(value);
  }
  //Comentario paar deploy
  function contraseña(event) {
    const value = event.target.value;
    setPassword(value);
  }
  function iniciarSesion() {
    setIsButtonDisabled(true);
    clienteAxios
      .post('/login', { email: username, password: password })
      .then((response) => {
        //Decode token
        let decodedToken = decodeToken(response.data.access_token);
        localStorage.setItem('token', response.data.access_token);
        message.success(`Bienvenido ${decodedToken.user.name}`);
        setToken(response.data.access_token);
        setSuccess(true);
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.log(error.response.data.message || error.response.data.msg);
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: error.response.data.message || error.response.data.msg,
          confirmButtonText: 'ACEPTAR'
        }).then(() => {
          navigate('/login');
        });
      });
  }
  return (
    <>
      {sucess ? (
        <Navigate to="/" />
      ) : (
        <div className="pagina-login">
          <div className="container-login">
            <div className="contenedor-logos">
              <img className="logo-bhtrade" src={logoBH} alt="Logo bhtrade" />
              <img className="logo-promodreams ml-2" src={logoPromoDreams} alt="Logo promodreams" />
              <img className="logo-trademarket ml-2" src={logoTradeMarket} alt="Logo trademarket" />
              <img className="logo-promolife ml-2" src={logoPromoLife} alt="Logo promolife" />
            </div>
            {localStorage.getItem('token') && (
              <Alert
                message="Tu sesión ha expirado, ingresa de nuevo."
                type="error"
                showIcon
                className="mt-4 mb-4"
                closable
              />
            )}
            <div className="wrapper-form-login  mt-4">
              <div className="wrapper-logo-bpms">
                <img className="logobpms" src={logoBPMS} alt="Logo bpms" />
              </div>
              <Form onFinish={iniciarSesion}>
                <div className="mt-2">
                  <span className="textcorreo">Correo</span>
                  <div>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: '¡Porfavor ingresa una direccion valida!'
                        }
                      ]}>
                      <Input
                        className="cajacorreo"
                        type="email"
                        placeholder={'Correo@mail.com'}
                        name="username"
                        onChange={usuario}
                        value={username}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="mt-3">
                  <span className="textcontra">Contraseña</span>
                  <div>
                    <Form.Item>
                      <Input.Password
                        className="cajacontraseña"
                        type="password"
                        placeholder="*******"
                        name="password"
                        onChange={contraseña}
                        value={password}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="conten-btn-iniciar-sesion mt-5 mb-4">
                  <Boton type="primary" block htmlType="submit" loading={isButtonDisabled}>
                    INICIAR SESIÓN
                  </Boton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Login;
