import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

const ReusableModal = ({
  title,
  className,
  onOk,
  footer,
  children,
  open,
  okText,
  onCancel,
  cancelText,
  width,
  style
}) => {
  return (
    <Modal
      cancelButtonProps={{
        style: {
          borderColor: 'red',
          color: 'red',
          background: 'transparent',
          hover: {
            background: 'red',
            color: 'white'
          }
        }
      }}
      title={title}
      onOk={onOk}
      className={className}
      onCancel={onCancel}
      open={open}
      centered={true}
      footer={footer}
      okText={okText || 'Confirmar'}
      cancelText={cancelText || 'Cancelar'}
      width={width}
      style={style}>
      {children}
    </Modal>
  );
};

ReusableModal.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onOk: PropTypes.func,
  open: PropTypes.bool,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  width: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.any,
  footer: PropTypes.any
};

export default ReusableModal;
