import { LoadingOutlined } from '@ant-design/icons';
import { Affix, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const Loading = ({ loading }) => {
  return (
    <>
      <Affix
        style={{
          position: 'absolute',
          top: -1,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: '10000',
          display: loading ? 'block' : 'none'
        }}>
        <Spin
          tip="Cargando..."
          className="flex flex-column justify-content-center align-items-center"
          style={{
            backgroundColor: '#bdbdbd8a',
            width: '100%',
            height: '100%',
            fontSize: '1.5rem'
          }}
          indicator={<LoadingOutlined style={{ fontSize: '3rem' }} />}
          spinning={loading}
        />
      </Affix>
    </>
  );
};

Loading.defaultProps = {
  loading: true
};

Loading.propTypes = {
  loading: PropTypes.bool
};

export default Loading;
