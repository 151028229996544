import { useEffect, useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import clienteAxios from '../../Utils/clienteAxios';
import { Col, Row, Table, Tag } from 'antd';
import parseDate from '../../Utils/parseDate';
import moment from 'moment';

const ModalHistoryCoutProduct = ({
  isOpenModalHistoryConfirmation,
  setModalHistoryConfirmation,
  dataHistoryConfirmation
}) => {
  const [loading, setLoading] = useState('');
  const [informationRecep, setInformationRecep] = useState([]);

  useEffect(() => {
    if (Object.keys(dataHistoryConfirmation).length !== 0) {
      setLoading(true);
      clienteAxios
        .get(`product/count/confirmation/history/${dataHistoryConfirmation?.idProdutc}`)
        .then((response) => {
          setInformationRecep(response?.data?.Product_count_history?.reverse());
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpenModalHistoryConfirmation]);

  const handleCancelHistoryConfirmation = () => {
    setModalHistoryConfirmation(false);
  };

  const columsHistory = [
    {
      title: 'ALMACÉN',
      dataIndex: 'almacen',
      key: 'almacen'
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: 'OBSERVACIONES',
      dataIndex: 'observaciones',
      key: 'observaciones'
    },
    {
      title: 'FECHA DE RECEPCIÓN',
      dataIndex: 'fecha_recepcion',
      key: 'fecha_recepcion'
    }
  ];

  const data = informationRecep.map((item, index) => ({
    key: index,
    almacen: item?.type,
    tipo: (
      <>
        <Tag color={item?.confirmation_type === 'Total' ? 'green' : 'orange'}>
          {item?.confirmation_type}
        </Tag>
      </>
    ),
    observaciones: item?.observation || 'Sin observaciones',
    fecha_recepcion: (
      <>
        <Row>
          <Col xs={24} md={12}>
            {parseDate(item?.created_at, 'short')}
          </Col>
          <Col xs={24} md={12}>
            {moment(item?.created_at).format('LT  ')}
          </Col>
        </Row>
      </>
    )
  }));

  return (
    <ReusableModal
      width={900}
      open={isOpenModalHistoryConfirmation}
      title="HISTORIAL CONTEO DE PRODUCTO"
      onCancel={handleCancelHistoryConfirmation}>
      <Table loading={loading} columns={columsHistory} dataSource={data} pagination={false}></Table>
    </ReusableModal>
  );
};

ModalHistoryCoutProduct.propTypes = {
  setModalHistoryConfirmation: PropTypes.func,
  isOpenModalHistoryConfirmation: PropTypes.bool,
  dataHistoryConfirmation: PropTypes.object
};

export default ModalHistoryCoutProduct;
