import React from 'react';
import { Alert, Space } from 'antd';
const onClose = (e) => {
  console.log(e, 'I was closed.');
};
const Notifications = () => (
  <div>
    <h1>Notificaciones</h1>
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
        <Alert
          message="Notifications"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
          onClose={onClose}
        />
      </Space>
    </>
  </div>
);

export default Notifications;
