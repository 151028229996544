import { Button, Col, InputNumber, Row, Table, Form } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Firma from './Firma';
import { useState } from 'react';
import ReusableModal from '../Atoms/Modal';

const OptionsInspection = ({
  firmaEmpaque,
  setFirmaEmpaque,
  firmaLogistica,
  setFirmaLogistica,
  firmaRecepcion,
  setFirmaRecepcion,
  idInspeccion
}) => {
  const [modalFirma, setModalFirma] = useState(false);
  const [dataSignature, setDataSignature] = useState('');

  const descriptionRecepcion = [
    {
      descripcion: 'Mercancía con defecto'
    },
    {
      descripcion: 'Color o Pantone incorrecto'
    },
    {
      descripcion: 'Daño en logo'
    },
    {
      descripcion: 'Logo incorrecto'
    },
    {
      descripcion: 'Piezas incompletas'
    },
    {
      descripcion: 'Mercancía no combinada'
    }
  ];

  const descriptionEmpaque = [
    {
      descripcion: 'Mercancía con defecto'
    },
    {
      descripcion: 'Mercancía no combinada'
    },
    {
      descripcion: 'Piezas incompletas'
    },
    {
      descripcion: 'Caja master no correcta'
    },
    {
      descripcion: 'Encintado incorrecto de caja'
    },
    {
      descripcion: 'corrugado con defecto'
    }
  ];

  const descripcionLogistica = [
    {
      descripcion: 'Cajas completas'
    },
    {
      descripcion: 'Caja master con defecto'
    },
    {
      descripcion: 'Etiqueta de identificación incorrecta'
    },
    {
      descripcion: 'Estiba inadecuada a peso'
    },
    {
      descripcion: 'Documentación incorrecta'
    },
    {
      descripcion: 'Mercancia incorrecta vs factura'
    }
  ];

  return (
    <>
      <Row>
        <Col xs={24} md={{ span: 8, offset: 3 }}>
          <div>
            <div className="flex justify-content-between">
              <h3>Inspección Recepción</h3>
              <div>
                {idInspeccion === undefined ? (
                  firmaRecepcion !== undefined && firmaRecepcion !== '' ? (
                    <Button
                      icon={<EyeOutlined />}
                      type="link"
                      onClick={() => {
                        setModalFirma(true);
                        setDataSignature(firmaRecepcion);
                      }}
                    />
                  ) : null
                ) : null}

                <Firma
                  button={true}
                  none={true}
                  recepcion={true}
                  firmaRecepcion={firmaRecepcion}
                  setFirmaRecepcion={setFirmaRecepcion}
                />
              </div>
            </div>
            <Table
              className="mt-3"
              columns={[
                {
                  title: 'DESCRIPCIÓN',
                  dataIndex: 'descripcion',
                  key: 'descripcion',
                  fixed: 'left'
                },
                {
                  title: 'CANTIDAD',
                  dataIndex: 'cantidad',
                  key: 'cantidad',
                  render: (text, record, index) => (
                    <Form.Item
                      name={`recepcion${index}`}
                      initialValue={0}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresa la cantidad!'
                        }
                      ]}>
                      <InputNumber min={0} className="centered-input" />
                    </Form.Item>
                  )
                }
              ]}
              dataSource={descriptionRecepcion.map((element, index) => ({
                ...element,
                key: index
              }))}
              pagination={false}
              scroll={{ x: 300 }}
            />
            {idInspeccion !== undefined ? (
              firmaRecepcion !== null ? (
                <div className="flex justify-content-center text-align-center align-items-center mt-2">
                  <div style={{ border: '1px solid #000', padding: '10px', width: '30%' }}>
                    <img src={firmaRecepcion} alt="firma" />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Col>
        <Col xs={24} md={{ span: 8, offset: 3 }}>
          <div>
            <div className="flex justify-content-between">
              <h3>Inspección Empaque</h3>
              <div>
                {idInspeccion === undefined ? (
                  firmaEmpaque !== undefined && firmaEmpaque !== '' && firmaEmpaque !== null ? (
                    <Button
                      icon={<EyeOutlined />}
                      type="link"
                      onClick={() => {
                        setModalFirma(true);
                        setDataSignature(firmaEmpaque);
                      }}
                    />
                  ) : null
                ) : null}
                <Firma
                  button={true}
                  none={true}
                  empaque={true}
                  firmaEmpaque={firmaEmpaque}
                  setFirmaEmpaque={setFirmaEmpaque}
                />
              </div>
            </div>
            <Table
              className="mt-3"
              columns={[
                {
                  title: 'DESCRIPCIÓN',
                  dataIndex: 'descripcion',
                  key: 'descripcion',
                  fixed: 'left'
                },
                {
                  title: 'CANTIDAD',
                  dataIndex: 'cantidad',
                  key: 'cantidad',
                  render: (text, record, index) => (
                    <>
                      {/* <Form form={form}> */}
                      <Form.Item
                        name={`empaque${index}`}
                        initialValue={0}
                        rules={[
                          {
                            required: true,
                            message: 'Por favor indica el tipo de producto!'
                          }
                        ]}>
                        <InputNumber min={0} className="centered-input" />
                      </Form.Item>
                      {/* </Form> */}
                    </>
                  )
                }
              ]}
              dataSource={descriptionEmpaque.map((element, index) => ({
                ...element,
                key: index
              }))}
              pagination={false}
              scroll={{ x: 300 }}
            />
            {idInspeccion !== undefined ? (
              firmaEmpaque !== null ? (
                <div className="flex justify-content-center text-align-center align-items-center mt-2">
                  <div style={{ border: '1px solid #000', padding: '10px', width: '30%' }}>
                    <img src={firmaEmpaque} alt="firma" />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={24} md={{ span: 8, offset: 8 }}>
          <div>
            <div className="flex justify-content-between">
              <h3>Inspección Logistica</h3>
              <div>
                {idInspeccion === undefined ? (
                  firmaLogistica !== undefined && firmaLogistica !== '' ? (
                    <Button
                      icon={<EyeOutlined />}
                      type="link"
                      onClick={() => {
                        setModalFirma(true);
                        setDataSignature(firmaLogistica);
                      }}
                    />
                  ) : null
                ) : null}
                <Firma
                  button={true}
                  none={true}
                  logistica={true}
                  firmaLogistica={firmaLogistica}
                  setFirmaLogistica={setFirmaLogistica}
                />
              </div>
            </div>

            <Table
              className="mt-3"
              columns={[
                {
                  title: 'DESCRIPCIÓN',
                  dataIndex: 'descripcion',
                  key: 'descripcion',
                  fixed: 'left'
                },
                {
                  title: 'CANTIDAD',
                  dataIndex: 'cantidad',
                  key: 'cantidad',
                  render: (text, record, index) => (
                    <>
                      <Form.Item
                        name={`logistica${index}`}
                        initialValue={0}
                        rules={[
                          {
                            required: true,
                            message: 'Por favor indica el tipo de producto!'
                          }
                        ]}>
                        <InputNumber min={0} className="centered-input" />
                      </Form.Item>
                    </>
                  )
                }
              ]}
              dataSource={descripcionLogistica.map((element, index) => ({
                ...element,
                key: index
              }))}
              pagination={false}
              scroll={{ x: 300 }}
            />
            {idInspeccion !== undefined ? (
              firmaLogistica !== null ? (
                <div className="flex justify-content-center text-align-center align-items-center mt-2">
                  <div style={{ border: '1px solid #000', padding: '10px', width: '30%' }}>
                    <img src={firmaLogistica} alt="firma" />
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Col>
      </Row>

      <ReusableModal
        title="FIRMA"
        open={modalFirma}
        onCancel={() => setModalFirma(false)}
        width={400}
        footer={null}>
        <div style={{ border: '1px solid #000', padding: '10px' }}>
          <img src={dataSignature} alt="firma" />
        </div>
      </ReusableModal>
    </>
  );
};

OptionsInspection.propTypes = {
  setDescriptionRecepcion: PropTypes.func,
  setDescriptionEmpaque: PropTypes.func,
  setDescriptionLogistica: PropTypes.func,
  firmaRecepcion: PropTypes.string,
  setFirmaRecepcion: PropTypes.func,
  firmaEmpaque: PropTypes.string,
  setFirmaEmpaque: PropTypes.func,
  firmaLogistica: PropTypes.string,
  setFirmaLogistica: PropTypes.func,
  inspection_receipts: PropTypes.object,
  quantityEmpaque: PropTypes.number,
  setQuantityEmpaque: PropTypes.func,
  quantityLogistica: PropTypes.number,
  setQuantityLogistica: PropTypes.func,
  idInspeccion: PropTypes.string
};

export default OptionsInspection;
