const rolessg = [
  'equipo_administracion',
  'equipo_almacen',
  'equipo_calidad',
  'equipo_compras',
  'equipo_direccion',
  'equipo_diseno_operativo',
  'equipo_importaciones',
  'equipo_logistica',
  'equipo_mesa_control',
  'equipo_produccion',
  'equipo_recursos_humanos',
  'equipo_ti',
  'equipo_ventas_bh',
  'equipo_ventas_pl',
  'sin_definir',
  'Super_Admin',
  'marketing_pl'
];

export default rolessg;
