import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import CardModule from '../Components/Molecules/CardModule';
import {
  CarOutlined,
  ContainerFilled,
  PlayCircleOutlined,
  ShoppingFilled,
  TeamOutlined
} from '@ant-design/icons';
import decodeToken from '../Utils/decodeToken';

const Inicio = () => {
  const [loading, setLoading] = useState(true);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let isArea = decodedToken?.user?.display_name_area;
  let isRole = decodedToken?.role?.name;
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, '1000');
  }, []);

  const validGestionPedido = [
    'ventas_bh',
    'ventas_pl',
    'compras',
    'sgcmc',
    // 'muestras',
    'mesa_de_control',
    'almacen',
    'calidad'
  ];

  const validRutasEntrega = [
    'compras',
    'sgcmc',
    // 'muestras',
    'logistica',
    'mesa_de_control'
  ];

  return (
    <>
      <Spin spinning={loading} tip="Cargando...">
        <div className="align-items-center justify-content-center text-align-center">
          <h1>Modulos</h1>
        </div>
        {isRole === 'Super_Admin' || isArea === 'sgcmc' || decodedToken?.sub === 280 ? (
          <>
            <div className="cards-container mt-4">
              {(decodedToken?.user?.display_name_area === 'direccion' &&
                decodedToken?.role?.name !== 'equipo_direccion') ||
              (decodedToken?.user?.display_name_area === 'ventas' &&
                decodedToken?.role?.name === 'equipo_direccion') ||
              isRole === 'Super_Admin' ||
              validGestionPedido.includes(decodedToken?.user?.display_name_area) ? (
                <CardModule
                  link="/pedidos"
                  name="Gestión de Pedidos"
                  logo={<ContainerFilled style={{ fontSize: '2rem' }} />}
                />
              ) : null}

              {validRutasEntrega.includes(decodedToken?.user?.display_name_area) ||
              isRole === 'Super_Admin' ? (
                <CardModule
                  link="/rutas-de-entrega"
                  name="Gestión de Rutas"
                  logo={<CarOutlined style={{ fontSize: '2rem' }} />}
                />
              ) : null}

              <CardModule
                link="/solicitudes-gasto"
                name="Solicitud de Gastos"
                logo={<ShoppingFilled style={{ fontSize: '2rem' }} />}
              />
              {isRole === 'Super_Admin' ? (
                <CardModule
                  link="/users"
                  name="Usuarios"
                  logo={<TeamOutlined style={{ fontSize: '2rem' }} />}
                />
              ) : null}

              <CardModule
                link="/capacitacion"
                name="Capacitación"
                logo={<PlayCircleOutlined style={{ fontSize: '2rem' }} />}
              />
            </div>
          </>
        ) : (
          <>
            <div className="cards-container mt-4">
              <CardModule
                link="/solicitudes-gasto"
                name="Solicitud de Gastos"
                logo={<ShoppingFilled style={{ fontSize: '2rem' }} />}
              />
              <CardModule
                link="/capacitacion"
                name="Capacitación"
                logo={<PlayCircleOutlined style={{ fontSize: '2rem' }} />}
              />
            </div>
          </>
        )}
      </Spin>
    </>
  );
};

export default Inicio;
