import React, { useEffect } from 'react';
import {
  Radio,
  Input,
  Form,
  Space,
  Select,
  DatePicker,
  Alert,
  message,
  ConfigProvider,
  Checkbox
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Card from '../Components/Organisms/Card';
import Boton from '../Components/Atoms/Boton';
import { useNavigate } from 'react-router';
import CrearFirmas from '../Components/Organisms/CrearFirmas';
import { useParams } from 'react-router-dom';
import clienteAxios from '../Utils/clienteAxios';
import decodeToken from '../Utils/decodeToken';
import moment from 'moment';
import Swal from 'sweetalert2';
import UploadPhoto from '../Components/Molecules/UploadPhoto';
import Loading from '../Components/Molecules/Loading';
import es_ES from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import reasonsOfIncidence from '../Components/Organisms/reasonsOfIncidence';

const { TextArea } = Input;
const ContenCrearIncidencia = () => {
  const [pedidoData, setPedidoData] = useState([]);
  const [firmaReviso, setFirmaReviso] = useState();
  const [firmaElaboro, setFirmaElaboro] = useState();
  const [solutionOfIncidence, setSolutionOfIncidence] = useState();
  const [reasonOfIncidence, setReasonOfIncidence] = useState([]);
  const [areaOfIncidence, setAreaOfIncidence] = useState();
  const [technique, setTechnique] = useState();
  const [nombreReviso, setNombreReviso] = useState('');
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [typeOfProduct, setTypeOfProduct] = useState();
  const [productQuantity, setProductQuantity] = useState([]);
  const [inputSize, setInputSize] = useState('middle');
  const [form] = Form.useForm();
  const navigate = useNavigate();

  let arrayFiltrado = productQuantity.filter(
    (elemento) => elemento !== undefined && elemento?.quantity_selected !== 0
  );
  let { idPedido } = useParams();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  const updateInputSize = () => {
    if (window.innerWidth <= 422) {
      setInputSize('small');
    } else {
      setInputSize('middle');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateInputSize);
    updateInputSize();
    return () => window.removeEventListener('resize', updateInputSize);
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/pedido/${idPedido}`)
      .then((response) => {
        let data = response.data;
        setPedidoData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ['evidenciaDeProductosAfectados']: fileList
    });
  }, [fileList]);

  function handleChangeAreaOfIncidence(area) {
    setAreaOfIncidence(area);
    setReasonOfIncidence();
  }
  const handleChangeReasonOfIncidence = (checkedValues) => {
    setReasonOfIncidence(checkedValues);
  };
  const handleChangeSolutionOfIncidence = (e) => {
    setSolutionOfIncidence(e.target.value);
  };
  const handleTechniqueChange = (value) => {
    setTechnique(value);
  };
  const handleChangeTypeOfProduct = (e) => {
    setTypeOfProduct(e.target.value);
  };

  const handleClickCreateIncidence = (values) => {
    values.firma_de_revision = firmaReviso;
    values.firma_elaboro = firmaElaboro;
    values.reviso = nombreReviso;
    values.comentarios_generales = values.comentarios_generales || 'No hay comentarios';
    if (!values?.tipo_de_tecnica) {
      values.tipo_de_tecnica = 'N/A';
    }
    values.fecha_compromiso = moment(values?.compromise_date?.$d).format('YYYY-MM-DD');
    console.log(values.fecha_compromiso);
    values.fecha_creacion = moment().format('YYYY-MM-DD');
    values.incidence_products = arrayFiltrado;
    const cadenaConSeparador = reasonOfIncidence.join(' | ');
    values.motivo_de_incidencia = cadenaConSeparador;

    delete values.comentarios;
    delete values.nombreReviso;
    delete values.firmaReviso;
    delete values.firmaElaboro;
    delete values.evidenciaDeProductosAfectados;
    handleUpload(fileList, values);
  };

  const handleUpload = (fileList, valuesOfForm) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });

    productQuantity.length > 0
      ? arrayFiltrado?.length === 1
        ? clienteAxios
            .post('/image/upload', formData)
            .then((response) => {
              let imageUrlArr = response.data.images;
              message.success('La imagen se subió correctamente.');
              valuesOfForm.evidencia = JSON.stringify(imageUrlArr);
              postIncidence(valuesOfForm);
            })
            .catch((error) => {
              console.error(error);
              message.error('Hubo un error al subir la imagen.');
            })
        : Swal.fire({
            title: 'Error',
            text: 'Solo puedes seleccionar un producto para crear la incidencia',
            icon: 'warning',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            setProductQuantity([]);
          })
      : Swal.fire({
          title: 'Error',
          text: 'Debes seleccionar al menos un producto para crear la incidencia',
          icon: 'warning',
          confirmButtonText: 'Aceptar'
        });
  };

  const postIncidence = (values) => {
    clienteAxios
      .post(`/pedidos/${idPedido}/incidencia`, values)
      .then((response) => {
        console.log(response);
        setLoading(false);
        Swal.fire({
          title: 'Incidencia creada',
          text: 'La incidencia se ha creado correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          navigate(`/pedidos/${idPedido}`);
        });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        Swal.fire({
          title: 'Error',
          text: error?.response?.data?.msg,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          navigate(`/pedidos/${idPedido}`);
        });
      });
  };

  return (
    <>
      <Loading loading={loading} />
      <h1>
        <ArrowLeftOutlined style={{ fontSize: '2rem' }} onClick={() => navigate(-1)} />
        crear incidencia
      </h1>

      <Card title="DATOS DE LA INCIDENCIA">
        <p className="ml-4 mr-4 ">
          <strong>ID Pedido: </strong>
          <span>{idPedido || 'No hay información'}</span>
        </p>
        <p className="ml-4 mr-4 ">
          <strong>Cliente: </strong>
          <span>{pedidoData?.more_information?.client_name?.trim() || 'No hay información'}</span>
        </p>
      </Card>

      <Form
        layout="vertical"
        autoComplete="off"
        onFinish={handleClickCreateIncidence}
        form={form}
        scrollToFirstError={{
          behavior: (actions) => {
            return actions.forEach(({ top }) => {
              window.scrollTo({
                top: top - 150,
                left: 0,
                behavior: 'smooth'
              });
            });
          }
        }}>
        <>
          <h2 className="mt-5">MOTIVO DE LA INCIDENCIA</h2>
          <div className="flex justify-content-center">
            <Form.Item
              className="mt-3"
              style={{ marginBottom: '0' }}
              label={
                <span className="font-weight-500">
                  ¿De qué área es el problema que quieres reportar?
                </span>
              }
              name="area"
              rules={[
                {
                  required: true,
                  message: 'Por favor indica el área!'
                }
              ]}>
              <Select
                onChange={handleChangeAreaOfIncidence}
                value={areaOfIncidence}
                placeholder="Selecciona el área"
                options={[
                  { value: 'Ventas', label: 'Ventas' },
                  { value: 'Compras', label: 'Compras' },
                  { value: 'Logística', label: 'Logística' },
                  { value: 'Almacén', label: 'Almacén' },
                  { value: 'Proveedor/Maquilador', label: 'Proveedor/Maquilador' },
                  { value: 'Administrativo', label: 'Administrativo' }
                ]}
              />
            </Form.Item>
          </div>

          <div className="flex justify-content-around">
            <Card title={areaOfIncidence} titleAlign="center">
              <Form.Item
                name="motivo_de_incidencia"
                label={
                  <span className="font-weight-500">¿Cuál es el motivo de la incidencia?</span>
                }
                rules={[
                  {
                    required: true,
                    message: 'Por favor indica la razón!'
                  }
                ]}>
                <Checkbox.Group onChange={handleChangeReasonOfIncidence} value={reasonOfIncidence}>
                  <Space direction="vertical" size={'large'}>
                    {areaOfIncidence === undefined ? (
                      <Alert
                        className="alert-pendiente"
                        message="Selecciona un área de incidencia para ver los motivos disponibles"
                        type="warning"
                        showIcon
                      />
                    ) : (
                      Object.keys(reasonsOfIncidence[areaOfIncidence])?.map((reason, index) => (
                        <Checkbox key={index} value={reason}>
                          {reason}
                        </Checkbox>
                      ))
                    )}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            </Card>
          </div>
        </>
        <h2 className="mt-4">PRODUCTOS AFECTADOS</h2>
        <TablaProductsIncidence
          type="post"
          productSelec={true}
          visible={false}
          data={pedidoData?.products_orders}
          productQuantity={productQuantity}
          setProductQuantity={setProductQuantity}></TablaProductsIncidence>

        <>
          <h2 className="mt-5">DETALLE DEL PRODUCTO</h2>
          <div className="mt-3 flex flex-wrap justify-content-around">
            <Form.Item
              label={<span className="font-weight-500">Tipo de producto</span>}
              name="tipo_de_producto"
              rules={[
                {
                  required: true,
                  message: 'Por favor indica el tipo de producto!'
                }
              ]}>
              <Radio.Group
                size={inputSize}
                buttonStyle="solid"
                className="ml-2"
                onChange={handleChangeTypeOfProduct}
                value={typeOfProduct}>
                <Radio.Button value="Limpio">Limpio</Radio.Button>
                <Radio.Button value="Maquilado">Maquilado</Radio.Button>
                <Radio.Button value="Fabricacion">Fabricación</Radio.Button>
                <Radio.Button value="Proceso">Proceso</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {(typeOfProduct === 'Maquilado' || typeOfProduct === 'Fabricacion') && (
              <Form.Item
                label={<span className="font-weight-500">Tipo de Técnica</span>}
                name="tipo_de_tecnica"
                rules={[
                  {
                    required: true,
                    message: 'Por favor indica el tipo de técnica!'
                  }
                ]}>
                <Select
                  className="ml-2"
                  style={{ width: 200 }}
                  value={technique}
                  onChange={handleTechniqueChange}
                  placeholder="Selecciona el tipo de técnica"
                  options={[
                    { value: 'Serigrafia', label: 'Serigrafia' },
                    { value: 'Tampografia', label: 'Tampografía' },
                    {
                      value: 'Grabado Láser o Punta Diamante',
                      label: 'Grabado láser o Punta diamante'
                    },
                    { value: 'Hot Stamping/Bajo Relieve', label: 'Hot Stamping/Bajo Relieve' },
                    { value: 'Bordado o Vinil', label: 'Bordado o Vinil' },
                    { value: 'Gota de resina', label: 'Gota de resina' }
                  ]}
                />
              </Form.Item>
            )}
          </div>
        </>
        <h2 className="mt-4">EVIDENCIA DE PRODUCTOS AFECTADOS</h2>
        <Form.Item
          className="mt-2"
          name="evidenciaDeProductosAfectados"
          label="Fotografía de los productos afectados"
          rules={[
            {
              required: true,
              validator: () => {
                if (fileList?.length > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Por favor, sube una imagen de evidencia!'));
              }
            }
          ]}>
          <UploadPhoto setFileList={setFileList} fileList={fileList} />
        </Form.Item>
        <>
          <h2 className="mt-4">SOLUCIÓN DE LA INCIDENCIA</h2>
          <div className="flex flex-column flex-wrap text-align-center">
            <Form.Item
              className="mt-3"
              label={<span className="font-weight-500">Solución de la incidencia</span>}
              name="solution"
              rules={[
                {
                  required: true,
                  message: 'Por favor indica la solución de la incidencia!'
                }
              ]}>
              <Radio.Group
                onChange={handleChangeSolutionOfIncidence}
                value={solutionOfIncidence}
                className="justify-content-center flex flex-wrap">
                <Radio value="Reprocesar">Reprocesar</Radio>
                <Radio value="Recomprar">Recomprar</Radio>
                <Radio value="Cambio Físico">Cambio físico</Radio>
                <Radio value="Reembolsar">Reembolsar</Radio>
                <Radio value="Devolución">Devolución</Radio>
                <Radio value="Otro">Otro</Radio>
                {solutionOfIncidence === 'Otro' && (
                  <Input
                    placeholder="Describe la solución"
                    style={{ marginLeft: 10, marginTop: 15 }}
                  />
                )}
              </Radio.Group>
            </Form.Item>
            <Space className="mt-2 justify-content-evenly flex flex-wrap">
              <Form.Item
                label={<span className="font-weight-500  text-align-center">Responsable</span>}
                name="responsable"
                rules={[
                  {
                    required: true,
                    message: 'Por favor indica el responsable!'
                  }
                ]}>
                <Input
                  placeholder="Nombre del responsable"
                  style={{ width: '100%', minWidth: 250 }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="font-weight-500  text-align-center">
                    Fecha compromiso para realizar solución
                  </span>
                }
                name="compromise_date"
                rules={[
                  {
                    required: true,
                    message: 'Por favor indica la fecha compromiso de solución!'
                  }
                ]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: '100%', minWidth: 250 }}
                  disabledDate={(current) => current && current < dayjs().startOf('day')}
                />
              </Form.Item>
            </Space>
          </div>
        </>
        <h2 className="mt-4">COMENTARIOS GENERALES</h2>
        <Form.Item className="mt-3" name="comments">
          <TextArea placeholder="Escribe aquí tus comentarios..." />
        </Form.Item>
        <div className="mt-5">
          <CrearFirmas
            nombreElaboro={decodedToken?.user?.name}
            nombreReviso={nombreReviso}
            setNombreReviso={setNombreReviso}
            firmaReviso={firmaReviso}
            setFirmaReviso={setFirmaReviso}
            firmaElaboro={firmaElaboro}
            setFirmaElaboro={setFirmaElaboro}
          />
        </div>
        <div className="justify-content-end mt-5">
          <Space align="end" direction="horizontal">
            <Boton type="primary" htmlType="submit">
              Crear Incidencia
            </Boton>
          </Space>
        </div>
      </Form>
    </>
  );
};
const CrearIncidencia = () => {
  const [locale] = useState(es_ES);

  return (
    <>
      <ConfigProvider locale={locale}>
        <ContenCrearIncidencia />
      </ConfigProvider>
    </>
  );
};

export default CrearIncidencia;
