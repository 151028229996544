import React, { useEffect, useState } from 'react';
import {
  Radio,
  Form,
  Space,
  DatePicker,
  Alert,
  Spin,
  ConfigProvider,
  message,
  Input,
  Image
} from 'antd';
import Card from '../Components/Organisms/Card';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import Boton from '../Components/Atoms/Boton';
import parseDate from '../Utils/parseDate';
import ConsultarFirmas from '../Components/Organisms/ConsultaFirmas';
import { photosEndpoint } from '../Constants/apiEndpoint';
import moment from 'moment';
import Swal from 'sweetalert2';
import es_ES from 'antd/locale/es_ES';
import 'dayjs/locale/es';
import UploadPhoto from '../Components/Molecules/UploadPhoto';
import dayjs from 'dayjs';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import reasonsOfIncidence from '../Components/Organisms/reasonsOfIncidence';
import decodeToken from '../Utils/decodeToken';

const ContenConsultarIncidencia = () => {
  let { idIncidencia } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [recargarUpload, setRecargarUpload] = useState(false);
  const navigate = useNavigate();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  useEffect(() => {
    clienteAxios
      .get(`/incidencias/${idIncidencia}`)
      .then((response) => {
        let data = response?.data?.data.incidencia;
        setLoading(false);
        data.evidence = JSON.parse(data?.evidence).map((url) => photosEndpoint + url);
        setPhotos(
          data?.evidence.map((url) => {
            const startIndex = url.indexOf('storage/');
            if (startIndex !== -1) {
              return url.substring(startIndex);
            }
            return url;
          })
        );
        if (data?.solution_date) {
          let solutionOfIncidence = new Date(data?.solution_date + 'T00:00:00');
          let creationDate = new Date(data?.creation_date + 'T00:00:00');
          let realTimeToSolution = (solutionOfIncidence - creationDate) / (1000 * 3600 * 24);
          data.realTimeToSolution = realTimeToSolution;
        }
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  useEffect(() => {
    {
      fileList?.length > 0
        ? setIsButtonDisabled(false)
        : productQuantity?.length > 0 &&
          productQuantity?.[0]?.quantity_selected !==
            data?.orderDetails?.[0]?.incidenceProducts?.[0]?.quantity_selected
        ? setIsButtonDisabled(false)
        : setIsButtonDisabled(true);
    }
  }, [fileList, productQuantity, data]);

  const handleSaveRealSolutionDate = (values) => {
    values.solution_date = moment(values?.solution_date?.$d).format('YYYY-MM-DD');
    Swal.fire({
      title: '¿Estás seguro de liberar la incidencia?',
      text: 'Esto liberará la incidencia, se guardará la fecha y no se podrá volver a editar',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí, liberar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .put(`/incidencias/${idIncidencia}`, values)
          .then(() => {
            Swal.fire({
              title: 'Exito',
              text: 'La fecha de solución se ha guardado correctamente y se ha liberado la incidencia',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch(() => {
            Swal.fire({
              title: 'Error',
              text: 'Ha ocurrido un error al guardar la fecha de solución',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          });
      }
    });
  };

  const editarIncidencia = () => {
    setIsButtonDisabled(true);
    let evidenciaPhotos = JSON.stringify(photos);
    const valuesActualizarIncidencia = {
      quantity_selected: productQuantity?.[0]?.quantity_selected,
      evidencia: evidenciaPhotos
    };
    const valuesActualizarIncidencia2 = {
      quantity_selected: productQuantity?.[0]?.quantity_selected
    };
    fileList.length > 0
      ? handleUpload(fileList, valuesActualizarIncidencia2)
      : handleSave(valuesActualizarIncidencia);
  };

  const handleUpload = (fileList, valuesOfForm) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    clienteAxios.post('/image/upload', formData).then((response) => {
      let imageUrlArr = response.data.images;
      let concatPhotos = imageUrlArr.concat(photos);
      message.success('La imagen se subió correctamente.');
      valuesOfForm.evidencia = JSON.stringify(concatPhotos);
      handleSave(valuesOfForm);
    });
  };

  const handleSave = (values) => {
    Swal.fire({
      title: '¿Deseas actualizar esta incidencia?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Si, actualizar',
      cancelButtonText: 'No, cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .put(`/incidencias/${idIncidencia}/update`, values)
          .then((response) => {
            console.log('response', response);
            Swal.fire({
              title: 'Incidencia actualizada correctamente',
              text: 'La incidencia se ha actualizado correctamente',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setLoading(!loading);
              setFileList([]);
              setRecargarUpload(!recargarUpload);
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              title: 'Error',
              text: 'Ups, ha ocurrido un error al actualizar la incidencia. Por favor, inténtalo de nuevo más tarde.',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          });
      } else {
        setIsButtonDisabled(false);
      }
    });
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <h1>
        <ArrowLeftOutlined style={{ fontSize: '2rem' }} onClick={() => navigate(-1)} /> detalle de
        incidencia
      </h1>
      <Card title="DATOS DE LA INCIDENCIA">
        <div className="ml-3 mr-3 mt-1 mb-1">
          <strong>Fecha de creación: </strong>
          <span>{parseDate(data?.creation_date, 'long')}</span>
        </div>
        <div className="ml-3 mr-3 mt-1 mb-1">
          <strong>ID incidencia: </strong>
          <span>{data?.code_incidence}</span>
        </div>
        <div className="ml-3 mr-3 mt-1 mb-1">
          <strong>ID Pedido: </strong>
          <span>{data?.code_sale}</span>
        </div>
      </Card>

      <div className="flex justify-content-around" style={{ marginTop: '-10px' }}>
        <Card title={'Área Seleccionada:' + ' ' + data?.area}>
          <Space direction="vertical">
            {Object.keys(reasonsOfIncidence[data?.area || 'Compras']).map((reason, index) => {
              return (
                <Radio
                  key={index}
                  checked={data?.reason?.split(' | ').includes(reason)}
                  disabled={true}>
                  {reason}
                </Radio>
              );
            })}
          </Space>
        </Card>
      </div>

      <h2 className="mt-3 mb-2">PRODUCTOS AFECTADOS</h2>
      <TablaProductsIncidence
        type={
          data?.status === 'Creada' && data?.elaborated === decodedToken?.user?.name
            ? 'post'
            : 'get'
        }
        visible={false}
        productSelec={true}
        data={data?.orderDetails?.[0]?.incidenceProducts}
        productQuantity={productQuantity}
        setProductQuantity={setProductQuantity}></TablaProductsIncidence>

      <div className="flex justify-content-around mt-3">
        <Card title="INFORMACIÓN DEL PRODUCTO">
          <div className="ml-2 mr-2 mt-1 mb-1">
            <strong>Tipo de producto: </strong>
            <span>{data?.product_type}</span>
          </div>
          <div className="ml-2 mr-2 mt-1 mb-1">
            <strong>Tipo de técnica: </strong>
            <span>{data?.type_of_technique}</span>
          </div>
        </Card>
      </div>

      <>
        <h2
          className="mt-3"
          style={{
            marginTop: data?.elaborated === decodeToken?.user?.name ? '0.5rem' : 'initial'
          }}>
          {' '}
          EVIDENCIA DE PRODUCTOS AFECTADOS
        </h2>{' '}
        <div
          style={{ display: 'flex', alignItems: 'center', overflowY: 'auto', maxHeight: 'auto' }}>
          {data?.evidence?.map((evidenceUrl, index) => {
            const replace = evidenceUrl.replace(/ /g, '%20');
            return (
              <div key={index} className="div-margin-image-1">
                <div className="div-margin-image-2">
                  <Image height={80} src={replace} />
                </div>
              </div>
            );
          })}
          {data?.status === 'Creada' && data?.elaborated === decodedToken?.user?.name ? (
            <div className="mt-2">
              <UploadPhoto setFileList={setFileList} fileList={fileList} key={recargarUpload} />
            </div>
          ) : null}
        </div>
      </>
      <Card title="SOLUCIÓN DE LA INCIDENCIA ">
        <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
          <strong>Solución: </strong>
          <span>{data?.solution}</span>
        </div>
        <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
          <strong>Responsable:</strong>
          <span>{data?.responsible}</span>
        </div>
        <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
          <strong>Fecha compromiso a realizar solución:</strong>
          <span>{moment(data.commitment_date).format('YYYY-MM-DD')}</span>
        </div>
        {data?.solution_date ? (
          <>
            <div className="flex flex-wrap justify-content-evenly">
              <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
                <strong>Fecha real de solución:</strong>
                <span>{parseDate(data?.solution_date, 'short')}</span>
              </div>
              <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
                <strong>Tiempo real de solución:</strong>
                <span>{data?.realTimeToSolution} días</span>
              </div>

              <div className="ml-4 mr-4 mt-2 mb-2 flex flex-column">
                <Alert
                  message={
                    <span>
                      La incidencia fue resuelta él <b>{parseDate(data?.solution_date, 'long')}</b>
                    </span>
                  }
                  type="info"
                  showIcon
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-content-evenly">
            <Form layout="vertical" autoComplete="off" onFinish={handleSaveRealSolutionDate}>
              <div className="flex flex-row justify-content-center align-items-center flex-wrap">
                <Form.Item
                  label="Fecha real de solución:"
                  name="solution_date"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor indica la fecha real de solución!'
                    }
                  ]}>
                  <DatePicker
                    status="warning"
                    placeholder="Ingresa fecha"
                    format="DD/MM/YYYY"
                    disabledDate={(current) => current && current < dayjs().startOf('day')}
                  />
                </Form.Item>
                <Boton type="primary" htmlType="submit">
                  Guardar fecha real de la solución
                </Boton>
              </div>
            </Form>
          </div>
        )}
      </Card>

      <h2 style={{ marginTop: '2.5rem', marginBottom: '0.5rem' }}>COMENTARIOS GENERALES</h2>
      <Input.TextArea rows={2} value={data?.comments} disabled={true} />

      <div style={{ marginTop: '2.5rem' }}>
        <ConsultarFirmas
          rol_creator={data?.rol_creator}
          nombreElaboro={data?.elaborated}
          nombreReviso={data?.reviewed}
          firmaElaboro={data?.signature_elaborated}
          firmaReviso={data?.signature_reviewed}
        />
      </div>
      {data?.status !== 'Liberda' && data?.elaborated === decodedToken?.user?.name ? (
        <div className="flex justify-content-end mt-3">
          <Boton type="primary" onClick={editarIncidencia} disabled={isButtonDisabled}>
            Editar Incidencia
          </Boton>
        </div>
      ) : null}
    </Spin>
  );
};

const ConsultarIncidencia = () => {
  const [locale] = useState(es_ES);
  return (
    <>
      <ConfigProvider locale={locale}>
        <ContenConsultarIncidencia />
      </ConfigProvider>
    </>
  );
};
export default ConsultarIncidencia;
