import React from 'react';
import { useState, useRef, useLayoutEffect } from 'react';
import SignaturePad from 'react-signature-canvas';
import Boton from '../Atoms/Boton';
import '../../Assets/Styles/Molecules/Firma.css';
import PropTypes, { bool } from 'prop-types';
import Modal from '../Atoms/Modal';

const Firma = ({
  firma,
  setFirma,
  button,
  none,
  recepcion,
  setFirmaRecepcion,
  firmaRecepcion,
  empaque,
  firmaEmpaque,
  setFirmaEmpaque,
  logistica,
  firmaLogistica,
  setFirmaLogistica
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sigCanvas = useRef({});
  // const [deviceSize, setDeviceSize] = useState({ width: 0, height: 0, deviceType: 'default' });

  const limpiar = () => {
    sigCanvas.current.clear();
    setFirma('');
    recepcion
      ? setFirmaRecepcion('')
      : empaque
      ? setFirmaEmpaque('')
      : logistica
      ? setFirmaLogistica('')
      : setFirma('');
  };

  const guardar = () =>
    recepcion
      ? setFirmaRecepcion(sigCanvas.current.getSignaturePad().toDataURL('image/png'))
      : empaque
      ? setFirmaEmpaque(sigCanvas.current.getSignaturePad().toDataURL('image/png'))
      : logistica
      ? setFirmaLogistica(sigCanvas.current.getSignaturePad().toDataURL('image/png'))
      : setFirma(sigCanvas.current.getSignaturePad().toDataURL('image/png'));

  useLayoutEffect(() => {
    if (isModalOpen) {
      handleResize();
    }
  }, [isModalOpen]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      handleResize();
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const handleResize = () => {
    if (window.innerHeight < 425) {
      // setDeviceSize({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      //   deviceType: 'mobile landscape'
      // });
      console.log('MOBILE LANDSCAPE');
      document
        .querySelectorAll('.sigCanvasClassName')
        .forEach((canvas) => (canvas.width = window.innerWidth - 84));
      document
        .querySelectorAll('.sigCanvasClassName')
        .forEach((canvas) => (canvas.height = window.innerHeight - 157));
    } else {
      // setDeviceSize({
      //   width: window.innerWidth,
      //   height: window.innerHeight,
      //   deviceType: 'mobile protrait'
      // });
      if (window.innerWidth < 550) {
        console.log('MOBILE PORTRAIT');
        document
          .querySelectorAll('.sigCanvasClassName')
          .forEach((canvas) => (canvas.width = window.innerWidth - 84));
        document
          .querySelectorAll('.sigCanvasClassName')
          .forEach((canvas) => (canvas.height = window.innerWidth - 157));
      } else {
        document.querySelectorAll('.sigCanvasClassName').forEach((canvas) => (canvas.width = 475));
        document.querySelectorAll('.sigCanvasClassName').forEach((canvas) => (canvas.height = 300));
      }
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    guardar();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    limpiar();
  };

  const ModalFooter = () => {
    return (
      <>
        <Boton danger onClick={limpiar}>
          Limpiar
        </Boton>
        <Boton type="primary" onClick={handleOk}>
          Aceptar
        </Boton>
      </>
    );
  };

  return (
    <>
      <img
        // src={firma  || ''}
        src={
          recepcion
            ? firmaRecepcion
            : empaque
            ? firmaEmpaque
            : logistica
            ? firmaLogistica
            : firma || ''
        }
        className="img-firma"
        onClick={showModal}
        style={none ? { display: 'none' } : {}}
      />
      {button ? (
        <Boton type={firma ? 'secondary' : 'primary'} onClick={showModal}>
          {firma ? 'Reemplazar firma' : 'Firmar'}
        </Boton>
      ) : null}

      <Modal
        title="FIRMA"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={<ModalFooter />}
        className="firma-modal"
        wrapClassName="firma-modal-wrapper">
        <span>Si estás en un celular puedes girarlo para usar toda la pantalla para firmar.</span>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{
            width: 475,
            height: 300,
            className: 'sigCanvasClassName'
          }}
        />
        {/* <div style={{ color: 'red' }}>
          <span>Width: {deviceSize.width}</span>
          <br />
          <span>Height: {deviceSize.height}</span>
          <br />
          <span>Device Type: {deviceSize.deviceType}</span> <br />
          <span>Canvas Height Size: {document.querySelector('.sigCanvasClassName')?.height}</span>
        </div> */}
      </Modal>
    </>
  );
};

Firma.propTypes = {
  firma: PropTypes.string,
  setFirma: PropTypes.func,
  recepcion: bool,
  firmaRecepcion: PropTypes.string,
  setFirmaRecepcion: PropTypes.func,
  empaque: bool,
  firmaEmpaque: PropTypes.string,
  setFirmaEmpaque: PropTypes.func,
  logistica: bool,
  firmaLogistica: PropTypes.string,
  setFirmaLogistica: PropTypes.func,
  button: PropTypes.bool,
  none: PropTypes.bool
};

export default Firma;
