import React, { useState } from 'react';
import { Checkbox, ConfigProvider, DatePicker, Select, Table, Input } from 'antd';
import propTypes from 'prop-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import decodeToken from '../../Utils/decodeToken';
import es_ES from 'antd/locale/es_ES';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const TableRouteComprasFinish = ({
  tableRefFinish,
  finalizado,
  setFinalizado,
  data,
  loading,
  edit,
  allProductsCompleta,
  selectedRowsCompleta,
  setSelectedRowsCompleta,
  excludedRowsCompleta,
  setExcludedRowsCompleta,
  setSelectedRowsPendientes
}) => {
  const [locale] = useState(es_ES);
  const { TextArea } = Input;
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let userRole = decodedToken?.role?.name;

  const disableequipo_logistica = userArea === 'logistica' ? true : false;
  const option_status_delivery = [
    { value: 'Completo', label: 'Completo' },
    { value: 'Pendiente', label: 'Pendiente' },
    { value: 'Reprogramado', label: 'Reprogramado' }
  ];

  const filterOptionStatus_delivery =
    userArea === 'sgcmc' || userRole === 'Super_Admin' || decodedToken?.sub === 280
      ? option_status_delivery
      : userArea === 'logistica'
      ? option_status_delivery.filter(
          (item) => item.value === 'Completo' || item.value === 'Reprogramado'
        )
      : userArea === 'compras' || userArea === 'mesa_de_control'
      ? option_status_delivery.filter(
          (item) => item.value === 'Pendiente' || item.value === 'Reprogramado'
        )
      : null;

  const commonColumsCompleta = [
    {
      title: 'PRODUCTO',
      dataIndex: 'producto',
      key: 'producto',
      fixed: 'left',
      render(text, record) {
        return (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {edit === true ? (
                <Checkbox
                  style={{ marginRight: 10 }}
                  checked={selectedRowsCompleta.includes(record.key)}
                  onChange={(e) => handleCheckboxChangeCompleta(e, record.key)}
                />
              ) : null}
              <span>{text}</span>
            </div>
          </>
        );
      }
    },
    {
      title: 'N° PEDIDO',
      dataIndex: 'pedido',
      key: 'pedido'
    },
    {
      title: 'ORDEN',
      dataIndex: 'orden',
      key: 'orden'
    },
    {
      title: 'FECHA DE ENTREGA',
      dataIndex: 'date_of_delivery',
      key: 'date_of_delivery',
      render: (text, record) =>
        allProductsCompleta === false && selectedRowsCompleta.includes(record.key) ? (
          <ConfigProvider locale={locale}>
            <DatePicker
              disabled={disableequipo_logistica}
              defaultValue={dayjs(text, dateFormat)}
              format={dateFormat}
              onChange={(date, dateString) => handleDateChangeCompleta(record.key, dateString)}
              disabledDate={(current) => current && current < dayjs().startOf('day')}
            />
          </ConfigProvider>
        ) : (
          text
        )
    },
    {
      title: 'TIPO DE ENTREGA',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) =>
        allProductsCompleta === false && selectedRowsCompleta.includes(record.key) ? (
          <Select
            disabled={disableequipo_logistica}
            style={{ width: 100 }}
            defaultValue={text}
            options={[
              { value: 'Parcial', label: 'Parcial' },
              { value: 'Total', label: 'Total' }
            ]}
            onChange={(value) => hanldeSelectChangeCompleta(record.key, 'type', value)}
          />
        ) : (
          text
        )
    },
    {
      title: 'ESTADO DE ENTREGA',
      dataIndex: 'status_delivery',
      key: 'status_delivery',
      render: (text, record) =>
        allProductsCompleta === false && selectedRowsCompleta.includes(record.key) ? (
          <Select
            style={{ width: 100 }}
            disabled={disableequipo_logistica}
            defaultValue={text}
            options={filterOptionStatus_delivery}
            onChange={(value) => hanldeSelectChangeCompleta(record.key, 'status_delivery', value)}
          />
        ) : (
          text
        )
    },
    {
      title: 'OBSERVACIONES',
      dataIndex: 'observation',
      key: 'observation',
      render: (text, record) =>
        allProductsCompleta === false && selectedRowsCompleta.includes(record.key) ? (
          <TextArea
            rows={2}
            onChange={(e) => hanldeSelectChangeCompleta(record.key, 'observation', e.target.value)}
          />
        ) : (
          text || 'Sin observaciones'
        )
    },
    {
      title: 'TIPO DE ENVIO',
      dataIndex: 'shipping_type',
      key: 'shipping_type',
      render: (text, record) =>
        allProductsCompleta === false && selectedRowsCompleta.includes(record.key) ? (
          <Select
            disabled={disableequipo_logistica}
            style={{ width: 100 }}
            defaultValue={text}
            options={[
              { value: 'Interno', label: 'Interno' },
              { value: 'Externo', label: 'Externo' },
              { value: 'Paqueteria', label: 'Paqueteria' }
            ]}
            onChange={(value) => hanldeSelectChangeCompleta(record.key, 'shipping_type', value)}
          />
        ) : (
          text
        )
    },
    {
      title: 'DESTINO',
      dataIndex: 'destino',
      key: 'destino'
    }
  ];

  const hanldeSelectChangeCompleta = (key, field, value) => {
    const updatedDataCompleta = finalizado.map((item, index) => {
      if (index === key) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setFinalizado(updatedDataCompleta);
  };

  const handleDateChangeCompleta = (key, dateString) => {
    const updatedDataCompleta = finalizado.map((item, index) => {
      if (index === key) {
        return { ...item, date_of_delivery: dateString };
      }
      return item;
    });
    setFinalizado(updatedDataCompleta);
  };

  const handleCheckboxChangeCompleta = (e, key) => {
    const newSelectedRows = e.target.checked
      ? [...selectedRowsCompleta, key]
      : selectedRowsCompleta.filter((rowKey) => rowKey !== key);

    const newExcludedRows = !e.target.checked
      ? [...excludedRowsCompleta, key]
      : excludedRowsCompleta.filter((rowKey) => rowKey !== key);

    setSelectedRowsCompleta(newSelectedRows);
    setExcludedRowsCompleta(newExcludedRows);
    setSelectedRowsPendientes([]);
  };

  const dataCompleta = data?.map((item, index) => ({
    key: index,
    pedido: item?.code_sale,
    orden: item?.code_order,
    producto: item?.description,
    type: item?.type,
    date_of_delivery: item?.date_of_delivery,
    status_delivery: item?.status_delivery,
    shipping_type: item?.shipping_type,
    destino: item?.type_of_destiny,
    observation: item?.observation
  }));

  return (
    <>
      <Table
        ref={tableRefFinish}
        className="mt-3 mb-2 tablaComprasFinish"
        columns={commonColumsCompleta}
        dataSource={dataCompleta}
        scroll={{ x: 1600 }}
        loading={loading}
        pagination={false}></Table>{' '}
    </>
  );
};

TableRouteComprasFinish.propTypes = {
  tableRefFinish: propTypes.object,
  finalizado: propTypes.array,
  data: propTypes.array,
  setFinalizado: propTypes.func,
  selectedRowsCompleta: propTypes.array,
  setSelectedRowsCompleta: propTypes.func,
  allProductsCompleta: propTypes.bool,
  excludedRowsCompleta: propTypes.array,
  setExcludedRowsCompleta: propTypes.func,
  loading: propTypes.bool,
  setLoading: propTypes.func,
  edit: propTypes.bool,
  setSelectedRowsPendientes: propTypes.func
};

export default TableRouteComprasFinish;
