import React, { useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ContainerFilled,
  LogoutOutlined,
  TeamOutlined,
  QuestionCircleOutlined,
  ShoppingFilled,
  CarOutlined
} from '@ant-design/icons';
import { Layout, Menu, Dropdown, Avatar, Space, Button } from 'antd';
import Boton from '../Atoms/Boton';
import decodeToken from '../../Utils/decodeToken';
import LogoBPMS from '../../Assets/Images/Logo-BPMS-1x.png';
import { useEffect } from 'react';

const { Header, Sider, Content, Footer } = Layout;

const AppLayout = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [decodedToken, setDecodedToken] = useState(null);
  const [screenSizePage, setScreenSizePage] = useState('');

  const gestionPedidosArea = [
    'ventas',
    'equipo_direccion',
    'ventas_bh',
    'ventas_pl',
    'compras',
    'sgcmc',
    // 'muestras',
    'mesa_de_control',
    'almacen'
  ];

  const userRole = decodedToken?.role?.name;
  const userArea = decodedToken?.user?.display_name_area;

  const isAdmin = userRole === 'administrator';
  const isSuperAdmin = userRole === 'Super_Admin';

  useEffect(() => {
    function handleResize() {
      const xs = window.innerWidth < 576;
      const sm = window.innerWidth < 768;
      const md = window.innerWidth < 992;
      const lg = window.innerWidth < 1200;
      const xl = window.innerWidth < 1500;
      const xxl = window.innerWidth < 2000;

      if (xs) {
        setScreenSizePage('xs');
      } else if (sm) {
        setScreenSizePage('sm');
      } else if (md) {
        setScreenSizePage('md');
      } else if (lg) {
        setScreenSizePage('lg');
      } else if (xl) {
        setScreenSizePage('xl');
      } else if (xxl) {
        setScreenSizePage('xxl');
      } else {
        setScreenSizePage('xxxl');
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) setDecodedToken(decodeToken(localStorage.getItem('token')));
    setCollapsed(true);
  }, [localStorage.getItem('token')]);

  const cerrarSesion = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const menuCerrarSesion = [
    {
      key: '1',
      label: (
        <Boton danger onClick={cerrarSesion}>
          Cerrar sesión
        </Boton>
      )
    }
  ];

  //returns an object with keys xs, sm, md, lg, xl, xxl to check for screen size
  const screenSizes = screenSizePage;

  return (
    <Layout>
      <Sider
        width={
          screenSizes === 'xs' || screenSizes === 'sm' || screenSizes === 'md' ? '250px' : '200px'
        }
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setCollapsed(broken);
        }}
        collapsedWidth={
          screenSizes === 'xs'
            ? '0'
            : '80' && screenSizes === 'sm'
            ? '0'
            : '80' && screenSizes === 'md'
            ? '0'
            : '80'
        }
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 100,
          background: '#161618'
        }}>
        <div className="section-user-info">
          <Avatar
            size={50}
            src={decodedToken?.user?.photo}
            icon={decodedToken?.user?.name.charAt(0)}
            style={{
              backgroundColor: '#e6f4ff',
              color: '#1677FF'
            }}
          />
          {!collapsed && (
            <div className="flex-column">
              <span className="section-user-info-name">{decodedToken?.user?.name}</span>
              <span className="section-user-info-role">{decodedToken?.role?.display_name}</span>
            </div>
          )}
        </div>

        {userRole === 'Super_Admin' || userArea === 'sgcmc' || decodedToken?.sub === 280 ? (
          <>
            <Menu
              theme="dark"
              style={{ background: '#161618' }}
              mode="inline"
              defaultSelectedKeys={location.pathname ? [`${location.pathname}`] : ['/inicio']}
              onClick={({ key }) => {
                navigate(key);
                if (screenSizes === 'xs' || screenSizes === 'sm' || screenSizes === 'md')
                  setCollapsed(true);
              }}
              items={[
                isAdmin || isSuperAdmin || gestionPedidosArea.includes(userArea)
                  ? {
                      key: '/pedidos',
                      icon: <ContainerFilled />,
                      label: 'Pedidos'
                    }
                  : '',

                (isAdmin && userArea !== 'direccion') ||
                userArea === 'compras' ||
                userArea === 'sgcmc' ||
                userArea === 'logistica' ||
                userRole === 'equipo_mesa_control' ||
                isSuperAdmin
                  ? {
                      key: '/rutas-de-entrega',
                      icon: <CarOutlined />,
                      label: 'Rutas de entrega'
                    }
                  : '',
                {
                  key: '/solicitudes-gasto',
                  icon: <ShoppingFilled />,
                  label: 'Solicitudes de gasto'
                },
                {
                  key: '/capacitacion',
                  icon: <QuestionCircleOutlined />,
                  label: 'Capacitación'
                },
                isSuperAdmin
                  ? {
                      key: '/users',
                      icon: <TeamOutlined />,
                      label: 'Usuarios'
                    }
                  : ''
              ]}
            />
          </>
        ) : (
          <>
            <Menu
              theme="dark"
              style={{ background: '#161618' }}
              mode="inline"
              defaultSelectedKeys={location.pathname ? [`${location.pathname}`] : ['/inicio']}
              onClick={({ key }) => {
                navigate(key);
                if (screenSizes === 'xs' || screenSizes === 'sm' || screenSizes === 'md')
                  setCollapsed(true);
              }}
              items={[
                {
                  key: '/solicitudes-gasto',
                  icon: <ShoppingFilled />,
                  label: 'Solicitudes de gasto'
                },
                {
                  key: '/capacitacion',
                  icon: <QuestionCircleOutlined />,
                  label: 'Capacitación'
                }
              ]}
            />
          </>
        )}
      </Sider>
      {/* Tamaño de gris en pantalla */}
      <Layout
        className="site-layout flex"
        style={{
          marginLeft:
            (screenSizes === 'xs' || screenSizes === 'sm' || screenSizes === 'md') && collapsed
              ? '0px'
              : (screenSizes === 'xs' || screenSizes === 'sm' || screenSizes === 'md') && !collapsed
              ? '0px'
              : (screenSizes === 'lg' || screenSizes === 'xl' || screenSizes === 'xxl') &&
                !collapsed
              ? '200px'
              : '80px',

          opacity:
            (screenSizes === 'xs' && !collapsed) ||
            (screenSizes === 'sm' && !collapsed) ||
            (screenSizes === 'md' && !collapsed)
              ? '0.6'
              : ' '
        }}>
        <Header
          className="site-layout-background"
          style={{
            position: 'fixed',
            top: 0,
            zIndex: 99,
            width: '100%',
            padding: 0
          }}>
          {/* Menu de cerrar secion */}
          {(screenSizes === 'xs' && !collapsed) ||
          (screenSizes === 'sm' && !collapsed) ||
          (screenSizes === 'md' && !collapsed) ? (
            <Button
              style={{ width: '100%', height: '100vh', opacity: '0.1' }}
              onClick={() => setCollapsed(!collapsed)}>
              cdcd
            </Button>
          ) : null}

          <Space
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              paddingRight:
                screenSizes === 'xs' || screenSizes === 'sm' || screenSizePage === 'md'
                  ? '0px'
                  : collapsed
                  ? '80px'
                  : '200px'
            }}>
            <div className="flex">
              {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed)
              })}
              <img src={LogoBPMS} width="50px" className="align-self-center mb-1" />
            </div>
            <Dropdown menu={{ items: menuCerrarSesion }} placement="bottomRight">
              <LogoutOutlined className="mr-4" />
            </Dropdown>
          </Space>
        </Header>
        <Content className="site-layout-background container align-self-center">
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            opacity:
              (screenSizes === 'xs' && !collapsed) ||
              (screenSizes === 'sm' && !collapsed) ||
              (screenSizes === 'md' && !collapsed)
                ? '0.6'
                : ''
          }}>
          © 2024 BPMS PL - BH. Todos los derechos reservados.
        </Footer>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
