import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Atoms/Modal';
import { InputNumber } from 'antd';

export const ModalIngresarPresupuesto = ({
  isModalOpenIncome,
  showModalIncome,
  handleEnterBudget,
  budgetAmount,
  setBudgetAmount
}) => {
  return (
    <Modal
      title="INGRESAR PRESUPUESTO"
      centered
      open={isModalOpenIncome}
      onCancel={showModalIncome}
      onOk={handleEnterBudget}
      width={350}>
      <div className="flex">
        <span className="mr-3">Importe: </span>
        <InputNumber
          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          value={budgetAmount}
          onChange={(value) => setBudgetAmount(value)}
          className="width-100"
        />
      </div>
    </Modal>
  );
};

ModalIngresarPresupuesto.propTypes = {
  isModalOpenIncome: PropTypes.bool,
  showModalIncome: PropTypes.func,
  handleEnterBudget: PropTypes.func,
  budgetAmount: PropTypes.number,
  setBudgetAmount: PropTypes.func
};
