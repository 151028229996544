import ReusableModal from '../Atoms/Modal';
import { Col, Row, Select, Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import clienteAxios from '../../Utils/clienteAxios';
import parseDate from '../../Utils/parseDate';
import moment from 'moment';
import Boton from '../Atoms/Boton';
import { CloseCircleOutlined } from '@ant-design/icons';
import decodeToken from '../../Utils/decodeToken';

const ModalHistoryDelibery = ({ isOpenhistoryRoute, setHistoryRoute, propData, scrollY }) => {
  const [dataistoryRoute, setDataHistoryRoute] = useState([]);
  const [loadingHistoryRoute, setLoadingHistoryRoute] = useState(true);
  const [destinations, setDestinations] = useState('');
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let userDeparment = decodedToken?.user?.display_name_department;
  let userRole = decodedToken?.role?.name;

  useEffect(() => {
    if (Object.keys(propData).length !== 0) {
      setLoadingHistoryRoute(true);
      clienteAxios
        .get(`/rutas-de-entrega/show/${propData?.product_id}`)
        .then((response) => {
          setDataHistoryRoute(response?.data?.data?.ruta);
          setLoadingHistoryRoute(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [propData]);

  const HandleCancelRoute = () => {
    setHistoryRoute(false);
    document.body.classList.remove('body-fixed');
    window.scrollTo(0, scrollY);
  };

  const columsHistoryRoute = [
    {
      title: 'DESTINO',
      dataIndex: 'destino',
      key: 'destino',
      fixed: 'left'
    },
    {
      title: 'FECHA DE ENTREGA',
      dataIndex: 'fecha_entrega',
      key: 'fecha_entrega'
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: 'ESTADO',
      dataIndex: 'estado',
      key: 'estado'
    },
    {
      title: 'OBSERVACIONES',
      dataIndex: 'observation',
      key: 'observation'
    },
    {
      title: 'TIPO DE ENVIO',
      dataIndex: 'type_send',
      key: 'type_send'
    },
    {
      title: 'FECHA ACTUALIZACIÓN',
      dataIndex: 'date_update',
      key: 'date_update'
    }
  ];

  let dataRole =
    decodedToken?.role?.name === 'administrator' ||
    decodedToken?.role?.name === 'equipo_mesa_control'
      ? dataistoryRoute?.filter(
          (item) =>
            item?.type_of_destiny === 'Almacen PL' ||
            item?.type_of_destiny === 'Maquila' ||
            item?.type_of_destiny === 'Almacen PM' ||
            item?.type_of_destiny === 'Cliente'
        )
      : dataistoryRoute?.filter(
          (item) =>
            item?.type_of_destiny === 'Almacen PL' ||
            item?.type_of_destiny === 'Maquila' ||
            item?.type_of_destiny === 'Almacen PM'
        );

  let mostrarDestino = dataRole?.filter((item) => item?.type_of_destiny === destinations);

  let mostarData = destinations === '' ? dataRole : mostrarDestino;

  const dataHistory = mostarData.map((item, index) => ({
    key: index,
    destino: item?.type_of_destiny,
    fecha_entrega: parseDate(item?.date_of_delivery, 'short'),
    tipo: item?.type,
    observation: item?.observation === null ? 'Sin observaciones' : item?.observation,
    estado: (
      <>
        <Tag
          color={
            item?.status_delivery === 'Completo'
              ? 'green'
              : item?.status_delivery === 'Pendiente'
              ? 'orange'
              : 'blue'
          }>
          {item?.status_delivery}
        </Tag>
      </>
    ),
    type_send: item?.shipping_type,
    date_update: (
      <Row gutter={30} className="justify-content-center">
        <Col>{parseDate(item?.created_at, 'short')}</Col>
        <Col>{moment(item?.created_at).format('LT')}</Col>
      </Row>
    )
  }));

  const options = [
    {
      value: 'Almacen PL',
      label: 'Almacen PL'
    },
    {
      value: 'Maquila',
      label: 'Maquila'
    },
    {
      value: 'Almacen PM',
      label: 'Almacen PM'
    },
    {
      value: 'Cliente',
      label: 'Cliente'
    }
  ];

  const optionFilter =
    decodedToken?.role?.name === 'administrator' ||
    userDeparment === 'ventas' ||
    userArea === 'sgcmc' ||
    decodedToken?.sub === 280 ||
    userArea === 'mesa_de_control' ||
    userRole === 'Super_Admin'
      ? options
      : userArea === 'compras'
      ? options.filter(
          (item) =>
            item.value === 'Almacen PL' || item.value === 'Maquila' || item.value === 'Almacen PM'
        )
      : null;
  return (
    <>
      <ReusableModal
        title="HISTORIAL DE RUTA"
        open={isOpenhistoryRoute}
        onCancel={HandleCancelRoute}
        width={1250}
        cancelText={'Cerrar'}
        okButtonProps={{
          style: { display: 'none' }
        }}>
        <div className="mb-3 justify-content-center align-items-center text-align-center">
          <strong className="mr-1">Filtrar: </strong>
          <Select
            value={destinations}
            onChange={(value) => setDestinations(value)}
            style={{ width: 150 }}
            options={optionFilter}
          />
          {destinations !== '' ? (
            <Boton
              className="ml-2"
              style={{
                border: 'none',
                height: '20px',
                boxShadow: 'none',
                padding: '0px ',
                color: 'var(--secondary-color-red)'
              }}
              onClick={() => setDestinations('')}>
              <CloseCircleOutlined />
            </Boton>
          ) : null}
        </div>

        <Table
          pagination={false}
          columns={columsHistoryRoute}
          dataSource={dataHistory}
          loading={loadingHistoryRoute}
          scroll={{ y: 400, x: 1150 }}
        />
      </ReusableModal>
    </>
  );
};

ModalHistoryDelibery.propTypes = {
  isOpenhistoryRoute: PropTypes.bool,
  setHistoryRoute: PropTypes.func,
  propData: PropTypes.object,
  scrollY: PropTypes.number
};

export default ModalHistoryDelibery;
