import React from 'react';
import { Form, Input, InputNumber, Upload } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import Modal from '../../Components/Atoms/Modal';

export default function ModalRegresarExcedente({
  isModalOpenExcess,
  showModalExcess,
  handleReturnSurplus,
  setQuantitySurplus,
  setDescriptionSurplus,
  setArchive,
  setIsFileRemoved
}) {
  const { TextArea } = Input;

  return (
    <>
      <Modal
        title="REGRESAR EXCEDENTE"
        centered
        open={isModalOpenExcess}
        onCancel={showModalExcess}
        onOk={handleReturnSurplus}>
        <Form
          className="mt-4"
          labelCol={{
            span: 6
          }}
          labelAlign="left"
          wrapperCol={{
            span: 19
          }}>
          <Form.Item
            label="Importe"
            rules={[
              { required: true, message: 'Ingrese un monto mayor a 0!', type: 'number', min: 1 }
            ]}>
            <InputNumber
              className="width-100"
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={(value) => setQuantitySurplus(value)}
            />
          </Form.Item>
          <Form.Item
            label="Descripción"
            rules={[{ required: true, message: 'Escribe una descripción!' }]}>
            <TextArea
              rows={3}
              showCount
              maxLength={150}
              placeholder="Escribe aquí"
              onChange={(e) => setDescriptionSurplus(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Archivo">
            <Upload
              maxCount={1}
              listType="picture"
              accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
              beforeUpload={(file) => {
                // Validar el tipo de archivo o realizar otras validaciones necesarias
                // y luego establecer el archivo seleccionado en el estado
                setArchive(file);
                setIsFileRemoved(false);
                return false; // Evita que el archivo se cargue automáticamente
              }}
              onRemove={() => {
                setArchive(null);
                setIsFileRemoved(true);
              }}
              multiple={false}>
              <Boton color="primary" variant="outlined" icon={<PaperClipOutlined />}>
                Adjuntar archivo o imagen
              </Boton>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

ModalRegresarExcedente.propTypes = {
  isModalOpenExcess: PropTypes.bool,
  showModalExcess: PropTypes.func,
  handleReturnSurplus: PropTypes.func,
  setQuantitySurplus: PropTypes.func,
  setDescriptionSurplus: PropTypes.func,
  setArchive: PropTypes.func,
  setIsFileRemoved: PropTypes.func
};
