const parseDate = (date, format = 'short') => {
  const options = {
    year: 'numeric',
    month: format === 'long' ? 'long' : '2-digit',
    day: '2-digit'
  };
  return new Date(date?.replace(/-/g, '/').replace(/T.+/, '')).toLocaleDateString('ES-MX', options);
};

export default parseDate;
