const reasonsOfIncidence = {
  Ventas: {
    'No se tiene dirección de entrega': 1,
    'Precio incorrecto': 2,
    'No se define las características del producto (color, logo)': 3,
    'No envió la información correctamente (No adjuntó los datos)': 4,
    'Solicitud de cambio y/o devolución fuera de tiempo (Política de devolución)': 5
  },
  Compras: {
    'Retraso en entrega del material': 1,
    'Entrega incompleta (Con respecto a la factura)': 2,
    'Proveedor no entrega documentación completa': 3,
    'Impresión o grabado diferente al solicitado en el virtual autorizado por el cliente': 4
  },
  Logística: {
    'Entrega errónea (Con respecto a la factura)': 1,
    'Entrega incompleta (Con respecto a la factura)': 2,
    'Entrega o embarque fuera de tiempo': 3
  },
  Almacén: {
    'Se empacó mal el producto': 1,
    'Maltrato de producto y genera un defecto': 2,
    'Retraso en operación de empaque': 3,
    'No se realiza la inspección correctamente (Calidad)': 4
  },
  'Proveedor/Maquilador': {
    'Impresión o grabado diferente al solicitado': 1,
    'Mala calidad de impresión o grabado': 2,
    'Entrega incompleta (Con respecto a la factura)': 3,
    'No es el producto solicitado': 4,
    'Retraso en operación de producción': 5
  },
  Administrativo: {
    'No cumple con el procedimiento': 1,
    'No respeta los procedimientos': 2
  }
};

export default reasonsOfIncidence;
