import axios from 'axios';
import { apiEndpoint } from '../Constants/apiEndpoint';

axios.defaults.headers.common['Accept'] = 'application/json';

const clienteAxios = axios.create({
  baseURL: apiEndpoint
});

export default clienteAxios;
