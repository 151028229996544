import { useState, useMemo, useEffect } from 'react';
import { Table, Input, Tag } from 'antd';
import { FileDoneOutlined, HistoryOutlined } from '@ant-design/icons';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import ModalCreateDelibery from './ModalCreateDelibery';
import ModalHistoryDelibery from './ModalHistoryDelibery';
import StatusBarPRoduct from '../Molecules/StatusBarProduct';
import decodeToken from '../../Utils/decodeToken';
import ModalMultiplesProducts from '../Molecules/ModalMultiplesProducts';

const TableTrazavilidadProducto = ({ code_sale, setLoading, loading, orders_groups }) => {
  const [isOpenhistoryRoute, setHistoryRoute] = useState(false);
  const [propData, setPropData] = useState({});
  const [isOpenModalUpdateRoute, setModalUpdateRoute] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [dataTrazaProduc, setDataTrazaProduc] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openMultipleProducts, setOpenMultipleProducts] = useState(false);
  const { Search } = Input;

  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let userDeparment = decodedToken?.user?.display_name_department;
  let userRole = decodedToken?.role?.name;

  useEffect(() => {
    const data = orders_groups?.map((item, index) => ({
      key: index,
      description: item?.description,
      code_order_oc: item?.code_order_oc,
      code_order_ot:
        JSON.parse(item?.code_order_ot).map((ot) => ot).length > 0 ? (
          JSON.parse(item?.code_order_ot)
            .map((ot) => ot + ' ')
            .join(', ')
        ) : (
          <Tag color="red">Sin OT asignadas</Tag>
        ),
      state: (
        <div style={{ overflowY: 'auto', maxHeight: '150px', minWidth: '180px' }}>
          <StatusBarPRoduct status={item?.status_orders || []} />
        </div>
      ),
      actions: (
        <>
          {userArea === 'compras' ||
          userArea === 'mesa_de_control' ||
          userArea === 'sgcmc' ||
          decodedToken?.sub === 280 ||
          userRole === 'Super_Admin' ? (
            <Boton
              className="mb-3"
              type="primary"
              icon={<FileDoneOutlined />}
              onClick={() => {
                setModalUpdateRoute(true);
                setPropData({
                  code_sale: code_sale,
                  code_order: item?.code_order_oc,
                  product_id: item?.product_id_oc,
                  status_product: item?.status_orders
                });
                setScrollY(window.scrollY);
                document.body.classList.add('body-fixed');
              }}>
              {item?.status_orders?.length > 0 ? 'Actualizar Ruta' : 'Crear Ruta'}
            </Boton>
          ) : null}
          {decodedToken?.role?.name === 'equipo_mesa_control' ||
          (decodedToken?.role?.name === 'administrator' && item?.deliverys_route?.length > 0) ||
          (userArea === 'sgcmc' && item?.deliverys_route?.length > 0) ||
          (userDeparment === 'ventas' && item?.deliverys_route?.length > 0) ||
          (userRole === 'Super_Admin' && item?.deliverys_route?.length > 0) ||
          (decodedToken?.sub === 280 && item?.deliverys_route?.length > 0) ? (
            <Boton
              className="mb-3"
              type="secondary"
              icon={<HistoryOutlined />}
              onClick={() => {
                setHistoryRoute(true);
                setPropData({
                  product_id: item?.product_id_oc
                });
                setScrollY(window.scrollY);
                document.body.classList.add('body-fixed');
              }}>
              Historial Trazabilidad
            </Boton>
          ) : userDeparment === 'ventas' || userDeparment === 'direccion' ? (
            <Tag color="red">Aún no se han programado rutas</Tag>
          ) : null}
          {/*Mostar solamente cuando uno de los indices tiene type_product como maquilado*/}
          {(item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            decodedToken?.role?.name === 'administrator') ||
          (item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            userArea === 'mesa_de_control') ||
          (item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            userArea === 'sgcmc') ||
          (item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            decodedToken?.sub === 280) ||
          (item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            userDeparment === 'ventas') ||
          (item?.Inspection?.length > 0 &&
            item?.Inspection?.some((item) => item?.type_product === 'maquilado') &&
            userRole === 'Super_Admin') ? (
            <Tag color="green">Producto Inspeccionado</Tag>
          ) : null}
        </>
      )
    }));
    setDataTrazaProduc(data);
    setFilteredData(data); // Inicialmente, los datos filtrados son iguales a todos los datos
  }, [orders_groups]);

  const handleSearch = (value) => {
    const filtered = dataTrazaProduc.filter((item) =>
      item.description.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const columnsTableTrazaProduc = useMemo(
    () => [
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'description',
        key: 'description',
        fixed: 'left'
        // width: 280
      },
      {
        title: 'OC',
        dataIndex: 'code_order_oc',
        key: 'code_order_oc'
        // width: 100
      },
      // {
      //   title: 'OT',
      //   dataIndex: 'code_order_ot',
      //   key: 'code_order_ot'
      // },
      {
        title: 'ESTADO',
        dataIndex: 'state',
        key: 'state'
      },
      {
        title: 'ACCIONES',
        dataIndex: 'actions',
        key: 'actions'
      }
    ],
    []
  );

  return (
    <>
      <div className="flex justify-content-between mb-3">
        <div>
          <Search
            placeholder="Buscar producto"
            onSearch={handleSearch}
            allowClear
            className="mr-4"
          />
        </div>

        <div>
          <Boton
            type="primary"
            onClick={() => {
              setOpenMultipleProducts(true);
            }}>
            Añadir múltiples productos
          </Boton>
        </div>
      </div>

      <Table
        loading={loading}
        columns={columnsTableTrazaProduc}
        dataSource={filteredData} // Usar los datos filtrados
        pagination={false}
      />

      {isOpenModalUpdateRoute && (
        <ModalCreateDelibery
          isOpenModalUpdateRoute={isOpenModalUpdateRoute}
          setModalUpdateRoute={setModalUpdateRoute}
          propData={propData}
          setLoading={setLoading}
          loading={loading}
          scrollY={scrollY}
        />
      )}

      <ModalHistoryDelibery
        isOpenhistoryRoute={isOpenhistoryRoute}
        setHistoryRoute={setHistoryRoute}
        propData={propData}
        scrollY={scrollY}
      />

      {openMultipleProducts && (
        <ModalMultiplesProducts
          openMultipleProducts={openMultipleProducts}
          setOpenMultipleProducts={setOpenMultipleProducts}
          orders_groups={orders_groups}
          code_sale={code_sale}
        />
      )}
    </>
  );
};

TableTrazavilidadProducto.propTypes = {
  products: PropTypes.array,
  orders_groups: PropTypes.array,
  orders: PropTypes.array,
  code_sale: PropTypes.string,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default TableTrazavilidadProducto;
