import { Alert, Form, Input, Radio } from 'antd';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { useEffect, useState } from 'react';
import clienteAxios from '../../Utils/clienteAxios';
import Swal from 'sweetalert2';
import CrearFirmas from './CrearFirmas';

const ModalCreateRecepcions = ({
  isOpenReceptionProduct,
  setReceptionProduct,
  propData,
  setLoading,
  loading,
  id,
  decodedToken
}) => {
  const [dataHistory, setDataHistoryRoute] = useState([]);
  const [almacen, setAlmacen] = useState('');
  const [informationRecep, setInformationRecep] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loadCounterCount, setLoadCounterCount] = useState(0); // Counter to track the loading status
  const [firmaElaboro, setFirmaElaboro] = useState();

  const [form] = Form.useForm();
  useEffect(() => {
    if (Object.keys(propData).length !== 0) {
      clienteAxios
        .get(`/rutas-de-entrega/show/${propData?.product_id}`)
        .then((response) => {
          setDataHistoryRoute(response?.data?.data?.ruta);
          setLoadCounterCount((prev) => prev + 1); // Increment the counter
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [propData]);

  useEffect(() => {
    if (Object.keys(propData).length !== 0) {
      clienteAxios
        .get(`history/confirmation/route/product/${propData?.product_id}`)
        .then((response) => {
          setInformationRecep(response?.data?.HistoryConfirmationRoutes?.reverse());
          setLoadCounterCount((prev) => prev + 1); // Increment the counter
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [propData]);

  useEffect(() => {
    if (loadCounterCount === 2) {
      // Check if both requests are completed
      let initialValue =
        option_filteredDatastatus_deliveryComplete?.[0]?.value ||
        option_filteredDatastatus_deliveryComplete?.[1]?.value;
      setAlmacen(initialValue);
      form.setFieldsValue({ destiny: initialValue }); // Update form field value
      setDataLoading(true); // Set data loading to true
    }
  }, [loadCounterCount]);

  //Mostrar el último registro conforme al destino y la fecha de created_at
  let filteredData = [];

  if (dataHistory?.length > 0) {
    const lastRecord = dataHistory?.reduce((acc, curr) => {
      if (!acc[curr.type_of_destiny]) {
        acc[curr.type_of_destiny] = [];
      }
      acc[curr.type_of_destiny].push(curr);
      return acc;
    }, {});

    filteredData = Object.keys(lastRecord).map((key) => {
      const dates = lastRecord[key].map((item) => new Date(item.created_at));
      const maxDate = new Date(Math.max.apply(null, dates));
      return lastRecord[key].find(
        (item) => new Date(item.created_at).getTime() === maxDate.getTime()
      );
    });
  }

  const filteredDatastatus_deliveryComplete = filteredData?.filter(
    (item) =>
      (item?.status_delivery === 'Completo' && item?.type_of_destiny === 'Almacen PL') ||
      (item?.status_delivery === 'Completo' && item?.type_of_destiny === 'Almacen PM')
  );
  const option_filteredDatastatus_deliveryComplete = filteredDatastatus_deliveryComplete?.map(
    (item) => ({
      value: item?.type_of_destiny,
      label: item?.type_of_destiny
    })
  );

  const handleCancelReceptionProduct = () => {
    setReceptionProduct(false);
    form.resetFields();
  };

  const handleReceptionProduct = (values) => {
    values.signature = firmaElaboro;
    values.id_product_order = propData?.product_id;
    handlePostReceptionProduct(values);
  };

  const handlePostReceptionProduct = (values) => {
    console.log('values', values);
    verType !== undefined
      ? ultimaConfirmacion?.destination === almacen &&
        ultimaConfirmacion?.reception_type === verType
        ? Swal.fire({
            width: 600,
            icon: 'question',
            title:
              'Ya se realizo una recepción con este destino y tipo de recepción, ¿Deseas volver a realizarla?',
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clienteAxios
                .post(`/confirmation/route/product/${id}`, values)
                .then((response) => {
                  console.log('response', response);
                  Swal.fire({
                    title: 'Producto recibido',
                    icon: 'success'
                  }).then(() => {
                    setReceptionProduct(false);
                    form.resetFields();
                    setLoading(!loading);
                  });
                })
                .catch((error) => {
                  console.error(error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error al recibir el producto'
                  });
                  setLoading(!loading);
                  setReceptionProduct(false);
                });
            }
          })
        : Swal.fire({
            title: '¿Estás seguro de recibir el producto?',
            icon: 'question',
            confirmButtonText: 'Aceptar',
            denyButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clienteAxios
                .post(`/confirmation/route/product/${id}`, values)
                .then((response) => {
                  console.log('response', response);
                  Swal.fire({
                    title: 'Producto recibido',
                    icon: 'success'
                  }).then(() => {
                    setReceptionProduct(false);
                    form.resetFields();
                    setLoading(!loading);
                  });
                })
                .catch((error) => {
                  console.error(error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Hubo un error al recibir el producto'
                  });
                  setLoading(!loading);
                  setReceptionProduct(false);
                });
            }
          })
      : Swal.fire({
          width: 600,
          icon: 'error',
          title:
            'No puedes realizar la recepción de este producto porque aún no tiene un destino asignado en la ruta de entrega'
        });
  };

  let verType = filteredData?.find((item) => item?.type_of_destiny === almacen)?.type;
  let ultimaConfirmacion = informationRecep?.find((item) => item?.destination === almacen);

  return (
    <>
      {dataLoading ? (
        <ReusableModal
          title="RECEPCIÓN DE INVENTARIO"
          open={isOpenReceptionProduct}
          width={550}
          onCancel={handleCancelReceptionProduct}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <Alert
            message="Se mostrarán los tipos de productos disponibles para la recepción. En caso de haber dos opciones, selecciona solo un tipo."
            type="warning"
            showIcon
            closable
            className="mb-3"
          />
          <Form form={form} onFinish={handleReceptionProduct}>
            <Form.Item
              className="mb-3"
              name="destiny"
              label="Tipo de destino:"
              initialValue={almacen}
              rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Radio.Group onChange={(e) => setAlmacen(e.target.value)}>
                {option_filteredDatastatus_deliveryComplete?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Tipo de recepción" style={{ display: 'none' }}>
              <Input disabled={true} value={verType} className="text-align-center"></Input>
            </Form.Item>

            <Form.Item name="observation" label="Observaciones">
              <Input.TextArea style={{ width: 260 }} className="text-align-center"></Input.TextArea>
            </Form.Item>

            <div className="mt-2">
              <CrearFirmas
                nombreElaboro={decodedToken?.user?.name}
                reviso={false}
                firmaElaboro={firmaElaboro}
                setFirmaElaboro={setFirmaElaboro}
              />
            </div>

            <div className="justify-content-end">
              <Boton danger onClick={handleCancelReceptionProduct} className="mr-2">
                Cancelar
              </Boton>

              <Boton
                type="primary"
                htmlType="submit"
                disabled={option_filteredDatastatus_deliveryComplete?.length === 0 ? true : false}>
                Confirmar Recepción
              </Boton>
            </div>
          </Form>
        </ReusableModal>
      ) : null}
    </>
  );
};

ModalCreateRecepcions.propTypes = {
  isOpenReceptionProduct: PropTypes.bool,
  setReceptionProduct: PropTypes.func,
  propData: PropTypes.object,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.string,
  decodedToken: PropTypes.object
};

export default ModalCreateRecepcions;
