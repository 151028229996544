export default function getSampleQuantity(a) {
  return (a >= 2) & (a <= 8) //a
    ? 2
    : (a >= 9) & (a <= 15) //b
    ? 3
    : (a >= 16) & (a <= 25) //c
    ? 5
    : (a >= 26) & (a <= 50) //d
    ? 6
    : (a >= 51) & (a <= 90) //e
    ? 8
    : (a >= 91) & (a <= 150) //f
    ? 13
    : (a >= 151) & (a <= 280) //g
    ? 20
    : (a >= 281) & (a <= 500) //h
    ? 32
    : (a >= 501) & (a <= 1200) //i
    ? 50
    : (a >= 1201) & (a <= 3200) //j
    ? 80
    : (a >= 3201) & (a <= 10000) //k
    ? 125
    : (a >= 10001) & (a <= 35000) //l
    ? 315
    : a >= 35001
    ? 500
    : 0;
}
