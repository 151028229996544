import React from 'react';
import { Link } from 'react-router-dom';
import logoBPMS from '../Assets/Images/Logo-BPMS-4x.webp';
import Boton from '../Components/Atoms/Boton';

export default function NotFound() {
  return (
    <>
      <div
        className="flex flex-column justify-content-center align-items-center"
        style={{ minHeight: '100vh' }}>
        <h1 style={{ fontSize: '15rem' }}>404</h1>
        <h1 className="mb-5">La página que estás buscando no existe</h1>

        <Link to="/inicio" className="mb-5">
          <Boton type="primary" size="large">
            Regresar al inicio
          </Boton>
        </Link>

        <img src={logoBPMS} alt="logo BPMS" width={200} className="mt-5" />
      </div>
    </>
  );
}
