import React from 'react';
// import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import PropTypes from 'prop-types';
import Boton from './Boton';

export const BotonExcel = () => {
  // useLayoutEffect(() => {
  //   const tableElement = document.querySelector('.ant-table-content table');
  //   if (tableElement) {
  //     tableElement.id = tableId;
  //   }
  // }, []);

  return (
    // <ReactHtmlTableToExcel
    //   id={tableId}
    //   className="download-table-xls-button"
    //   table={nametable}
    //   filename={filename}
    //   sheet={filename}
    //   buttonText="Exportar Excel"
    // />
    <Boton>Excel</Boton>
  );
};

BotonExcel.propTypes = {
  tableId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  nametable: PropTypes.string.isRequired
};
