import React, { useState } from 'react';

import { createContext } from 'react';
import PropTypes from 'prop-types';

export const GlobalFuctions = createContext();

const GlobalVariables = ({ children }) => {
  const [quatityValue, setQuantityValue] = useState(null);
  const [idValue, setIdValue] = useState(null);
  const [productsSelection, setProductsSlection] = useState([]);
  const [pedidos, setPedidos] = useState([0]);
  let temporal = [];
  const fullOrders = (pedido, record) => {
    const model = {
      odoo_product_id: pedido['odoo_product_id'],
      productId: pedido['id'],
      ped_amount: pedido['amount'] || pedido['quantity_selected'],
      code_order: record.referencia
    };

    temporal.push(model);
    const filtrado = temporal.filter((elemento, index, self) => {
      return (
        index ===
        self.findIndex(
          (elem) =>
            elem.odoo_product_id === elemento.odoo_product_id &&
            elem.productId === elemento.productId
        )
      );
    });

    let pedidosconstruido = [filtrado];
    let pedidosSeparados = pedidosconstruido.reduce((result, current) => {
      current.forEach((pedido) => {
        let index = result.findIndex((group) => group[0].code_order === pedido.code_order);
        if (index === -1) {
          result.push([pedido]);
        } else {
          result[index].push(pedido);
        }
      });
      return result;
    }, []);

    return pedidosSeparados;
  };

  const setProductsSelectionDefault = (productos) => {
    if (productos) {
      setProductsSlection([productos]);
    }
    return null;
  };

  return (
    <GlobalFuctions.Provider
      value={{
        quatityValue,
        idValue,
        productsSelection,
        pedidos,
        fullOrders,
        setPedidos,
        setProductsSlection,
        setQuantityValue,
        setIdValue,
        setProductsSelectionDefault
      }}>
      {children}
    </GlobalFuctions.Provider>
  );
};
GlobalVariables.propTypes = {
  children: PropTypes.object
};
export default GlobalVariables;
