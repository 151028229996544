import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import Modal from '../../Components/Atoms/Modal';

export default function ModalDatosEvetuales({
  newDataRequest,
  isModalOpenEventual,
  showModalEventual,
  handleEditAny,
  eventualColumns
}) {
  return (
    <>
      <Modal
        title="ACTUALIZAR EVENTUALES"
        centered
        open={isModalOpenEventual}
        onCancel={showModalEventual}
        onOk={handleEditAny}>
        {newDataRequest.event && newDataRequest.event.length > 0 && (
          <Table
            className="mt-4"
            columns={eventualColumns}
            dataSource={newDataRequest.event.map((event, index) => ({
              key: index,
              name: event.name,
              editableCompany: event.company_name,
              editablePay: event.pay,
              id: event.id
            }))}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: 3
            }}
          />
        )}
      </Modal>
    </>
  );
}

ModalDatosEvetuales.propTypes = {
  isModalOpenEventual: PropTypes.bool,
  showModalEventual: PropTypes.func,
  handleEditAny: PropTypes.func,
  newDataRequest: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  eventualColumns: PropTypes.array
};
