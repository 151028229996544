import React from 'react';
import PropTypes from 'prop-types';
import '../../Assets/Styles/Organisms/Card.css';

export default function Card(props) {
  return (
    <div className={`mb-4 mt-4 card ${props.className}`}>
      <h2 className={`card-title mb-2 ${props.titleAlign === 'center' ? 'text-align-center' : ''}`}>
        {props.title}
      </h2>
      <div className="card-body flex-row flex-wrap justify-content-center text-align-center">
        {props.children}
      </div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  paddingBottom: PropTypes.string,
  className: PropTypes.string
};
