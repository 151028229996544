import React, { useState, useEffect } from 'react';
import Card from '../Components/Organisms/Card';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Image, Space, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router';
import parseDate from '../Utils/parseDate';
import ConsultarFirmas from '../Components/Organisms/ConsultaFirmas';
import TextArea from 'antd/es/input/TextArea';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import { photosEndpoint } from '../Constants/apiEndpoint';
import OptionsInspection from '../Components/Molecules/OptionsInspection';
import Boton from '../Components/Atoms/Boton';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ConsultarInspeccionCalidad = () => {
  const [base64, setBase64] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const url = 'https://dev-api-bpms.promolife.lat/storage/images/1724294760%20reajpg.jpg';

    clienteAxios
      .get(url, { responseType: 'blob' })
      .then((response) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64(reader.result);
        };
        reader.readAsDataURL(response.data);
      })
      .catch((error) => {
        console.error('Error al convertir la imagen:', error.message);
        setError('Error al convertir la imagen: ' + error.message);
      });
  }, []);
  console.log('base64', base64);

  const downloadPdf = () => {
    const input = document.getElementById('content');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // Ancho de la imagen en mm para A4
        const pageHeight = 265; // Altura de la página en mm para A4
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save('download.pdf');
      })
      .catch((err) => console.error('Error al generar el PDF', err));
  };

  console.log('error', error);
  console.log('base64', base64);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [firmaRecepcion, setFirmaRecepcion] = useState();
  const [firmaEmpaque, setFirmaEmpaque] = useState();
  const [firmaLogistica, setFirmaLogistica] = useState();
  const navigate = useNavigate();

  let { idInspeccion } = useParams();

  const [form] = Form.useForm(); // Crear una instancia del formulario

  useEffect(() => {
    clienteAxios
      .get(`/inspections/${idInspeccion}`)
      .then((response) => {
        let data = response?.data;
        console.log('data', data);
        setLoading(false);
        data.evidence = JSON.parse(data?.inspections?.files).map((url) => photosEndpoint + url);
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [loading]);

  console.log(data);

  useEffect(() => {
    const values = {};
    if (data?.inspection_receipts !== undefined) {
      values[`recepcion${0}`] = data?.inspection_receipts?.merchandise_with_defect || 0;
      values[`recepcion${1}`] = data?.inspection_receipts?.wrong_color_or_pantone || 0;
      values[`recepcion${2}`] = data?.inspection_receipts?.damage_to_logo || 0;
      values[`recepcion${3}`] = data?.inspection_receipts?.wrong_logo || 0;
      values[`recepcion${4}`] = data?.inspection_receipts?.incomplete_pieces || 0;
      values[`recepcion${5}`] = data?.inspection_receipts?.uncombined_merchandise || 0;
      if (data?.inspection_packagings !== undefined) {
        values[`empaque${0}`] = data?.inspection_packagings?.merchandise_with_defect || 0;
        values[`empaque${1}`] = data?.inspection_packagings?.uncombined_merchandise || 0;
        values[`empaque${2}`] = data?.inspection_packagings?.incomplete_pieces || 0;
        values[`empaque${3}`] = data?.inspection_packagings?.master_box_not_correct || 0;
        values[`empaque${4}`] = data?.inspection_packagings?.incorrect_box_taping || 0;
        values[`empaque${5}`] = data?.inspection_packagings?.corrugated_with_defect || 0;
      }

      if (data?.inspection_logistics !== undefined) {
        values[`logistica${0}`] = data?.inspection_logistics?.complete_boxes || 0;
        values[`logistica${1}`] = data?.inspection_logistics?.master_box_with_defect || 0;
        values[`logistica${2}`] = data?.inspection_logistics?.incorrect_identification_label || 0;
        values[`logistica${3}`] = data?.inspection_logistics?.inadequate_stowage_by_weight || 0;
        values[`logistica${4}`] = data?.inspection_logistics?.incorrect_documentation || 0;
        values[`logistica${5}`] = data?.inspection_logistics?.incorrect_merchandise_vs_invoice || 0;
      }

      form.setFieldsValue(values); // Actualizar los valores del formulario
    } else if (data?.inspection_packagings !== undefined) {
      values[`empaque${0}`] = data?.inspection_packagings?.merchandise_with_defect || 0;
      values[`empaque${1}`] = data?.inspection_packagings?.uncombined_merchandise || 0;
      values[`empaque${2}`] = data?.inspection_packagings?.incomplete_pieces || 0;
      values[`empaque${3}`] = data?.inspection_packagings?.master_box_not_correct || 0;
      values[`empaque${4}`] = data?.inspection_packagings?.incorrect_box_taping || 0;
      values[`empaque${5}`] = data?.inspection_packagings?.corrugated_with_defect || 0;

      if (data?.inspection_logistics !== undefined) {
        values[`logistica${0}`] = data?.inspection_logistics?.complete_boxes || 0;
        values[`logistica${1}`] = data?.inspection_logistics?.master_box_with_defect || 0;
        values[`logistica${2}`] = data?.inspection_logistics?.incorrect_identification_label || 0;
        values[`logistica${3}`] = data?.inspection_logistics?.inadequate_stowage_by_weight || 0;
        values[`logistica${4}`] = data?.inspection_logistics?.incorrect_documentation || 0;
        values[`logistica${5}`] = data?.inspection_logistics?.incorrect_merchandise_vs_invoice || 0;
      }

      form.setFieldsValue(values); // Actualizar los valores del formulario
    } else if (data?.inspection_logistics !== undefined) {
      values[`logistica${0}`] = data?.inspection_logistics?.complete_boxes || 0;
      values[`logistica${1}`] = data?.inspection_logistics?.master_box_with_defect || 0;
      values[`logistica${2}`] = data?.inspection_logistics?.incorrect_identification_label || 0;
      values[`logistica${3}`] = data?.inspection_logistics?.inadequate_stowage_by_weight || 0;
      values[`logistica${4}`] = data?.inspection_logistics?.incorrect_documentation || 0;
      values[`logistica${5}`] = data?.inspection_logistics?.incorrect_merchandise_vs_invoice || 0;
      form.setFieldsValue(values); // Actualizar los valores del formulario
    }

    setFirmaRecepcion(data?.inspection_receipts?.receipt_signature);
    setFirmaEmpaque(data?.inspection_packagings?.packaging_signature);
    setFirmaLogistica(data?.inspection_logistics?.logistics_signature);
  }, [data, form]);

  let nuevoObjeto;

  if (Object.values(data)?.length > 0) {
    let producsInspeccion = data?.products_inspection;
    const { description_product, ...resto } = producsInspeccion;

    nuevoObjeto = { description: description_product, ...resto };
  }
  const handleUpdate = async (values) => {
    values.value1 = 'inspection_logistics_true';
    values.inspection_logistics = {
      complete_boxes: values?.logistica0,
      master_box_with_defect: values?.logistica1,
      incorrect_identification_label: values?.logistica2,
      inadequate_stowage_by_weight: values?.logistica3,
      incorrect_documentation: values?.logistica4,
      incorrect_merchandise_vs_invoice: values?.logistica5,
      logistics_signature: firmaLogistica
    };

    values.value2 = 'inspection_packagings_true';
    values.inspection_packagings = {
      merchandise_with_defect: values?.empaque0,
      uncombined_merchandise: values?.empaque1,
      incomplete_pieces: values?.empaque2,
      master_box_not_correct: values?.empaque3,
      incorrect_box_taping: values?.empaque4,
      corrugated_with_defect: values?.empaque5,
      packaging_signature: firmaEmpaque
    };

    values.value3 = 'inspection_receipts_true';
    values.inspection_receipts = {
      merchandise_with_defect: values?.recepcion0,
      wrong_color_or_pantone: values?.recepcion1,
      damage_to_logo: values?.recepcion2,
      wrong_logo: values?.recepcion3,
      incomplete_pieces: values?.recepcion4,
      uncombined_merchandise: values?.recepcion5,
      receipt_signature: firmaRecepcion
    };
    handlePodtUpdate(values);
  };

  const handlePodtUpdate = async (values) => {
    Swal.fire({
      title: '¿Estás seguro que deseas actualizar la inspección?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, actualizar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/inspections/${idInspeccion}`, values)
          .then((response) => {
            console.log(response);
            Swal.fire({
              title: '¡Actualizado!',
              text: 'La inspección ha sido actualizada con éxito.',
              icon: 'success'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire('¡Error!', 'Ha ocurrido un error.', 'error');
          });
      }
    });
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <>
        <h1>
          <ArrowLeftOutlined
            style={{ fontSize: '2rem' }}
            onClick={() => navigate(-1)}
            className="mr-2"
          />
          CONSULTA DE INSPECCIÓN DE CALIDAD
        </h1>

        <div id="content">
          <Card title="DETALLES DEL PEDIDO" className="mt-5">
            <p className="ml-4 mr-4 mb-2">
              <strong>ID Inspección: </strong>
              <span>{data?.inspections?.code_inspection}</span>
            </p>

            <p className="ml-4 mr-4 mb-2">
              <strong>Fecha: </strong>
              <span>{parseDate(data?.inspections?.date_inspection, 'short')}</span>
            </p>

            <p className="ml-4 mr-4 mb-2">
              <strong> Tipo de producto: </strong>
              <span>{data?.inspections?.type_product || 'No hay información'}</span>
            </p>
          </Card>
          <h2 className="mt-5 mb-2">PRODUCTOS INSPECCIONADOS</h2>
          <TablaProductsIncidence
            data={[nuevoObjeto]}
            productSelec={true}
            type="get"></TablaProductsIncidence>
          <div className="flex flex-column align-items-center">
            <Card title="DATOS DE NIVELES DE CALIDAD">
              <p className="ml-4 mr-4 mb-2">
                <strong>TOTAL DE PIEZAS: </strong>
                <span>{data?.inspections?.quantity_revised || 'No hay información'}</span>
              </p>

              <p className="ml-4 mr-4 mb-2">
                <strong>MUESTRA: </strong>
                <span>{data?.inspections?.sample}</span>
              </p>

              <p className="ml-4 mr-4 mb-2">
                <strong>MÁXIMO DE PIEZAS A RECHAZAR: </strong>
                <span>{data?.inspections?.quantity_denied}</span>
              </p>
              <p className="ml-4 mr-4 mb-2">
                <strong>PIEZAS RECHAZADAS: </strong>
                <span>{data?.inspections?.rejected_parts}</span>
              </p>
            </Card>
          </div>
          <Form form={form} onFinish={handleUpdate}>
            <OptionsInspection
              idInspeccion={idInspeccion}
              inspection_receipts={data?.inspection_receipts}
              firmaRecepcion={firmaRecepcion}
              setFirmaRecepcion={setFirmaRecepcion}
              firmaEmpaque={firmaEmpaque}
              setFirmaEmpaque={setFirmaEmpaque}
              firmaLogistica={firmaLogistica}
              setFirmaLogistica={setFirmaLogistica}></OptionsInspection>
            <h2 className="mt-4">EVIDENCIA DE PRODUCTOS AFECTADOS</h2>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                overflowY: 'auto',
                maxHeight: 'auto'
              }}>
              {data?.evidence?.map((evidenceUrl, index) => {
                const replace = evidenceUrl.replace(/ /g, '%20');
                return (
                  <div key={index} className="div-margin-image-1">
                    <div className="div-margin-image-2">
                      <Image height={80} src={replace} />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mb-5">
              <h2 className="mt-4">OBSERVACIONES</h2>
              <TextArea
                rows={2}
                value={data?.inspections?.observations}
                disabled
                className="mt-2"
              />
            </div>
            <ConsultarFirmas
              data={data}
              nombreElaboro={data?.inspections?.user_created}
              firmaElaboro={data?.inspections?.user_signature_created}
              reviso={false}
            />
            <div className="justify-content-end mt-3">
              <Boton className="mr-2" type="secondary" onClick={downloadPdf}>
                Descargar PDF
              </Boton>

              <Space align="end" direction="horizontal">
                <Boton type="primary" htmlType="submit">
                  Actualizar Inspección
                </Boton>
              </Space>
            </div>
          </Form>
        </div>
      </>
    </Spin>
  );
};

export default ConsultarInspeccionCalidad;
