import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import setToken from '../Utils/setToken';
import decodeToken from '../Utils/decodeToken';
import { matchRoutes, useLocation } from 'react-router-dom';
let decodedToken = decodeToken(localStorage.getItem('token'));
let userArea = decodedToken?.user?.display_name_area;
let isRole = decodedToken?.role?.name;

const denegatedRoutesCreate = [
  '/users',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto',
  '/rutas-de-entrega',
  '/pedidos/:idPedido/crear-incidencia'
];

const denegateroutesComprasAndMesa = [
  '/users',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto',
  '/pedidos/:idPedido/crear-incidencia'
];

if (userArea === 'compras') {
  denegateroutesComprasAndMesa.push('/consultar-inspeccion-calidad/:idInspeccion');
}

const denegatedRoutesRolesAreaBPM = [
  '/pedidos',
  '/pedidos/:id',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto',
  '/consultar-inspeccion-calidad/:idInspeccion',
  '/rutas-de-entrega',
  '/pedidos/:idPedido/crear-incidencia'
];

const denegatedRoutesSgcmcandMuestras = ['/users', '/pedidos/:idPedido/crear-incidencia'];

const denegatedRoutesCalidad = [
  '/users',
  'rutas-de-entrega',
  '/pedidos/:idPedido/crear-incidencia'
];

const denegateRouteLogistica = [
  '/users',
  '/pedidos',
  '/pedidos/:id',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto',
  '/consultar-inspeccion-calidad/:idInspeccion',
  '/pedidos/:idPedido/crear-incidencia'
];

const denegatedRouteAlmacen = [
  '/users',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad',
  '/pedidos/:idPedido/crear-inspeccion-de-calidad/:idProducto',
  '/consultar-inspeccion-calidad/:idInspeccion',
  '/pedidos/:idPedido/crear-incidencia',
  '/rutas-de-entrega'
];

const deparmentDenegatedRolesAreaBPMS = [
  'recursos_humanos',
  'administracion',
  'contabilidad',
  'diseno_operativo',
  'produccion',
  'marketing_pl',
  'importaciones'
];

if (isRole !== 'Super_Admin') {
  denegatedRoutesRolesAreaBPM.push('/users');
}

const isUserAccesingUnauthorizedPath = (userRole, userArea, userDeparment) => {
  const unthorizedPathsForUserRoles = {};

  if (decodedToken?.sub === 280 || userArea === 'sgcmc' || userRole === 'Super_Admin') {
    if (
      (userRole === 'administrator' && userArea === 'direccion') ||
      (userRole === 'equipo_direccion' && userArea === 'ventas') ||
      userArea === 'ventas_bh' ||
      userArea === 'ventas_pl'
    ) {
      unthorizedPathsForUserRoles[userRole] = denegatedRoutesCreate.map((route) => ({
        path: route
      }));
    } else if (
      (userRole === 'equipo_direccion' && userArea === 'direccion') ||
      (userRole === 'equipo_direccion' && userArea === 'proyectos') ||
      userArea === 'muestras' ||
      deparmentDenegatedRolesAreaBPMS.includes(userDeparment)
    ) {
      unthorizedPathsForUserRoles[userRole] = denegatedRoutesRolesAreaBPM.map((route) => ({
        path: route
      }));
    } else if (
      userArea === 'compras' ||
      (userArea === 'mesa_de_control' && decodedToken?.sub !== 280)
    ) {
      console.log('ruta 1');
      unthorizedPathsForUserRoles[userRole] = denegateroutesComprasAndMesa.map((route) => ({
        path: route
      }));
    } else if (
      userArea === 'sgcmc' ||
      decodedToken?.sub === 280
      // || userArea === 'muestras'
    ) {
      console.log('ruta 2');
      unthorizedPathsForUserRoles[userRole] = denegatedRoutesSgcmcandMuestras.map((route) => ({
        path: route
      }));
    } else if (userArea === 'calidad') {
      unthorizedPathsForUserRoles[userRole] = denegatedRoutesCalidad.map((route) => ({
        path: route
      }));
    } else if (userArea === 'logistica') {
      unthorizedPathsForUserRoles[userRole] = denegateRouteLogistica.map((route) => ({
        path: route
      }));
    } else if (userArea === 'almacen') {
      unthorizedPathsForUserRoles[userRole] = denegatedRouteAlmacen.map((route) => ({
        path: route
      }));
    } else {
      unthorizedPathsForUserRoles[userRole] = [{ path: '' }];
    }
  } else {
    unthorizedPathsForUserRoles[userRole] = denegatedRoutesRolesAreaBPM.map((route) => ({
      path: route
    }));
  }

  const location = useLocation();
  const route = matchRoutes(unthorizedPathsForUserRoles[userRole], location);
  return route?.length > 0 ? true : false;
};

const RutaPrivada = () => {
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let isTokenExpired = decodedToken ? decodedToken.exp < Date.now() / 1000 : true;
  if (!isTokenExpired) setToken(localStorage.getItem('token'));
  if (decodedToken?.role?.length === 0) {
    localStorage.removeItem('token');
    return <Navigate to="/login" />;
  }
  return !isTokenExpired ? (
    isUserAccesingUnauthorizedPath(
      decodedToken?.role?.name,
      decodedToken?.user?.display_name_area,
      decodedToken?.user?.display_name_department
    ) ? (
      <Navigate to="/not-found" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default RutaPrivada;
