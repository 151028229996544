export default function getMaximumOfPiecesToReject(b) {
  return (b >= 2) & (b <= 8) //a
    ? 0
    : (b >= 9) & (b <= 15) //b
    ? 0
    : (b >= 16) & (b <= 25) //c
    ? 1
    : (b >= 26) & (b <= 50) //d
    ? 1
    : (b >= 51) & (b <= 90) //e
    ? 1
    : (b >= 91) & (b <= 150) //f
    ? 1
    : (b >= 151) & (b <= 280) //g
    ? 2
    : (b >= 281) & (b <= 500) //h
    ? 3
    : (b >= 501) & (b <= 1200) //i
    ? 4
    : (b >= 1201) & (b <= 3200) //j
    ? 6
    : (b >= 3201) & (b <= 10000) //k
    ? 11
    : (b >= 10001) & (b <= 35000) //l
    ? 15
    : b >= 35001
    ? 25
    : 0;
}
