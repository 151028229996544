import React, { useEffect, useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';
import { Alert, ConfigProvider, DatePicker, Select, Table, Input } from 'antd';
import dayjs from 'dayjs';
import es_ES from 'antd/locale/es_ES';
import decodeToken from '../../Utils/decodeToken';

const ModalCreateDelibery = ({
  isOpenModalUpdateRoute,
  setModalUpdateRoute,
  setLoading,
  loading,
  propData,
  scrollY
}) => {
  const { Option } = Select;
  const [locale] = useState(es_ES);
  const dateFormat = 'YYYY/MM/DD';
  const { TextArea } = Input;
  const [updateDelibery, setUpdateDelibery] = useState([]);
  const [dataDefaultHisory, setDataDefaultHistory] = useState([]);
  const [loadingDelibery, setLoadingDelibery] = useState(true);
  const [loadingData, setLoadingData] = useState(true); // Estado de carga de los datos
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let isRole = decodedToken?.role?.name;

  useEffect(() => {
    if (Object.keys(propData).length !== 0) {
      setLoadingDelibery(true);
      clienteAxios
        .get(`/rutas-de-entrega/show/${propData?.product_id}`)
        .then((response) => {
          setDataDefaultHistory(response?.data?.data?.ruta);
          setLoadingDelibery(false);
          setLoadingData(false); // Indicar que los datos han sido cargados
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [propData]);

  let filteredData = [];

  if (dataDefaultHisory?.length > 0) {
    const groupData = dataDefaultHisory.reduce((acc, item) => {
      if (!acc[item.type_of_destiny]) {
        acc[item.type_of_destiny] = [];
      }
      acc[item.type_of_destiny].push(item);
      return acc;
    }, {});
    filteredData = Object.keys(groupData).map((key) => {
      const dates = groupData[key].map((item) => new Date(item.created_at));
      const maxDate = new Date(Math.max.apply(null, dates));
      return groupData[key].find(
        (item) => new Date(item.created_at).getTime() === maxDate.getTime()
      );
    });
  }
  let arrayNumberProperties = [];

  const showAlert = (message) => {
    Swal.fire({
      icon: 'error',
      title: message
    });
  };

  const validateDates = (item1, item2, message) => {
    if (item1 && item2 && item1.date_of_delivery < item2.date_of_delivery) {
      showAlert(message);
      return true;
    }
    return false;
  };

  const handleCreateRoute = () => {
    let filteredItems = updateDelibery.filter((item) => item !== undefined);
    let agregar = filteredItems?.map((item) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'status_delivery')) {
        return { ...item, status_delivery: 'Pendiente' };
      }
      return item;
    });

    const maquila = agregar.find((item) => item.type_of_destiny === 'Maquila');
    const almacenPL = agregar.find((item) => item.type_of_destiny === 'Almacen PL');
    const almacenPM = agregar.find((item) => item.type_of_destiny === 'Almacen PM');
    const cliente = agregar.find((item) => item.type_of_destiny === 'Cliente');

    if (
      validateDates(
        maquila,
        almacenPL,
        '¡La fecha de entrega de Maquila no puede ser menor a la de Almacen PL!'
      ) ||
      validateDates(
        almacenPM,
        maquila,
        '¡La fecha de entrega de Almacen PM no puede ser menor a la de Maquila!'
      ) ||
      validateDates(
        almacenPM,
        almacenPL,
        '¡La fecha de entrega de Almacen PM no puede ser menor a la de Almacen PL!'
      ) ||
      validateDates(
        cliente,
        almacenPM,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Almacen PM!'
      ) ||
      validateDates(
        cliente,
        maquila,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Maquila!'
      ) ||
      validateDates(
        cliente,
        almacenPL,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Almacen PL!'
      )
    ) {
      return;
    }

    agregar?.length > 0
      ? agregar.forEach((element) => {
          const numProperties = Object.keys(element).length;
          arrayNumberProperties = [...arrayNumberProperties, numProperties];
          if (element !== undefined && arrayNumberProperties.every((numero) => numero > 6)) {
            Swal.fire({
              title: '¿Estás seguro de crear la ruta?',
              icon: 'question',
              confirmButtonText: 'Aceptar',
              denyButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                clienteAxios
                  .post(`rutas-de-entrega/${propData?.code_sale}`, {
                    delivery_route: agregar
                  })
                  .then((response) => {
                    console.log('response', response);
                    Swal.fire({
                      icon: 'success',
                      title: '¡Ruta creada con éxito!'
                    }).then(() => {
                      document.body.classList.remove('body-fixed');
                      window.scrollTo(0, scrollY);
                      setModalUpdateRoute(false);
                      setLoading(!loading);
                      setUpdateDelibery([]);
                    });
                  })
                  .catch((error) => {
                    console.log('error', error);
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...'
                    });
                    document.body.classList.remove('body-fixed');
                    window.scrollTo(0, scrollY);
                    setModalUpdateRoute(false);
                    setLoading(!loading);
                    setUpdateDelibery([]);
                  });
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '¡No has llenado todos los campos de una fila!'
            });
          }
        })
      : Swal.fire({
          icon: 'error',
          title: '¡No se ha seleccionado información del destino!'
        });
  };

  const handleUpdateRoute = () => {
    const filteredItems = updateDelibery.filter((item) => item !== undefined);
    let agregar = filteredItems?.map((item) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'status_delivery')) {
        return { ...item, status_delivery: 'Pendiente' };
      }
      return item;
    });

    const maquila = agregar.find((item) => item.type_of_destiny === 'Maquila');
    const almacenPL = agregar.find((item) => item.type_of_destiny === 'Almacen PL');
    const almacenPM = agregar.find((item) => item.type_of_destiny === 'Almacen PM');
    const cliente = agregar.find((item) => item.type_of_destiny === 'Cliente');

    if (
      validateDates(
        maquila,
        almacenPL,
        '¡La fecha de entrega de Maquila no puede ser menor a la de Almacen PL!'
      ) ||
      validateDates(
        almacenPM,
        maquila,
        '¡La fecha de entrega de Almacen PM no puede ser menor a la de Maquila!'
      ) ||
      validateDates(
        almacenPM,
        almacenPL,
        '¡La fecha de entrega de Almacen PM no puede ser menor a la de Almacen PL!'
      ) ||
      validateDates(
        cliente,
        almacenPM,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Almacen PM!'
      ) ||
      validateDates(
        cliente,
        maquila,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Maquila!'
      ) ||
      validateDates(
        cliente,
        almacenPL,
        '¡La fecha de entrega de Cliente no puede ser menor a la de Almacen PL!'
      )
    ) {
      return;
    }

    agregar?.length > 0
      ? agregar.forEach((element) => {
          const numProperties = Object.keys(element).length;
          arrayNumberProperties = [...arrayNumberProperties, numProperties];
          if (element !== undefined && arrayNumberProperties.every((numero) => numero > 6)) {
            Swal.fire({
              title: '¿Estás seguro de actualizar la ruta?',
              icon: 'question',
              confirmButtonText: 'Aceptar',
              denyButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                clienteAxios
                  .post(`/ruta-de-entrega/${propData?.product_id}`, agregar)
                  .then((response) => {
                    console.log('response', response);
                    Swal.fire({
                      icon: 'success',
                      title: '¡Ruta actualizada con éxito!'
                    }).then(() => {
                      document.body.classList.remove('body-fixed');
                      window.scrollTo(0, scrollY);
                      setModalUpdateRoute(false);
                      setLoading(!loading);
                      setUpdateDelibery([]);
                    });
                  })
                  .catch((error) => {
                    console.log('error', error);
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...'
                    });
                    document.body.classList.remove('body-fixed');
                    window.scrollTo(0, scrollY);
                    setModalUpdateRoute(false);
                    setLoading(!loading);
                    setUpdateDelibery([]);
                  });
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '¡No has llenado todos los campos de una fila!'
            });
          }
        })
      : Swal.fire({
          icon: 'error',
          title: '¡No se ha seleccionado información del destino!'
        });
  };

  const handleCancelUpdateRoute = () => {
    setUpdateDelibery([]);
    setModalUpdateRoute(false);
    document.body.classList.remove('body-fixed');
    window.scrollTo(0, scrollY);
  };

  const dataUpdateDelibery = [
    {
      destino: 'Almacen PL'
    },
    {
      destino: 'Maquila'
    },
    {
      destino: 'Almacen PM'
    },
    {
      destino: 'Cliente'
    }
  ];

  const filterDataRole =
    userArea === 'sgcmc' || isRole === 'Super_Admin' || decodedToken?.sub === 280
      ? dataUpdateDelibery
      : userArea === 'compras'
      ? dataUpdateDelibery.filter(
          (item) =>
            item.destino === 'Almacen PL' ||
            item.destino === 'Maquila' ||
            item.destino === 'Almacen PM'
        )
      : userArea === 'mesa_de_control'
      ? dataUpdateDelibery.filter((item) => item.destino === 'Cliente')
      : null;

  const optionStatus_delivery = [
    {
      value: 'Completo',
      label: 'Completo'
    },
    {
      value: 'Pendiente',
      label: 'Pendiente'
    },
    {
      value: 'Reprogramado',
      label: 'Reprogramado'
    }
  ];

  const filterOptionStatus_delivery =
    userArea === 'sgcmc' || isRole === 'Super_Admin' || decodedToken?.sub === 280
      ? optionStatus_delivery
      : userArea === 'compras' || userArea === 'mesa_de_control'
      ? optionStatus_delivery.filter(
          (item) => item.value === 'Pendiente' || item.value === 'Reprogramado'
        )
      : null;

  const handleChangeTipe = (index, field, value, destino) => {
    const originalValues = filteredData?.find((item) => item?.type_of_destiny === destino);
    ///Si el destino que se esta ingresando tiene type igual a parcial y el status_delivery es igual a completo se debe cambiar el status_delivery a pendiente
    if (
      originalValues?.status_delivery === 'Completo' &&
      originalValues?.type === 'Parcial' &&
      field === 'type' &&
      value === 'Total'
    ) {
      setUpdateDelibery((prevState) => {
        const updatedDelivery = [...prevState];
        updatedDelivery[index] = {
          ...updatedDelivery[index],
          code_order: propData?.code_order,
          product_id: propData?.product_id,
          type_of_destiny: destino,
          status_delivery: 'Pendiente',
          [field]: value
        };

        originalValues !== undefined
          ? Object.keys(originalValues).forEach((key) => {
              if (!(key in updatedDelivery[index])) {
                updatedDelivery[index][key] = originalValues[key];
              }
            })
          : null;
        setUpdateDelibery(updatedDelivery);
      });
    } else {
      setUpdateDelibery((prevState) => {
        const updatedDelivery = [...prevState];
        updatedDelivery[index] = {
          ...updatedDelivery[index],
          code_order: propData?.code_order,
          product_id: propData?.product_id,
          type_of_destiny: destino,
          [field]: value
        };

        originalValues !== undefined
          ? Object.keys(originalValues).forEach((key) => {
              if (!(key in updatedDelivery[index])) {
                updatedDelivery[index][key] = originalValues[key];
              }
            })
          : null;
        setUpdateDelibery(updatedDelivery);
      });
    }
  };

  const disableOption = decodedToken?.role?.name === 'equipo_logistica' ? true : false;
  let message = (
    <>
      <strong>Nota: </strong>
      <span>Si se recibe la última parcialidad del producto, marque</span>
      <strong> Total</strong>
      <span> en el tipo de entrega en la ruta.</span>
    </>
  );
  return (
    <>
      <ReusableModal
        title={propData?.status_product?.length > 0 ? 'ACTUALIZAR RUTA' : 'CREAR RUTA'}
        open={isOpenModalUpdateRoute}
        onOk={propData?.status_product?.length > 0 ? handleUpdateRoute : handleCreateRoute}
        width={1000}
        onCancel={handleCancelUpdateRoute}
        okText={propData?.status_product?.length > 0 ? 'Actualizar Ruta' : 'Crear Ruta'}>
        <>
          <div>
            {loadingData ? (
              <Table loading={loadingDelibery} />
            ) : (
              <>
                <Alert type="warning" message={message} showIcon className="mb-3" />
                <Table
                  loading={loadingDelibery}
                  className="mb-3"
                  columns={[
                    {
                      title: 'DESTINO',
                      dataIndex: 'destino',
                      key: 'destino',
                      fixed: 'left'
                    },
                    {
                      title: 'FECHA DE ENTREGA',
                      dataIndex: 'fecha_entrega',
                      key: 'fecha_entrega',
                      render: (_, record, index) => {
                        const defaultValue = dayjs(
                          filteredData?.find((item) => item?.type_of_destiny === record.destino)
                            ?.date_of_delivery,
                          dateFormat
                        );

                        return (
                          <ConfigProvider locale={locale}>
                            <DatePicker
                              disabled={disableOption}
                              allowClear={false}
                              defaultValue={defaultValue.isValid() ? defaultValue : null}
                              onChange={(date, dateString) =>
                                handleChangeTipe(
                                  index,
                                  'date_of_delivery',
                                  dateString,
                                  record.destino
                                )
                              }
                              disabledDate={(current) =>
                                current && current < dayjs().startOf('day')
                              }
                            />
                          </ConfigProvider>
                        );
                      }
                    },
                    {
                      title: 'TIPO',
                      dataIndex: 'tipo',
                      key: 'tipo',
                      render: (_, record, index) => (
                        <>
                          <Select
                            disabled={disableOption}
                            style={{ width: 100 }}
                            defaultValue={
                              filteredData?.find((item) => item?.type_of_destiny === record.destino)
                                ?.type
                            }
                            onChange={(value) =>
                              handleChangeTipe(index, 'type', value, record.destino)
                            }
                            options={[
                              {
                                value: 'Parcial',
                                label: 'Parcial'
                              },
                              {
                                value: 'Total',
                                label: 'Total'
                              }
                            ]}
                          />
                        </>
                      )
                    },
                    {
                      title: 'ESTADO',
                      dataIndex: 'estado',
                      key: 'estado',
                      render: (_, record, index) => (
                        <>
                          <Select
                            defaultValue={
                              filteredData?.find((item) => item?.type_of_destiny === record.destino)
                                ?.status_delivery || 'Pendiente'
                            }
                            onChange={(value) =>
                              handleChangeTipe(index, 'status_delivery', value, record.destino)
                            }
                            options={filterOptionStatus_delivery}
                          />
                        </>
                      )
                    },
                    {
                      title: 'OBSERVACIÓN',
                      dataIndex: 'observation',
                      key: 'observation',
                      render: (_, record, index) => (
                        <>
                          <TextArea
                            rows={2}
                            defaultValue={
                              filteredData?.find((item) => item?.type_of_destiny === record.destino)
                                ?.observation
                            }
                            onChange={(e) =>
                              handleChangeTipe(index, 'observation', e.target.value, record.destino)
                            }
                          />
                        </>
                      )
                    },
                    {
                      title: 'TIPO DE ENVÍO',
                      dataIndex: 'type_send',
                      key: 'type_send',
                      render: (_, record, index) => (
                        <Select
                          disabled={disableOption}
                          style={{ width: 100 }}
                          defaultValue={
                            filteredData?.find((item) => item?.type_of_destiny === record.destino)
                              ?.shipping_type
                          }
                          onChange={(value) =>
                            handleChangeTipe(index, 'shipping_type', value, record.destino)
                          }>
                          <Option value="Interno">Interno</Option>
                          <Option value="Externo">Externo</Option>
                          <Option value="Paqueteria">Paqueteria</Option>
                        </Select>
                      )
                    }
                  ]}
                  dataSource={filterDataRole.map((item, index) => ({
                    ...item,
                    ...updateDelibery?.[index],
                    key: index
                  }))}
                  pagination={false}
                  scroll={{ y: 300, x: 1000 }}
                />
              </>
            )}
          </div>
        </>
      </ReusableModal>
    </>
  );
};

ModalCreateDelibery.propTypes = {
  isOpenModalUpdateRoute: PropTypes.bool,
  setModalUpdateRoute: PropTypes.func,
  propData: PropTypes.object,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  scrollY: PropTypes.number
};

export default ModalCreateDelibery;
