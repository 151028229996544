import React from 'react';
import { ConfigProvider, Steps } from 'antd';
import PropTypes from 'prop-types';

const circuleSteps = (className, number) => {
  return (
    <div className={className}>
      <span className="circulo_step_number">{number}</span>
    </div>
  );
};

const StatusBarPRoduct = ({ status }) => {
  const stepsColors = status?.reduce((acc, curr) => {
    acc[curr?.status] = curr?.visible === 1 ? 'finish' : curr?.visible === 0 ? 'process' : 'wait';
    return acc;
  }, {});

  const statusSteps = {
    'Almacen PL': 0,
    Maquila: 1,
    'Almacen PM': 2,
    Cliente: 3
  };
  const currentStep =
    statusSteps[status?.[3]?.status] ||
    statusSteps[status?.[2]?.status] ||
    statusSteps[status?.[1]?.status] ||
    statusSteps[status?.[0]?.status];

  const steps = [
    {
      title: 'Almacen PL',
      status:
        stepsColors['Almacen PL'] === 'wait'
          ? stepsColors['Maquila'] === 'finish' || stepsColors['Maquila'] === 'process'
            ? 'wait'
            : stepsColors['Maquila'] === 'wait'
            ? stepsColors['Almacen PM'] === 'finish' || stepsColors['Almacen PM'] === 'process'
              ? 'wait'
              : stepsColors['Almacen PM'] === 'wait'
              ? stepsColors['Cliente'] === 'finish' || stepsColors['Cliente'] === 'process'
                ? 'wait'
                : 'wait'
              : 'wait'
            : 'wait'
          : stepsColors['Almacen PL'] === 'finish'
          ? 'finish'
          : stepsColors['Almacen PL'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['Almacen PL'] === 'wait' ? (
          stepsColors['Maquila'] === 'finish' || stepsColors['Maquila'] === 'process' ? (
            <span>{circuleSteps('circulo_step_error', 'X')}</span>
          ) : stepsColors['Maquila'] === 'wait' ? (
            stepsColors['Almacen PM'] === 'finish' || stepsColors['Almacen PM'] === 'process' ? (
              <span>{circuleSteps('circulo_step_error', 'X')}</span>
            ) : stepsColors['Almacen PM'] === 'wait' ? (
              stepsColors['Cliente'] === 'finish' || stepsColors['Cliente'] === 'process' ? (
                <span>{circuleSteps('circulo_step_error', 'X')}</span>
              ) : null
            ) : null
          ) : null
        ) : stepsColors['Almacen PL'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['Almacen PL'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 1)}</span>
        ) : null
    },
    {
      title: 'Maquila',
      status:
        stepsColors['Maquila'] === 'wait'
          ? stepsColors['Almacen PM'] === 'finish'
            ? 'wait'
            : stepsColors['Almacen PM'] === 'process'
            ? 'wait'
            : stepsColors['Almacen PM'] === 'wait'
            ? 'wait'
            : 'wait'
          : stepsColors['Maquila'] === 'finish'
          ? 'finish'
          : stepsColors['Maquila'] === 'process'
          ? 'process'
          : 'wait',
      icon:
        stepsColors['Maquila'] === 'wait' ? (
          stepsColors['Almacen PM'] === 'finish' || stepsColors['Almacen PM'] === 'process' ? (
            <span>{circuleSteps('circulo_step_error', 'X')}</span>
          ) : stepsColors['Almacen PM'] === 'wait' ? (
            stepsColors['Cliente'] === 'finish' || stepsColors['Cliente'] === 'process' ? (
              <span>{circuleSteps('circulo_step_error', 'X')}</span>
            ) : null
          ) : null
        ) : stepsColors['Maquila'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['Maquila'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 2)}</span>
        ) : null
    },
    {
      title: 'Almacén PM',
      status: stepsColors['Almacen PM'],
      icon:
        stepsColors['Almacen PM'] === 'wait' ? (
          stepsColors['Cliente'] === 'finish' || stepsColors['Cliente'] === 'process' ? (
            <span>{circuleSteps('circulo_step_error', 'X')}</span>
          ) : null
        ) : stepsColors['Almacen PM'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['Almacen PM'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 3)}</span>
        ) : null
    },
    {
      title: 'Cliente',
      status: stepsColors['Cliente'],
      icon:
        stepsColors['Cliente'] === 'finish' ? (
          <span>{circuleSteps('circulo_step_success', '✔')}</span>
        ) : stepsColors['Cliente'] === 'process' ? (
          <span>{circuleSteps('circulo_step_process', 4)}</span>
        ) : null
    }
  ];

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorSplit: '#f4f9ff !important'
          }
        }}>
        <Steps
          size="small"
          className="mt-4 mb-2"
          current={currentStep}
          labelPlacement="vertical"
          items={steps}
        />
      </ConfigProvider>
    </>
  );
};

StatusBarPRoduct.propTypes = {
  status: PropTypes.array
};

export default StatusBarPRoduct;
