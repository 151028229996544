import React, { useEffect, useState } from 'react';
import Boton from '../Components/Atoms/Boton';
import { ReconciliationOutlined, SolutionOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import decodeToken from '../Utils/decodeToken';
import { Tabs, Alert, message } from 'antd';
import Swal from 'sweetalert2';
import FilterRequest from '../Components/Organisms/FilterRequest';
import { useNavigate } from 'react-router-dom';
import rolessg from '../rolessg';

const SolicitudesGasto = () => {
  const [requestsManager, setRequestsManager] = useState([]);
  const [adminRequests, setAdminRequests] = useState([]);
  const [myRequests, setMyRequests] = useState([]);
  const [shopping, setShopping] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;
  let startOfMonth;
  let endOfMonth;
  const [fechainial, setFechainial] = useState(startOfMonth);
  const [fechafinal, setFechafinal] = useState(endOfMonth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataFilterMonthManager, setDataFilterMonthManager] = useState([]);
  const [dataFilterMonthAdmin, setDataFilterMonthAdmin] = useState([]);
  const [dataFilterMonthShopping, setDataFilterMonthShoping] = useState([]);
  const [dataFilterMonthMyOrder, setDataFilterMonthMyOrder] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filterActiveMyOrder, setFilterActiveMyOrder] = useState(false);
  const navigate = useNavigate();

  //END PONIT SOLICITUDES DE GASTO MANAGER
  useEffect(() => {
    if (userRole === 'manager') {
      clienteAxios
        .get(`/sg/view-manager-request`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setRequestsManager(data);
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  //END PONIT SOLICITUDES DE GASTO POR USUARIO
  useEffect(() => {
    clienteAxios
      .get(`/sg/view-my-request`)
      .then((response) => {
        let data = response?.data?.spents;
        data.reverse();
        setMyRequests(data);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
      });
  }, []);

  //END PONIT SOLICITUDES DE GASTO DE QUIEN APRUEBA EL PAGO
  useEffect(() => {
    if (
      userRole === 'caja_chica' ||
      userRole === 'adquisiciones_pl' ||
      userRole === 'adquisiciones_bh'
    ) {
      clienteAxios
        .get(`/sg/view-buyer-request`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setShopping(data);
          setLoading(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  //END PONIT SOLICITUDES DE GASTO - SUPER ADMINISTRADOR
  useEffect(() => {
    if (userRole === 'administrator' || userRole === 'Super_Admin') {
      clienteAxios
        .get(`/sg/view-request-administrator`)
        .then((response) => {
          let data = response.data.spents;
          data.reverse();
          setAdminRequests(data);
          setLoadingAdmin(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: error.response?.data?.message,
            confirmButtonText: 'Aceptar'
          });
        });
    }
  }, [userRole]);

  const handleDatingManager = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/view-manager-request-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthManager(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingMyOrder = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/view-my-request-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthMyOrder(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActiveMyOrder(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingShopping = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/view-buyer-request-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthShoping(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  const handleDatingAdmin = () => {
    message.loading('Filtrando solicitudes...', 0);
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/view-request-administrator-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response.data.spents;
        data.reverse();
        setIsButtonDisabled(false);
        message.destroy();
        setDataFilterMonthAdmin(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActive(true);
      })
      .catch((error) => {
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setIsButtonDisabled(false);
      });
  };

  return (
    <>
      <div className="title-content-between mb-1">
        <h1>Solicitudes de gasto</h1>
        <div className="flex mt-button">
          {userRole === 'administrator' ||
          userRole === 'Super_Admin' ||
          userRole === 'caja_chica' ||
          userRole === 'adquisiciones_pl' ||
          userRole === 'adquisiciones_bh' ? (
            <Boton
              color="primary"
              variant="outlined"
              icon={<ReconciliationOutlined />}
              onClick={() => navigate('/reporte-general')}>
              Reporte General
            </Boton>
          ) : null}
          <Boton
            type="primary"
            className="ml-button"
            icon={<SolutionOutlined />}
            onClick={() => navigate('/nueva-solicitud')}>
            Nueva Solicitud
          </Boton>
        </div>
      </div>
      {userRole === 'administrator' || userRole === 'Super_Admin' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado se muestran todas las 'SOLICITUDES DE GASTO', sin importar el estado en el que se encuentren."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingAdmin}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthAdmin}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthAdmin}
                      dataRequests={adminRequests}
                      loading={loadingAdmin}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      unnamed={true}
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : rolessg.includes(userRole) ? (
        <>
          <FilterRequest
            startOfMonth={startOfMonth}
            endOfMonth={endOfMonth}
            setFechainial={setFechainial}
            setFechafinal={setFechafinal}
            handleDating={handleDatingMyOrder}
            isButton={isButtonDisabled}
            setFilterActive={setFilterActiveMyOrder}
            setDataFilterMonth={setDataFilterMonthMyOrder}
            filterActive={filterActiveMyOrder}
            dataFilterMonth={dataFilterMonthMyOrder}
            dataRequests={myRequests}
            loading={loading}
          />
        </>
      ) : userRole === 'caja_chica' ||
        userRole === 'adquisiciones_bh' ||
        userRole === 'adquisiciones_pl' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes De Adquisición`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las  'SOLICITUDES DE GASTO'  que ya fueron aprobadas por el Administrador para proceder a su compra."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingShopping}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthShoping}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthShopping}
                      dataRequests={shopping}
                      loading={loading}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      unnamed={true}
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : userRole === 'manager' ? (
        <>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `Solicitudes Por Aprobar`,
                children: (
                  <>
                    <Alert
                      message="En este apartado solamente se mostrarán las 'SOLICITUDES DE GASTO' que tienes de tú área."
                      type="info"
                      showIcon
                      closable
                    />
                    <FilterRequest
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingManager}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActive}
                      setDataFilterMonth={setDataFilterMonthManager}
                      filterActive={filterActive}
                      dataFilterMonth={dataFilterMonthManager}
                      dataRequests={requestsManager}
                      loading={loading}
                    />
                  </>
                )
              },
              {
                key: '2',
                label: `Mis Solicitudes`,
                children: (
                  <>
                    <FilterRequest
                      unnamed={true}
                      startOfMonth={startOfMonth}
                      endOfMonth={endOfMonth}
                      setFechainial={setFechainial}
                      setFechafinal={setFechafinal}
                      handleDating={handleDatingMyOrder}
                      isButton={isButtonDisabled}
                      setFilterActive={setFilterActiveMyOrder}
                      setDataFilterMonth={setDataFilterMonthMyOrder}
                      filterActive={filterActiveMyOrder}
                      dataFilterMonth={dataFilterMonthMyOrder}
                      dataRequests={myRequests}
                      loading={loading}
                    />
                  </>
                )
              }
            ]}
          />
        </>
      ) : null}
    </>
  );
};
export default SolicitudesGasto;
