import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Atoms/Modal';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadPhoto = ({ fileList, setFileList }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleCancel = () => setPreviewOpen(false);

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      let newFileList = [...fileList, file];
      setFileList(newFileList);

      return false;
    },
    listType: 'picture-card',
    onPreview: handlePreview
  };
  return (
    <>
      <Upload {...props} accept="image/*">
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Seleccionar foto</div>
        </div>
      </Upload>

      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%'
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

UploadPhoto.propTypes = {
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired
};

export default UploadPhoto;
