import axios from 'axios';
import clienteAxios from './clienteAxios';

//Esto sirve para que el token se ponga por default en todas las peticiones de axios sin tener que agregarlo manualmente cada vez
//Esta función se usa cuando se hace login o cuando se registra un usuario

const setToken = (token) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    clienteAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete clienteAxios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization'];
  }
};

export default setToken;
