import React from 'react';
import PropTypes from 'prop-types';
import { Radio, Slider, Table, Input } from 'antd';
import Boton from '../Atoms/Boton';
import { SearchOutlined } from '@ant-design/icons';
import localeObjectTable from '../../Utils/localeObjectTable';
import Modal from '../Atoms/Modal';
import currencyFormatter from '../../Utils/currencyFormatter';

export const ModalBuscarSolicitud = ({
  isModalOpenRequest,
  showModalRequest,
  handleOptionSerch,
  typeSearch,
  setTotalSerch,
  onSearchRequest,
  loadData,
  dataTable,
  setTotalStart,
  setTotalEnd,
  onRangeRequest
}) => {
  const { Search } = Input;

  const columns = [
    {
      title: 'ID SOLICITUD',
      dataIndex: 'id',
      key: 'id',
      render: (text) => (
        <a href={`/detalle-soliciutd-${text}`} target="_blank" rel="noreferrer">
          GTO-{text}
        </a>
      )
    },
    {
      title: 'NOMBRE',
      dataIndex: 'name_user',
      key: 'name_user'
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company',
      key: 'company'
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total',
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'MÉTODO DE PAGO',
      dataIndex: 'payment_method',
      key: 'payment_method'
    },
    {
      title: 'FECHA DE CREACIÓN',
      dataIndex: 'created_at',
      key: 'created_at'
    }
  ];

  return (
    <Modal
      title="SOLICITUD POR MONTO"
      centered
      open={isModalOpenRequest}
      onCancel={showModalRequest}
      footer={null}
      width={1000}>
      <>
        <span>Buscar por: </span>
        <Radio.Group onChange={handleOptionSerch} value={typeSearch} className="ml-3">
          <Radio value={1}>Total</Radio>
          <Radio value={2}>Rango</Radio>
        </Radio.Group>
        {typeSearch === 1 ? (
          <Search
            placeholder="Escribe el monto de la solictud que bsucar"
            allowClear
            size="large"
            onChange={(e) => setTotalSerch(e.target.value)}
            onSearch={onSearchRequest}
            loading={loadData}
            className="mt-3"
          />
        ) : typeSearch === 2 ? (
          <div className="flex align-self-center">
            <Slider
              range={{
                draggableTrack: true
              }}
              max={10000}
              style={{ flexGrow: 1, maxWidth: 'calc(100% - 50px)' }}
              //manda el valor principal y final del rango en totalStart y totalEnd
              onChange={(value) => {
                setTotalStart(value[0]);
                setTotalEnd(value[1]);
              }}
            />
            <Boton
              color="primary"
              variant="solid"
              shape="circle"
              className="ml-2"
              icon={<SearchOutlined />}
              style={{ flexShrink: 0 }}
              onClick={onRangeRequest}
            />
          </div>
        ) : null}
        <Table
          loading={loadData}
          columns={columns}
          locale={localeObjectTable}
          dataSource={dataTable}
          className="mt-4"
          scroll={{
            x: true
          }}
          pagination={{
            pageSize: 5,
            position: ['bottomCenter']
          }}
        />
        ;
      </>
    </Modal>
  );
};

ModalBuscarSolicitud.propTypes = {
  isModalOpenRequest: PropTypes.bool,
  showModalRequest: PropTypes.func,
  handleOptionSerch: PropTypes.func,
  typeSearch: PropTypes.number,
  setTotalSerch: PropTypes.func,
  onSearchRequest: PropTypes.func,
  loadData: PropTypes.bool,
  dataTable: PropTypes.array,
  setTotalStart: PropTypes.func,
  setTotalEnd: PropTypes.func,
  onRangeRequest: PropTypes.func
};
