import React from 'react';
import '../../Assets/Styles/Molecules/CardModule.css';
import { Link } from 'react-router-dom';

const CardModule = (data) => {
  return (
    <>
      <Link to={data.link}>
        <div className="card_module mb-3 ml-3 mr-3">
          <div className="card_logo">{data.logo}</div>
          <div className="card_data">
            <h3 className="card_name">{data.name}</h3>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CardModule;
