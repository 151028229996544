import { Alert, Checkbox, Col, Form, Radio, Row, Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { FormOutlined } from '@ant-design/icons';
import { useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';
import parseDate from '../../Utils/parseDate';
import moment from 'moment';
// import decodeToken from '../../Utils/decodeToken';

const TablaDetallesCompra = ({ dataPed, products, code_sale, setLoading, loading, disabled }) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [tipeConfir, setTipeConfir] = useState('parcial');
  const [productModal, setProductModal] = useState({ compraId: 0 });
  const [productCheck, setProductCheck] = useState([]);
  const [form] = Form.useForm();

  const columsTableDetallCom = [
    {
      title: 'REFERENCIA',
      dataIndex: 'referencia',
      key: 'referencia',
      fixed: 'left'
    },
    {
      title: 'PROVEEDOR',
      dataIndex: 'proveedor',
      key: 'proveedor'
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'FECHA ACTUALIZACIÓN',
      dataIndex: 'fecha',
      key: 'fecha'
    }
  ];

  // if (
  // userArea === 'compras' ||
  // userArea === 'sgcmc' ||
  // userRole === 'Super_Admin' ||
  // decodedToken?.sub === 280
  // ) {
  disabled === true
    ? columsTableDetallCom.push({
        title: 'ACCIONES',
        dataIndex: 'acciones',
        key: 'acciones'
      })
    : null;
  // }

  const showOpenConfir = () => {
    setOpenConfirm(true);
  };

  const handleCancelConfir = () => {
    setTipeConfir('parcial');
    setProductCheck([]);
    setOpenConfirm(false);
    form.resetFields();
  };

  let checkTrue = Object.values(productCheck).filter((item) => item.checked === true);

  let idProductCheck = [];
  checkTrue.map((item) => {
    idProductCheck.push(item.value);
  });

  const handleConfirmar = (values) => {
    values.code_sale = code_sale;
    values.order_purchase_id = productModal.order_purchase_id;
    checkTrue?.length > 0 && tipeConfir === 'parcial'
      ? (values.id_order_products = idProductCheck)
      : null;

    Swal.fire({
      text: 'Al confirmar esto, aseguras que enviaste la orden y recibiste la confirmación de recibido',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post('confirmation/order/products', values)
          .then(() => {
            Swal.fire({
              title: 'Éxito!',
              text: 'Se registró los datos correctamente.',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });
          })
          .then(() => {
            setLoading(!loading);
            handleCancelConfir();
            setTipeConfir('parcial');
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...'
            });
            setLoading(!loading);
            handleCancelConfir();
            setTipeConfir('parcial');
          });
      }
    });
  };

  let dataTableDetallCom = dataPed.map((item, index) => {
    let obj = {
      key: index,
      referencia: item.code_order,
      status: (
        <>
          {item?.status === 'Cancelado' ? (
            <Tag color="red">{item?.status}</Tag>
          ) : item?.status === 'Pedido de compra' ? (
            <Tag color="green">{item?.status === 'Pedido de compra' ? 'Confirmado' : null}</Tag>
          ) : item?.status === 'Petición presupuesto' ? (
            <Tag color="orange">
              {item?.status === 'Petición presupuesto' ? 'Sin Confirmar' : null}
            </Tag>
          ) : null}
        </>
      ),
      proveedor: item?.provider_name,
      fecha:
        item?.updated_at !== null ? (
          <Row gutter={40} className="justify-content-center">
            <Col>{parseDate(item?.updated_at, 'short')}</Col>
            <Col>{moment(item?.updated_at).format('LT')}</Col>
          </Row>
        ) : (
          <Tag color="red">Sin fecha de actualización</Tag>
        ),

      acciones: (
        <>
          <Boton
            type={item?.Confirmation === 'Confirmado' ? 'primary' : 'secondary'}
            disabled={item?.Confirmation === 'Confirmado' ? true : false}
            onClick={() => {
              setProductModal({
                compraId: item?.code_order,
                order_purchase_id: item?.id
              });
              showOpenConfir(true);
            }}
            icon={<FormOutlined />}>
            Confirmar
          </Boton>
        </>
      )
    };
    return obj;
  });

  const text = (
    <>
      <span>Si confirmas todos los productos, selecciona </span>
      <strong>General</strong>
      <span>. Si no, selecciona </span>
      <strong>Parcial</strong>
    </>
  );

  return (
    <>
      <Table columns={columsTableDetallCom} dataSource={dataTableDetallCom} pagination={false} />

      <ReusableModal
        title="CONFIRMAR ORDEN"
        open={openConfirm}
        width={700}
        onCancel={handleCancelConfir}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}>
        <Alert message={text} type="warning" showIcon className="mb-2" />
        <Form form={form} onFinish={handleConfirmar}>
          <Form.Item
            label="Confirmar"
            labelCol={24}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            }}
            name="value"
            initialValue={'parcial'}
            rules={[
              {
                required: true,
                style: { width: '200%' },
                validator: () => {
                  if (tipeConfir !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Selecciona un tipo de confirmación!'));
                }
              }
            ]}>
            <Radio.Group buttonStyle="solid" onChange={(e) => setTipeConfir(e.target.value)}>
              <Radio.Button value="general">General</Radio.Button>
              <Radio.Button value="parcial">Parcial</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {tipeConfir === 'parcial' && (
            <>
              <Table
                style={{ marginTop: '-10px' }}
                columns={[
                  {
                    title: 'REFERENCIA',
                    dataIndex: 'referencia',
                    key: 'referencia'
                  },
                  {
                    title: 'CHECK',
                    dataIndex: 'check',
                    key: 'check'
                  }
                ]}
                dataSource={products
                  .filter((item) => productModal?.compraId === item?.code_order)
                  .map((item, index) => ({
                    key: index,
                    referencia: item?.description,
                    check: (
                      <>
                        <Checkbox
                          disabled={item?.status === 1 ? true : false}
                          onChange={(e) =>
                            setProductCheck({
                              ...productCheck,
                              [index]: { checked: e.target.checked, value: item?.id }
                            })
                          }
                          checked={item?.status === 1 ? true : productCheck[index]?.checked}
                        />
                      </>
                    )
                  }))}
                pagination={false}
              />
              <Form.Item
                style={{ width: '200%', marginTop: '-30px' }}
                name="id_order_products"
                rules={[
                  {
                    required: true,
                    validator: () => {
                      if (checkTrue.length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Selecciona al menos un producto!'));
                    }
                  }
                ]}>
                <div></div>
              </Form.Item>
            </>
          )}

          <div className="justify-content-end">
            <Boton danger className="mr-2" onClick={handleCancelConfir}>
              Cancelar
            </Boton>

            <Boton type="primary" htmlType="submit">
              Confirmar
            </Boton>
          </div>
        </Form>
      </ReusableModal>
    </>
  );
};

TablaDetallesCompra.propTypes = {
  dataPed: PropTypes.array,
  products: PropTypes.array,
  code_sale: PropTypes.string,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default TablaDetallesCompra;
