import React from 'react';
import propTypes from 'prop-types';
import { Form, Select } from 'antd';
import Modal from '../Atoms/Modal';

const ModalManagerDepar = ({
  isModalOpen,
  handleCancel,
  handleAdd,
  namesUsers,
  setNameManager,
  nameDepartment,
  setNameDepartment,
  OptionDepartment
}) => {
  return (
    <>
      <Modal
        title="ASIGNAR MANGER A DEPARTAMENTO"
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={handleAdd}
        okText="Actualizar"
        cancelText="Cancelar">
        <Form
          className="mt-4"
          labelCol={{
            span: 10
          }}
          labelAlign="left"
          wrapperCol={{
            span: 14
          }}>
          <Form.Item label="Nombre Manager">
            <Select
              placeholder="Seleccione una opción"
              showSearch
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              options={namesUsers}
              onChange={(value) => setNameManager(value)}
            />
          </Form.Item>
          <Form.Item label="Nombre departamento">
            <Select
              placeholder="Seleccione una opción"
              value={nameDepartment}
              showSearch
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
              }
              onChange={(value) => setNameDepartment(value)}>
              {OptionDepartment.map((item) => {
                return (
                  <Select.Option key={item.key} value={item.key} label={item.label}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalManagerDepar;

ModalManagerDepar.propTypes = {
  isModalOpen: propTypes.bool.isRequired,
  handleCancel: propTypes.func.isRequired,
  handleAdd: propTypes.func.isRequired,
  namesUsers: propTypes.array.isRequired,
  setNameManager: propTypes.func.isRequired,
  nameDepartment: propTypes.string.isRequired,
  setNameDepartment: propTypes.func.isRequired,
  OptionDepartment: propTypes.array.isRequired
};
