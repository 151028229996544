import React, { useState } from 'react';
import Boton from '../Atoms/Boton';
import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons';
import Card from './Card';
import { Descriptions, Empty, Tag, Select, Pagination, Alert } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import parseDate from '../../Utils/parseDate';
import decodeToken from '../../Utils/decodeToken';

export default function SeccionHistorialIncidencias({ data, pedidoId }) {
  const [selectedArea, setSelectedArea] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let currentDateMinus30Days = new Date();
  currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);

  const filteredData = data?.filter((incidence) => {
    return selectedArea === null || incidence.area === selectedArea;
  });
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;
  const paginatedData = filteredData?.reverse()?.slice(startIdx, endIdx);

  return (
    <>
      <div className="flex flex-wrap justify-content-between  ">
        <>
          <div className="mb-2">
            <span className="mr-2">Filtrar por área:</span>
            <Select
              className="mb-1"
              placeholder="Selecciona el área a filtrar"
              options={[
                { value: 'Ventas', label: 'Ventas' },
                { value: 'Compras', label: 'Compras' },
                { value: 'Logística', label: 'Logística' },
                { value: 'Almacén', label: 'Almacén' },
                {
                  value: 'Proveedor/Maquilador',
                  label: 'Proveedor/Maquilador'
                },
                {
                  value: 'Administrativo',
                  label: 'Administrativo'
                }
              ]}
              onChange={(value) => setSelectedArea(value)}
              value={selectedArea}
            />
            {(selectedArea !== null && selectedArea !== undefined && (
              <Boton
                className="ml-2"
                style={{
                  border: 'none',
                  height: '20px',
                  boxShadow: 'none',
                  padding: '0px ',
                  color: 'var(--secondary-color-red)'
                }}
                onClick={() => setSelectedArea(null)}>
                <CloseCircleOutlined />
              </Boton>
            )) ||
              null}
          </div>

          {decodedToken?.role?.name === 'ventas' ||
          decodedToken?.role?.name === 'control de calidad' ||
          decodedToken?.role?.name === 'mesa de control' ||
          decodedToken?.role?.name === 'administrator' ? null : (
            <Alert
              message="Solamente Ventas, Control de Calidad y Mesa de Control pueden generar incidencias."
              type="warning"
              showIcon
              closable
            />
          )}

          <div>
            <Link to={`/pedidos/${pedidoId}/crear-incidencia/ventas`} className="mb-2">
              <Boton type="primary" icon={<WarningOutlined />} warning>
                Crear Incidencia
              </Boton>
            </Link>
          </div>

          {/* <div>
            <Link to={`/pedidos/${pedidoId}/crear-incidencia/calidad`} className="mb-2">
              <Boton style={{ display: 'none' }} type="primary" icon={<WarningOutlined />} warning>
                Crear Incidencia Calidad
              </Boton>
            </Link>
          </div> */}
        </>
      </div>

      {paginatedData?.length === 0 || paginatedData === undefined ? (
        <Empty description={<span>No hay incidencias</span>} className="mt-4" />
      ) : (
        paginatedData?.map((incidence, index) => {
          let solutionOfIncidence = '';
          let realTimeToSolution = '';

          if (incidence?.solution_date !== null) {
            solutionOfIncidence = new Date(incidence?.solution_date + 'T00:00:00');
            let creationDate = new Date(incidence?.creation_date + 'T00:00:00');
            realTimeToSolution = (solutionOfIncidence - creationDate) / (1000 * 3600 * 24);
            solutionOfIncidence = incidence?.solution_date;
          }

          return (
            <div key={index}>
              <Card>
                <Descriptions
                  className="mt-2"
                  size="small"
                  bordered
                  column={{ xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}>
                  <Descriptions.Item label="ID incidencia" className="description-header-text">
                    {incidence?.code_incidence}
                  </Descriptions.Item>
                  <Descriptions.Item label="Fecha de creación" className="description-header-text">
                    {parseDate(incidence?.creation_date, 'short')}
                  </Descriptions.Item>
                  <Descriptions.Item label="Área" className="description-header-text">
                    {incidence?.area}
                  </Descriptions.Item>
                  {(incidence?.rol_creator !== 'maquilador' && (
                    <>
                      <Descriptions.Item
                        label="Fecha compromiso de solución"
                        className="description-header-text">
                        {parseDate(incidence?.commitment_date, 'short')}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Fecha real de solución"
                        className="description-header-text">
                        {incidence?.solution_date ? (
                          parseDate(solutionOfIncidence, 'short')
                        ) : (
                          <Tag color="red">No se ha solucionado aún</Tag>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Tiempo real de solución"
                        className="description-header-text">
                        {realTimeToSolution ? (
                          realTimeToSolution + ' días'
                        ) : (
                          <Tag color="red">No se ha solucionado aún</Tag>
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item label="Responsable" className="description-header-text">
                        {incidence?.responsible}
                      </Descriptions.Item>
                    </>
                  )) ||
                    null}
                  <Descriptions.Item label="Elaboró" className="description-header-text">
                    {incidence?.elaborated}
                  </Descriptions.Item>
                  <Descriptions.Item label="Estado" className="description-header-text">
                    <Tag color={incidence?.status === 'Creada' ? 'orange' : 'green'}>
                      {incidence?.status}
                    </Tag>
                  </Descriptions.Item>
                </Descriptions>
                <div className="justify-content-end">
                  <Link to={`/consultar-incidencia/${incidence?.code_incidence}`} className="mt-2 ">
                    Ver informe completo
                  </Link>
                </div>
              </Card>
            </div>
          );
        })
      )}
      <Pagination
        className="display-flex justify-content-end"
        current={currentPage}
        total={filteredData?.length}
        pageSize={itemsPerPage}
        onChange={(page) => setCurrentPage(page)}
      />
    </>
  );
}

SeccionHistorialIncidencias.propTypes = {
  data: PropTypes.array,
  orderStatus: PropTypes.object,
  pedidoId: PropTypes.string
};
