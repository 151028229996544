import { Select, Spin, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import clienteAxios from '../Utils/clienteAxios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import decodeToken from '../Utils/decodeToken';
import Swal from 'sweetalert2';
import { photosEndpoint } from '../Constants/apiEndpoint';
import ModalRegistrarEventuales from '../Components/Organisms/ModalRegistrarEventuales';
import ModalDatosEvetuales from '../Components/Organisms/ModalDatosEvetuales';
import ModalRegresarExcedente from '../Components/Organisms/ModalRegresarExcedente';
import ModalActualizarMonto from '../Components/Organisms/ModalActualizarMonto';
import FormDetalleGasto from '../Components/Organisms/FormDetalleGasto';
import currencyFormatter from '../Utils/currencyFormatter';
import { ArrowLeftOutlined } from '@ant-design/icons';

const DetalleGasto = () => {
  const [newDataRequest, setNewDataRequest] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpenAmount, setIsModalOpenAmount] = useState(false);
  const [isModalOpenExcess, setIsModalOpenExcess] = useState(false);
  const [isModalOpenEventual, setIsModalOpenEventual] = useState(false);
  const [amountUpdate, setAmountUpdate] = useState(0);
  const [quantitySurplus, setQuantitySurplus] = useState(0);
  const [descriptionSurplus, setDescriptionSurplus] = useState('');
  const [archive, setArchive] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [modaldEventWeekend, setModaldEventWeekend] = useState(false);
  const [botonPresionado, setBotonPresionado] = useState(null);
  const [companyEventual, setCompanyEventual] = useState([]);
  const [totalEventuals, setTotalEventuals] = useState(0);
  const [eventualsPay, setEventualsPay] = useState([]);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  const isAdmin = decodedToken?.role?.name === 'administrator';
  const isSuperAdmin = decodedToken?.role?.name === 'Super_Admin';
  const isShopping = decodedToken?.role?.name === 'caja_chica';
  const isAdquisionBH = decodedToken?.role?.name === 'adquisiciones_bh';
  const isAdquisionPL = decodedToken?.role?.name === 'adquisiciones_pl';
  const isUser = decodedToken?.user?.name === newDataRequest?.user_name;
  const isDevolution = newDataRequest.approved_status === 'devolución';
  const isNormal = newDataRequest.type_status === 'normal';
  const isReceived = newDataRequest?.purchase_status === 'Recibido';
  const isCancel = newDataRequest?.type_status !== 'cancelado';
  const isDevolutionReturn = newDataRequest.purchase_table_name === 'pedido-devolución';
  const isPurchaseOrder = newDataRequest?.purchase_table_name === 'pedido-en-compra';
  const navigate = useNavigate();
  let { id } = useParams();
  const [pago, setPago] = useState(0);

  const handleUpdatePay = () => {
    setBotonPresionado('pago');
    showModalEventual();
  };

  const handleUpdateCompany = () => {
    setBotonPresionado('empresa');
    showModalEventual();
  };

  const showModalAmount = () => {
    setIsModalOpenAmount(!isModalOpenAmount);
  };

  const showModalExcess = () => {
    setIsModalOpenExcess(!isModalOpenExcess);
  };

  const showModalEventual = () => {
    setIsModalOpenEventual(!isModalOpenEventual);
  };

  const handleEventualWeekend = () => {
    setModaldEventWeekend(!modaldEventWeekend);
  };

  useEffect(() => {
    clienteAxios
      .get(`/sg/company-eventual`)
      .then((response) => {
        let data = response.data.companies;
        let filteredData = data.filter((item) => item.status === '1');
        setCompanyEventual(
          filteredData.map((item) => ({
            value: item.id,
            label: item.name
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/sg/detail-request/${id}`)
      .then((response) => {
        let data = response.data.data[0];
        setLoading(false);
        setNewDataRequest(data);
        setPago(data?.cc_or_amex === '0001' ? 1 : data?.cc_or_amex === '0002' ? 2 : 4);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: '¡Error!',
          text: 'No se pudo cargar el detalle de la solicitud',
          confirmButtonText: 'ACEPTAR'
        }).then(() => {
          navigate('/solicitudes-gasto');
          window.location.reload();
        });
      });
  }, [id, loading]);

  const openFile = () => {
    if (newDataRequest?.file !== null) {
      window.open(photosEndpoint + newDataRequest?.file);
    }
  };

  const openFileExcess = () => {
    if (newDataRequest?.returnmoney?.file_exchange_returns !== null) {
      window.open(photosEndpoint + newDataRequest?.returnmoney?.file_exchange_returns);
    }
  };

  const openFileMissing = () => {
    if (newDataRequest?.moremoneyeventual?.file !== null) {
      window.open(photosEndpoint + newDataRequest?.moremoneyeventual?.file);
    }
  };

  const openFileSurplus = () => {
    if (newDataRequest?.lessmoneyeventual?.file !== null) {
      window.open(photosEndpoint + newDataRequest?.lessmoneyeventual?.file);
    }
  };

  // API PARA APROBAR POR MANAGER
  const handlePostAuthRequest = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/approve-request-manager`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Autorización de solicitud exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostCancelRequest = () => {
    clienteAxios
      .post(`/sg/cancellation-request`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Solicitud cancelada exitosamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePostReturnRequest = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/return-request`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡La devolución esta proceso!',
          confirmButtonText: 'LISTO',
          confirmButtonColor: '#1677ff'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRejectedReturn = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/return-cancellation`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡La devolución se confirmo como rechazada!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleConfirmedReturn = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/return-confirmed`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Se ha confirmada la devolución!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // API PARA CONFIRMAR RECIBIDO
  const handlePostConfirmReceived = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/request-received`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Confirmación exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setIsButtonDisabled(false);
          setLoading(!loading);
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  //API PARA CONFIRMAR COMPRA
  const handlePostConfirmDelivery = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/confirm delivery-request`, { id: id })
      .then(() => {
        newDataRequest?.purchase_table_name === 'pedido-entregado';
        newDataRequest?.type_statu === 'entregado';
        Swal.fire({
          icon: 'success',
          title: '¡Hecho!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setIsButtonDisabled(false);
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostRejectedRequest = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/reject-request`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Solicitud denegada exitosamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // API PARA APROBAR POR ADMINISTRADOR
  const handlePostAutEnv = () => {
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/approve-request-administrador`, { id: id })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Aprobación de solicitud exitosa!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          navigate('/solicitudes-gasto');
        });
      })
      .catch((error) => {
        setIsButtonDisabled(false);
        console.error(error);
      });
  };

  const handlePostChangePayment = () => {
    clienteAxios
      .post(`/sg/update-payment-method`, { id: id, payment_method_id: pago })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: '¡Método de pago actualizado correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'ACEPTAR'
        });
      });
  };

  const handleReturnSurplus = () => {
    Swal.fire({
      title: `${currencyFormatter(quantitySurplus)}`,
      text: '¿Estás seguro de regresar este monto?',
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('purchase_id', id);
        data.append('total_return', quantitySurplus);
        data.append('description', descriptionSurplus);
        if (!isFileRemoved && archive !== null) {
          data.append('file_exchange_returns', archive);
        }
        clienteAxios
          .post(`/sg/return-excess-money-request`, data)
          .then(() => {
            showModalExcess();
            Swal.fire({
              icon: 'success',
              title: 'Excedente ingresado correctamente!',
              text: 'Por favor, comunícate con el personal de caja chica para devolver el excedente.',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: error.response.data.message,
              confirmButtonText: 'LISTO'
            });
          });
      }
    });
  };

  const handleUpdatePayment = () => {
    Swal.fire({
      title: `${currencyFormatter(amountUpdate)}`,
      text: '¿Estás seguro de actualizar este monto?',
      icon: 'question',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {
          clienteAxios
            .post(`/sg/update-total-request`, {
              id_purchase: id,
              total_update: amountUpdate
            })
            .then(() => {
              showModalAmount();
              Swal.fire({
                icon: 'success',
                title: 'Presupuesto ingresado correctamente!',
                showCancelButton: false,
                confirmButtonText: 'LISTO'
              }).then(() => {
                setLoading(!loading);
              });
            })
            .catch((error) => {
              Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'ACEPTAR'
              });
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSurplusReceived = () => {
    Swal.fire({
      title: '¿Estás seguro de confirmar el excedente?',
      text: 'Una vez confirmado, no se podrá modificar el estado.',
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/sg/return-excess-money-request-confirmation`, { purchase_id: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Excedente confirmado correctamente!',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'No se pudo confirmar el excedente'
            });
          });
      }
    });
  };

  const handleMissingDelivery = () => {
    Swal.fire({
      title: '¿Estás seguro de confirmar la entrega?',
      text: 'Una vez confirmado, no se podrá modificar el estado.',
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/confirmation/returnormore/money/eventuales`, { purchase_id: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Entrega confirmada correctamente!',
              showCancelButton: false,
              confirmButtonText: 'LISTO'
            }).then(() => {
              setLoading(!loading);
            });
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'No se pudo confirmar la entrega!'
            });
          });
      }
    });
  };

  const handlePayChange = (value, key) => {
    const updatedData = newDataRequest.event.map((event, index) => {
      if (index === key) {
        return { ...event, editablePay: value };
      }
      return event;
    });
    setNewDataRequest((prevExpenseDetail) => ({
      ...prevExpenseDetail,
      event: updatedData
    }));
  };

  const handleCompanyChange = (value, key) => {
    const updatedData = newDataRequest.event.map((event, index) => {
      if (index === key) {
        return { ...event, editableCompany: value };
      }
      return event;
    });
    setNewDataRequest((prevExpenseDetail) => ({
      ...prevExpenseDetail,
      event: updatedData
    }));
  };

  const finishFromEventWeekend = (value) => {
    handleSenEventWeekend(value);
  };

  const handleSenEventWeekend = (eventuales) => {
    if (parseFloat(newDataRequest?.total) !== totalEventuals && archive === null) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'No has cargado un comprobante',
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
      return;
    } //API FALLA
    //regresar presupuesto tambien
    let formData = new FormData();
    formData.append('id_purchase', id);
    formData.append('description', eventuales.description);
    formData.append('file', archive);
    eventuales.eventuales.forEach((event, index) => {
      formData.append(`eventuales[${index}][name]`, event.name);
      formData.append(`eventuales[${index}][pay]`, event.pay);
      formData.append(`eventuales[${index}][company]`, event.company);
    });
    setIsButtonDisabled(true);
    clienteAxios
      .post(`/sg/add-eventuals-weekend`, formData)
      .then(() => {
        setModaldEventWeekend(false);
        Swal.fire({
          icon: 'success',
          title: 'Eventuales registrados correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
          setIsButtonDisabled(false);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error al registrar eventuales',
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  const handleEditAny = () => {
    const id_eventual = [];
    const new_pay = [];
    const id_company = [];
    newDataRequest.event.forEach((event) => {
      id_eventual.push(event.id);
      new_pay.push(
        event.editablePay !== undefined && event.editablePay !== null
          ? event.editablePay
          : event.pay
      );
      id_company.push(event.editableCompany || event.company);
    });
    clienteAxios
      .post(`/sg/edit-eventuals`, { purchase_id: id, id_eventual, new_pay, id_company })
      .then(() => {
        setIsModalOpenEventual(false);
        Swal.fire({
          icon: 'success',
          title: 'Eventual (es) actualizado (s) correctamente!',
          confirmButtonText: 'LISTO'
        }).then(() => {
          setLoading(!loading);
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  };

  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PAGO',
      dataIndex: 'pay',
      key: 'pay',
      render: (text) => currencyFormatter(text)
    },
    {
      title: 'EMPRESA',
      dataIndex: 'company_name',
      key: 'company_name'
    }
  ];

  const eventualColumns = [
    {
      title: 'NOMBRE',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'PAGO',
      dataIndex: 'editablePay',
      key: 'editablePay',
      render: (text, record) => {
        if (botonPresionado === 'pago') {
          return (
            <InputNumber
              min={0}
              defaultValue={text}
              onChange={(value) => handlePayChange(value, record.key)}
            />
          );
        } else {
          return currencyFormatter(text);
        }
      }
    },
    {
      title: 'EMPRESA',
      dataIndex: 'editableCompany',
      key: 'editableCompany',
      render: (text, record) => {
        if (botonPresionado === 'empresa') {
          return (
            <Select
              defaultValue={text}
              options={companyEventual}
              className="width-100"
              showSearch
              filterOption={(value, option) =>
                option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0
              }
              onChange={(value) => handleCompanyChange(value, record.key)}
            />
          );
        } else {
          return text;
        }
      }
    }
  ];

  return (
    <>
      <Spin tip="Cargando detalle de solicitud" spinning={loading}>
        <>
          <h1 className="mb-4 mr-10">
            <ArrowLeftOutlined
              className="arrow-size"
              onClick={() => navigate('/solicitudes-gasto')}
            />
            Detalle de la Solicitud
          </h1>

          <FormDetalleGasto
            infoReq={newDataRequest}
            handleUpdateCompany={handleUpdateCompany}
            handleEventualWeekend={handleEventualWeekend}
            handleUpdatePay={handleUpdatePay}
            showModalAmount={showModalAmount}
            showModalExcess={showModalExcess}
            openFileExcess={openFileExcess}
            openFileSurplus={openFileSurplus}
            openFileMissing={openFileMissing}
            handlePostChangePayment={handlePostChangePayment}
            isNormal={isNormal}
            isShopping={isShopping}
            isAdquisionBH={isAdquisionBH}
            isAdquisionPL={isAdquisionPL}
            isCancel={isCancel}
            isPurchaseOrder={isPurchaseOrder}
            isReceived={isReceived}
            isDevolution={isDevolution}
            isDevolutionReturn={isDevolutionReturn}
            decodedToken={decodedToken}
            isUser={isUser}
            columns={columns}
            navigate={navigate}
            isSuperAdmin={isSuperAdmin}
            loading={loading}
            openFile={openFile}
            handlePostRejectedRequest={handlePostRejectedRequest}
            isButtonDisabled={isButtonDisabled}
            handlePostAuthRequest={handlePostAuthRequest}
            isAdmin={isAdmin}
            handlePostAutEnv={handlePostAutEnv}
            handlePostCancelRequest={handlePostCancelRequest}
            handlePostConfirmDelivery={handlePostConfirmDelivery}
            handlePostReturnRequest={handlePostReturnRequest}
            handlePostConfirmReceived={handlePostConfirmReceived}
            handleSurplusReceived={handleSurplusReceived}
            handleMissingDelivery={handleMissingDelivery}
            handleRejectedReturn={handleRejectedReturn}
            handleConfirmedReturn={handleConfirmedReturn}
          />
        </>
      </Spin>

      <ModalActualizarMonto
        isModalOpenAmount={isModalOpenAmount}
        showModalAmount={showModalAmount}
        handleUpdatePayment={handleUpdatePayment}
        setAmountUpdate={setAmountUpdate}
      />

      <ModalRegresarExcedente
        isModalOpenExcess={isModalOpenExcess}
        showModalExcess={showModalExcess}
        handleReturnSurplus={handleReturnSurplus}
        setQuantitySurplus={setQuantitySurplus}
        setDescriptionSurplus={setDescriptionSurplus}
        setArchive={setArchive}
        setIsFileRemoved={setIsFileRemoved}
      />

      <ModalDatosEvetuales
        isModalOpenEventual={isModalOpenEventual}
        showModalEventual={showModalEventual}
        handleEditAny={handleEditAny}
        newDataRequest={newDataRequest}
        eventualColumns={eventualColumns}
      />

      <ModalRegistrarEventuales
        modaldEventWeekend={modaldEventWeekend}
        handleEventualWeekend={handleEventualWeekend}
        finishFromEventWeekend={finishFromEventWeekend}
        newDataRequest={newDataRequest}
        totalEventuals={totalEventuals}
        eventualsPay={eventualsPay}
        setEventualsPay={setEventualsPay}
        setTotalEventuals={setTotalEventuals}
        companyEventual={companyEventual}
        setArchive={setArchive}
        setIsFileRemoved={setIsFileRemoved}
        isButtonDisabled={isButtonDisabled}
      />
    </>
  );
};

export default DetalleGasto;
