import PropTypes from 'prop-types';
import { useRef } from 'react';
import Modal from '../Atoms/Modal';

const ModalVideos = ({ modalVideos, setModalVideos, media }) => {
  const videoRef = useRef();

  const handleOk = () => {
    setModalVideos(false);
  };

  const onCancel = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setModalVideos(false);
  };

  return (
    <Modal
      centered
      title={media?.nombre}
      open={modalVideos}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      width={1000}
      onOk={handleOk}
      okText={'Confirmar'}>
      <video width="100%" ref={videoRef} controls src={media?.url}>
        Tu navegador no soporta la reproducción de videos.
      </video>{' '}
    </Modal>
  );
};

ModalVideos.propTypes = {
  modalVideos: PropTypes.bool,
  setModalVideos: PropTypes.func,
  setMedia: PropTypes.func,
  media: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

export default ModalVideos;
