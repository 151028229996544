import React from 'react';
import { Table, InputNumber, Form } from 'antd';
import '../../Assets/Styles/Organisms/TablaInspeccionProductos.css';
import PropTypes from 'prop-types';

const TablaProductsIncidence = ({
  data,
  productSelec,
  setProductQuantity,
  productQuantity,
  type
}) => {
  const handleChangeInputQuantity = ({
    quantity_selected,
    productindex,
    odoo_product_id,
    order_purchase_product_id,
    code_order
  }) => {
    const newQuantities = [...productQuantity];
    newQuantities[productindex] = {
      quantity_selected,
      odoo_product_id: parseInt(odoo_product_id),
      order_purchase_product_id,
      code_order
    };

    setProductQuantity(newQuantities);
  };

  const columnsProductIncidence = [
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'descripcion',
      key: 'descripcion',
      fixed: 'left'
    },

    {
      title: 'CANTIDAD TOTAL ',
      dataIndex: 'cantidad_total',
      key: 'cantidad_total'
    },
    {
      title: 'CANTIDAD RECIBIDA',
      dataIndex: 'cantidad_recibida',
      key: 'cantidad_recibida',
      render: (text, record, index) =>
        type !== 'get' ? (
          <div>
            <Form.Item
              rules={[
                {
                  required: true,
                  validator: () => {
                    if (productQuantity?.length > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Por favor, ingresa una cantidad!'));
                  }
                }
              ]}>
              <InputNumber
                className="centered-input"
                onChange={(value) =>
                  handleChangeInputQuantity({
                    quantity_selected: value,
                    productindex: index,
                    odoo_product_id: record?.odoo_product_id,
                    order_purchase_product_id: record?.order_purchase_id,
                    code_order: record?.code_order
                  })
                }
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                min={0}
                max={record?.cantidad_total}
                value={productQuantity[index]?.quantity_selected || record?.cantidad_recibida || 0}
              />
            </Form.Item>
          </div>
        ) : (
          record?.cantidad_recibida
        )
    }
  ];

  let datas = [];

  if (productSelec && data !== undefined && data[0] !== undefined) {
    datas = data?.map((product, index) => ({
      key: index,
      referencia: product?.code_order || null,
      descripcion: product?.description,
      proveedor: product?.provider_name,
      cantidad_total: product?.quantity || product?.total || 0,
      cantidad_recibida: product?.quantity_selected,
      odoo_product_id: product?.odoo_product_id,
      code_order: product?.code_order,
      order_purchase_id: product?.id
    }));
  }

  return <Table columns={columnsProductIncidence} dataSource={datas} pagination={false} />;
};

TablaProductsIncidence.propTypes = {
  data: PropTypes.array,
  productSelec: PropTypes.bool,
  setProductQuantity: PropTypes.func,
  productQuantity: PropTypes.array,
  type: PropTypes.string
};

export default TablaProductsIncidence;
