import React, { useEffect, useState } from 'react';
import TablaReporteSolicitudes from '../Components/Organisms/TablaReporteSolicitudes';
import {
  ArrowLeftOutlined,
  BankOutlined,
  RiseOutlined,
  FallOutlined,
  DownOutlined,
  HistoryOutlined,
  BookOutlined,
  SearchOutlined,
  ClearOutlined,
  FileSearchOutlined
} from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DatePicker, message, Radio, Button } from 'antd';
import Boton from '../Components/Atoms/Boton';
import Swal from 'sweetalert2';
import currencyFormatter from '../../src/Utils/currencyFormatter';
import decodeToken from '../Utils/decodeToken';
import es from 'antd/lib/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import { TablaReporteCatalogo } from '../Components/Organisms/TablaReporteCatalogo';
import { ModalIngresarPresupuesto } from '../Components/Organisms/ModalIngresarPresupuesto';
import { ModalEgresarPresupuesto } from '../Components/Organisms/ModalEgresarPresupuesto';
import { ModalBuscarSolicitud } from '../Components/Organisms/ModalBuscarSolicitud';

const ReporteGeneral = () => {
  const navigate = useNavigate();
  const decodedToken = decodeToken(localStorage.getItem('token'));
  const userRole = decodedToken?.role?.name;
  const [loading, setLoading] = useState(true);
  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingCard2, setLoadingCard2] = useState(false);
  const [isModalOpenIncome, setIsModalOpenIncome] = useState(false);
  const [isModalOpenEgress, setIsModalOpenEgress] = useState(false);
  const [isModalOpenRequest, setIsModalOpenRequest] = useState(false);
  const [budgetAmount, setBudgetAmount] = useState(0);
  const [archive, setArchive] = useState(null);
  const [nameReturn, setNameReturn] = useState('');
  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');
  const [fechainial, setFechainial] = useState(startOfMonth.format('YYYY-MM-DD'));
  const [fechafinal, setFechafinal] = useState(endOfMonth.format('YYYY-MM-DD'));
  const { RangePicker } = DatePicker;
  const [disabledRequest, setDisabledRequest] = useState(false);
  const [disabledCatalog, setDisabledCatalog] = useState(false);
  const [optionPayment, setOptionPayment] = useState('solicitudes');
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [dataRequest, setDataRequest] = useState({});
  const [dataCatalog, setDataCatalog] = useState({});
  const [filterRequest, setFilterRequest] = useState([]);
  const [filterCatalog, setFilterCatalog] = useState([]);
  const [filterActiveRequest, setFilterActiveRequest] = useState(false);
  const [filterActiveCatalog, setFilterActiveCatalog] = useState(false);
  const totalCC = filterActiveRequest
    ? filterRequest?.AvailableBudget
    : dataRequest?.AvailableBudget;
  const [totalSerch, setTotalSerch] = useState('');
  const [dataModal, setDataModal] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [typeSearch, setTypeSearch] = useState(1);
  const [totalStart, setTotalStart] = useState('');
  const [totalEnd, setTotalEnd] = useState('');

  const handleOptionPayment = (e) => {
    setOptionPayment(e.target.value);
  };

  const handleOptionSerch = (e) => {
    setTypeSearch(e.target.value);
  };

  const showModalIncome = () => {
    setIsModalOpenIncome(!isModalOpenIncome);
  };

  const showModalEgress = () => {
    setIsModalOpenEgress(!isModalOpenEgress);
  };

  const showModalRequest = () => {
    setIsModalOpenRequest(!isModalOpenRequest);
  };

  //END PONIT SOLICITUDES
  const responseRequest = () => {
    clienteAxios
      .get(`/sg/general-report-request`)
      .then((response) => {
        let data = response?.data;
        setDataRequest(data);
        setLoading(false);
        setLoadingCard(false);
        setLoadingCard2(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    responseRequest();
  }, []);

  const handleDataAdmin = () => {
    message.loading('Filtrando solicitudes...', 0);
    setDisabledRequest(true);
    clienteAxios
      .post(`/sg/general-report-request-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response?.data;
        // data.reverse();
        setDisabledRequest(false);
        message.destroy();
        setFilterRequest(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActiveRequest(true);
      })
      .catch((error) => {
        console.error(error);
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setDisabledRequest(false);
      });
  };

  const responseCatalog = () => {
    clienteAxios
      .get(`/sg/general-report-catalog`)
      .then((response) => {
        let data = response?.data;
        setDataCatalog(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    responseCatalog();
  }, []);

  const handleDataCatalog = () => {
    message.loading('Filtrando solicitudes...', 0);
    setDisabledCatalog(true);
    clienteAxios
      .post(`/sg/general-report-catalog-date`, {
        start: fechainial,
        end: fechafinal
      })
      .then((response) => {
        let data = response?.data;
        // data.reverse();
        setDisabledCatalog(false);
        message.destroy();
        setFilterCatalog(data);
        message.success('Solicitudes filtradas correctamente');
        setFilterActiveCatalog(true);
      })
      .catch((error) => {
        console.error(error);
        message.destroy();
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setDisabledCatalog(false);
      });
  };

  const handleEnterBudget = async () => {
    try {
      if (budgetAmount <= 0) {
        Swal.fire({
          icon: 'error',
          title: '¡Cuidado!',
          confirmButtonText: 'INTENTAR DE NUEVO',
          text: 'No puedes ingresar un monto de $0!'
        });
        return;
      }
      const result = await Swal.fire({
        title: `${currencyFormatter(budgetAmount)}`,
        text: '¿Estás seguro de ingresar este monto?',
        icon: 'question',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        await clienteAxios.post(`/sg/enter-budget`, { total: budgetAmount });
        showModalIncome();
        setLoadingCard(true);
        Swal.fire({
          icon: 'success',
          title: 'Presupuesto ingresado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          setBudgetAmount(0);
          responseRequest();
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'No se pudo ingresar el monto'
      });
    }
  };

  const handleReturnMoney = async () => {
    try {
      const result = await Swal.fire({
        title: 'Cuidado',
        text: '¿Estás seguro de egresar este monto?',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        const data = new FormData();
        data.append('total_returned', totalCC);
        data.append('was_returned_to', nameReturn);
        if (!isFileRemoved) {
          data.append('file', archive);
        }
        await clienteAxios.post(`/sg/budget-return`, data);
        setLoadingCard2(true);
        showModalEgress();
        Swal.fire({
          icon: 'success',
          title: 'Presupuesto egresado correctamente!',
          showCancelButton: false,
          confirmButtonText: 'LISTO'
        }).then(() => {
          responseRequest();
          setNameReturn('');
          setArchive(null);
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        text: error.response.data.message,
        confirmButtonText: 'INTENTAR DE NUEVO'
      });
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Boton
          type="link"
          onClick={showModalIncome}
          icon={<RiseOutlined />}
          style={{
            color: 'var( --secondary-color-green)'
          }}>
          Ingresar Presupuesto
        </Boton>
      )
    },
    {
      key: '2',
      label: (
        <Boton
          type="link"
          onClick={showModalEgress}
          icon={<FallOutlined />}
          style={{ color: 'var(--secondary-color-red)' }}>
          Egresar Presupuesto
        </Boton>
      )
    },
    {
      key: '3',
      label: (
        <Boton
          type="link"
          icon={<HistoryOutlined />}
          style={{ color: 'var( --primary-color)' }}
          onClick={() => navigate('/historial-presupuesto')}>
          Historial Presupuesto
        </Boton>
      )
    },
    {
      key: '4',
      label: (
        <Boton
          type="link"
          icon={<BankOutlined />}
          style={{ color: 'var( --primary-color)' }}
          onClick={() => navigate('/empresas-evetuales')}>
          Empresa Eventuales
        </Boton>
      )
    },
    {
      key: '5',
      label: (
        <Boton
          type="link"
          icon={<BookOutlined />}
          style={{ color: 'var( --primary-color)' }}
          onClick={() => navigate('/catalogo-conceptos')}>
          Catálogo Conceptos
        </Boton>
      )
    },
    {
      key: '6',
      label: (
        <Boton
          type="link"
          icon={<FileSearchOutlined />}
          style={{ color: 'var( --primary-color)' }}
          onClick={showModalRequest}>
          Solicitud por monto
        </Boton>
      )
    }
  ];

  const onSearchRequest = () => {
    if (totalSerch === '') {
      Swal.fire({
        icon: 'error',
        title: '¡Cuidado!',
        confirmButtonText: 'INTENTAR DE NUEVO',
        text: 'Debes ingresar un monto para buscar'
      });
      return;
    }
    setLoadData(true);
    clienteAxios
      .post(`/sg/request-total`, {
        total: totalSerch
      })
      .then((response) => {
        let data = response?.data;
        setDataModal(data);
        setLoadData(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setLoadData(false);
      });
  };

  const onRangeRequest = () => {
    setLoadData(true);
    clienteAxios
      .post(`/sg/request-range-total`, {
        total_start: totalStart,
        total_end: totalEnd
      })
      .then((response) => {
        let data = response?.data;
        setDataModal(data);
        setLoadData(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message,
          confirmButtonText: 'Aceptar'
        });
        setLoadData(false);
      });
  };

  const dataTable = dataModal?.purchase_by_total?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      name_user: item?.name_user,
      total: item?.total,
      payment_method: item?.payment_method,
      created_at: item?.created_at,
      company: item?.company
    };
  });

  return (
    <>
      <div className="title-content-between">
        <h1 className="mb-2 mr-5">
          <ArrowLeftOutlined
            className="arrow-size"
            onClick={() => navigate('/solicitudes-gasto')}
          />
          Reporte General
        </h1>
        {[
          'administrator',
          'adquisiciones_bh',
          'adquisiciones_pl',
          'Super_Admin',
          'caja_chica'
        ].includes(userRole) ? (
          <Dropdown
            menu={{
              items
            }}
            trigger={['click']}
            placement="bottomLeft">
            <Boton color="primary" variant="outlined">
              Más opciones
              <DownOutlined />
            </Boton>
          </Dropdown>
        ) : null}
      </div>

      <Radio.Group
        className="mt-4"
        buttonStyle="solid"
        style={{
          marginBottom: 16
        }}
        onChange={handleOptionPayment}
        defaultValue="solicitudes">
        <Radio.Button value="solicitudes">Solicitudes</Radio.Button>

        <Radio.Button value="catalogo">Catálogo</Radio.Button>
      </Radio.Group>

      <>
        {optionPayment === 'solicitudes' ? (
          <>
            <div className="flex-filter align-items-center">
              <p className="mr-button mb-button">Solicitudes mostradas de: </p>
              <div className="flex-row align-items-center">
                <RangePicker
                  format="DD-MM-YYYY"
                  placeholder={['Fecha de inicio', 'Fecha de fin']}
                  locale={es}
                  defaultValue={[startOfMonth, endOfMonth]}
                  onChange={(dates) => {
                    if (dates) {
                      setFechainial(dates[0].format('YYYY-MM-DD'));
                      setFechafinal(dates[1].format('YYYY-MM-DD'));
                    }
                  }}
                />
                <Button
                  color="primary"
                  variant="solid"
                  shape="circle"
                  className="ml-2"
                  disabled={disabledRequest}
                  onClick={() => {
                    handleDataAdmin();
                  }}
                  icon={<SearchOutlined />}
                />
                <Button
                  color="danger"
                  variant="outlined"
                  shape="circle"
                  className="ml-2"
                  disabled={disabledRequest}
                  onClick={() => {
                    setFilterActiveRequest(false);
                    setFilterRequest([]);
                  }}
                  icon={<ClearOutlined />}
                />
              </div>
            </div>

            <TablaReporteSolicitudes
              data={filterActiveRequest ? filterRequest : dataRequest}
              userRole={userRole}
              loading={loading}
              loadingCard={loadingCard}
              loadingCard2={loadingCard2}
            />
          </>
        ) : optionPayment === 'catalogo' ? (
          <>
            <div className="flex-filter align-items-center">
              <p className="mr-button mb-button">Solicitudes mostradas de: </p>
              <div className="flex-row align-items-center">
                <RangePicker
                  format="DD-MM-YYYY"
                  placeholder={['Fecha de inicio', 'Fecha de fin']}
                  locale={es}
                  defaultValue={[startOfMonth, endOfMonth]}
                  onChange={(dates) => {
                    if (dates) {
                      setFechainial(dates[0].format('YYYY-MM-DD'));
                      setFechafinal(dates[1].format('YYYY-MM-DD'));
                    }
                  }}
                />
                <Button
                  color="primary"
                  variant="solid"
                  shape="circle"
                  className="ml-2"
                  disabled={disabledCatalog}
                  onClick={() => {
                    handleDataCatalog();
                  }}
                  icon={<SearchOutlined />}
                />
                <Button
                  color="danger"
                  variant="outlined"
                  shape="circle"
                  className="ml-2"
                  danger
                  disabled={disabledCatalog}
                  onClick={() => {
                    setFilterActiveCatalog(false);
                    setFilterCatalog([]);
                    message.success('Filtros eliminados con éxito');
                  }}
                  icon={<ClearOutlined />}
                />
              </div>
            </div>
            <TablaReporteCatalogo
              data={filterActiveCatalog ? filterCatalog : dataCatalog}
              loading={loading}
              userRole={userRole}
            />
          </>
        ) : null}
      </>

      <ModalIngresarPresupuesto
        isModalOpenIncome={isModalOpenIncome}
        showModalIncome={showModalIncome}
        handleEnterBudget={handleEnterBudget}
        budgetAmount={budgetAmount}
        setBudgetAmount={setBudgetAmount}
      />

      <ModalEgresarPresupuesto
        isModalOpenEgress={isModalOpenEgress}
        showModalEgress={showModalEgress}
        handleReturnMoney={handleReturnMoney}
        totalCC={totalCC}
        nameReturn={nameReturn}
        setArchive={setArchive}
        setIsFileRemoved={setIsFileRemoved}
        archive={archive}
        setNameReturn={setNameReturn}
      />

      <ModalBuscarSolicitud
        isModalOpenRequest={isModalOpenRequest}
        showModalRequest={showModalRequest}
        handleOptionSerch={handleOptionSerch}
        typeSearch={typeSearch}
        setTotalSerch={setTotalSerch}
        onSearchRequest={onSearchRequest}
        loadData={loadData}
        dataTable={dataTable}
        setTotalStart={setTotalStart}
        setTotalEnd={setTotalEnd}
        onRangeRequest={onRangeRequest}
      />
    </>
  );
};

export default ReporteGeneral;
