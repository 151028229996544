import { Form, Alert, Tag, Table, Tooltip, Typography, Skeleton } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import Boton from '../Atoms/Boton';

export default function FormDetalleGasto({
  infoReq,
  handleUpdateCompany,
  handleEventualWeekend,
  handleUpdatePay,
  showModalAmount,
  showModalExcess,
  openFileExcess,
  openFileSurplus,
  openFileMissing,
  handlePostChangePayment,
  isNormal,
  isShopping,
  isAdquisionBH,
  isAdquisionPL,
  isCancel,
  isPurchaseOrder,
  isReceived,
  isDevolution,
  isDevolutionReturn,
  decodedToken,
  isUser,
  columns,
  isSuperAdmin,
  loading,
  openFile,
  handlePostRejectedRequest,
  isButtonDisabled,
  handlePostAuthRequest,
  isAdmin,
  handlePostAutEnv,
  handlePostCancelRequest,
  handlePostConfirmDelivery,
  handlePostReturnRequest,
  handlePostConfirmReceived,
  handleSurplusReceived,
  handleMissingDelivery,
  handleRejectedReturn,
  handleConfirmedReturn
}) {
  return (
    <>
      <>
        {infoReq?.approved_status !== 'rechazada' &&
        isUser &&
        infoReq?.purchase_table_name === 'pedido-en-proceso' ? (
          <Alert
            message="Una vez generada la 'SOLICITUD DE GASTO' ya no puedes hacer ningún cambio, en caso de alguna equivocación pide a tú 'JEFE DIRECTO' que deniegue la solicitud y genera una nueva."
            type="warning"
            showIcon
            closable
          />
        ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
          isCancel &&
          infoReq?.payment_method_id === 4 &&
          isPurchaseOrder ? (
          <Alert
            message="Una vez hayas actualizado el método de pago, podrás confirmar la compra."
            type="info"
            showIcon
            closable
          />
        ) : decodedToken?.role?.name !== 'caja_chica' &&
          isCancel &&
          infoReq?.type_status !== 'devolución' &&
          infoReq?.purchase_table_name === 'pedido-entregado' ? (
          <Alert
            message="Rectifica que tú producto este en buen estado o que el servicio se cumplio correctamente,
            si no es asi pide al comprador que deseas realizar la devolución."
            type="info"
            showIcon
            closable
          />
        ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
          isUser &&
          isCancel &&
          infoReq?.purchase_table_name === 'pedido-entregado' ? (
          <Alert
            message="Rectifica que el producto este en buen estado o que el servicio se cumplio correctamente, si es así, presentate con la persona que realizo la 'SOLICITUD DE GASTO'
            para ver si cumple con sus condiciones, de lo contrario realiza la devolución."
            type="info"
            showIcon
            closable
          />
        ) : null}
      </>

      {loading ? (
        <Skeleton className="skeleton-container" />
      ) : (
        <Form
          className="bold-form mt-4"
          labelCol={{
            span: 7
          }}
          labelAlign="left"
          wrapperCol={{
            span: 10
          }}
          layout="horizontal">
          <Form.Item label="Nombre Completo">
            <span>{infoReq?.user_name}</span>
          </Form.Item>
          <Form.Item label="Empresa">
            <span>{infoReq.company && infoReq.company[0]?.company_name}</span>
          </Form.Item>
          <Form.Item label="Concepto General">
            <span>{infoReq?.spent_general}</span>
          </Form.Item>
          <Form.Item label="Concepto Específico">
            <span>{infoReq?.spent_specific}</span>
          </Form.Item>
          {infoReq.event && infoReq.event.length > 0 ? (
            <>
              <Form.Item label="Eventuales">
                {infoReq?.spent_specific !== 'Eventuales' ? (
                  <Tooltip title="Haz clic aquí si la empresa de algún eventual es incorrecta o 'Sin definir'">
                    <Typography.Link className="ml-2" onClick={handleUpdateCompany}>
                      Actualizar empresa eventuales
                    </Typography.Link>
                  </Tooltip>
                ) : null}
              </Form.Item>
              <Form.Item>
                <Table
                  columns={columns}
                  dataSource={infoReq.event.map((event) => ({
                    key: uuidv4(),
                    name: event.name,
                    pay: event.pay,
                    company_name: event.company_name
                  }))}
                  pagination={{
                    position: ['bottomCenter'],
                    defaultPageSize: 3
                  }}
                />
              </Form.Item>
            </>
          ) : null}
          {infoReq?.spent_specific === 'Eventuales fin de semana' && infoReq.event.length === 0 ? (
            <>
              <Form.Item label="Eventuales">
                <span>SIN DEFINIR</span>
                <Tooltip title="Haz clic aquí si no registraste eventuales">
                  <Typography.Link className="ml-2" onClick={handleEventualWeekend}>
                    Registrar Eventuales
                  </Typography.Link>
                </Tooltip>
              </Form.Item>
            </>
          ) : null}

          <Form.Item label="Estado">
            {infoReq && (
              <>
                {isNormal && infoReq.approved_status === 'pendiente' && (
                  <Tag color="geekblue">EN PROCESO</Tag>
                )}
                {infoReq.approved_status === 'en aprobación por administrador' && isNormal && (
                  <Tag color="geekblue">EN PROCESO</Tag>
                )}
                {infoReq.approved_status === 'rechazada' && <Tag color="error">RECHAZADO</Tag>}
                {isNormal && infoReq.purchase_table_name === 'pedido-entregado' && (
                  <Tag color="gold">POR ENTREGAR</Tag>
                )}
                {infoReq.approved_status === 'aprobada' &&
                  isNormal &&
                  infoReq.purchase_table_name === 'pedido-recibido' && (
                    <Tag color="success">RECIBIDO</Tag>
                  )}
                {isDevolution && infoReq.type_status === 'en proceso' && isDevolutionReturn && (
                  <Tag color="gold">DEVOLUCIÓN EN PROCESO</Tag>
                )}
                {isDevolution && infoReq.type_status === 'rechazada' && isDevolutionReturn && (
                  <Tag color="red">DEVOLUCIÓN RECHAZADA</Tag>
                )}
                {isDevolution && isNormal && isDevolutionReturn && (
                  <Tag color="magenta">DEVOLUCIÓN COMPLETA</Tag>
                )}
                {infoReq.purchase_table_name === 'pedido-en-compra' && isNormal && (
                  <Tag color="purple">ADQUISICIÓN</Tag>
                )}
                {infoReq.purchase_table_name === 'pedido-en-compra' &&
                  infoReq.type_status === 'cancelado' && <Tag color="error">RECHAZADO</Tag>}
              </>
            )}
          </Form.Item>

          <Form.Item label="Método de pago">
            <>
              <span>
                {infoReq?.payment_method_id === 1
                  ? 'Efectivo'
                  : infoReq?.payment_method_id === 2
                  ? 'American Express'
                  : infoReq?.payment_method_id === 4
                  ? 'Sin método de pago'
                  : 'Sin definir'}
              </span>
              {(isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
              infoReq?.type_status === 'normal' &&
              isPurchaseOrder &&
              infoReq?.payment_method_id === 4 ? (
                <Tooltip title="Haz clic para actualizar el método de pago">
                  <Typography.Link className="ml-3" onClick={handlePostChangePayment}>
                    Confirmar compra
                  </Typography.Link>
                </Tooltip>
              ) : null}
            </>
          </Form.Item>

          <Form.Item label="Descripción de la necesidad">
            <span
              className="ant-form-text"
              style={{ overflowWrap: 'anywhere', textAlign: 'justify' }}>
              {infoReq?.description}
            </span>
          </Form.Item>
          <Form.Item label="Importe">
            <>
              <span>{currencyFormatter(infoReq?.total)}</span>
              {infoReq?.payment_method_id !== 4 &&
              (isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
              infoReq?.purchase_table_name !== 'pedido-en-proceso' &&
              isPurchaseOrder &&
              ['Eventuales Maquila', 'Eventuales'].includes(infoReq?.spent_specific) ? (
                <>
                  <Tooltip title="Haz clic aquí si el importe de algún eventual es incorrecto">
                    <Typography.Link className="ml-2" onClick={handleUpdatePay}>
                      Actualizar pago eventuales
                    </Typography.Link>
                  </Tooltip>
                </>
              ) : (isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
                infoReq?.payment_method_id !== 4 &&
                infoReq?.spent_specific !== 'Eventuales fin de semana' &&
                isCancel &&
                isPurchaseOrder ? (
                <Tooltip title="Actualiza el importe de pago en caso de que el precio haya cambiado al momento de la compra">
                  <Typography.Link className="ml-2" onClick={showModalAmount}>
                    Actualizar pago
                  </Typography.Link>
                </Tooltip>
              ) : isReceived &&
                isUser &&
                // infoReq?.returnmoney.length === 0 &&
                infoReq?.payment_method_id === 1 &&
                !['EVENTUALES', 'EVENTUALES FIN DE SEMANA', 'EVENTUALES MAQUILA'].includes(
                  infoReq?.spent_specific
                ) ? (
                <Tooltip title="Haz clic aquí si posees algún excedente de efectivo">
                  <Typography.Link className="ml-2" onClick={showModalExcess}>
                    ¿Posees algún excedente de efectivo?
                  </Typography.Link>
                </Tooltip>
              ) : null}
            </>
          </Form.Item>

          {/* CAMPO PARA MOSTRAR LA FIRMA DE DEVOLCUION DE EXCEDENE */}
          {!Array.isArray(infoReq?.returnmoney) ? (
            <Form.Item className="text-align-jusify" label="Devolución de excedente">
              <span>
                <strong>{infoReq?.returnmoney?.return_user_id}</strong> al ingresar la solicitud, el
                importe era de <strong>{`$${infoReq?.returnmoney?.previous_total}`}</strong> pero
                devolvió la suma de{' '}
                <strong>
                  {infoReq?.returnmoney?.total_return && `$${infoReq?.returnmoney?.total_return}`}
                </strong>{' '}
                con el motivo de <strong>{infoReq?.returnmoney?.description}</strong> con fecha y
                hora de <strong>{infoReq?.returnmoney?.created_at}</strong>{' '}
                {infoReq?.returnmoney?.file_exchange_returns && (
                  <>
                    <span> junto al </span>
                    <a onClick={openFileExcess}>Comprobante</a>{' '}
                  </>
                )}
                y el estado actual de recibido de exceso de fondo es{' '}
                <Tag color={infoReq?.returnmoney?.status === 'Sin confirmar' ? 'error' : 'success'}>
                  {infoReq?.returnmoney?.status}
                </Tag>
                {infoReq?.returnmoney?.status === 'Confirmado' && (
                  <>
                    por la persona <strong>{infoReq?.returnmoney?.confirmation_user_id}</strong> con
                    fecha y hora de <strong>{infoReq?.returnmoney?.confirmation_datetime}</strong>
                  </>
                )}
              </span>
            </Form.Item>
          ) : null}

          {/* CAMPO PARA MOSTRAR LA FIRMA DE ENTREGA DE SOBRANTE */}
          {!Array.isArray(infoReq?.lessmoneyeventual) ? (
            <Form.Item style={{ textAlign: 'justify' }} label="Entrega de sobrante">
              <span>
                <strong>{infoReq?.lessmoneyeventual?.id_applicant_person}</strong> al ingresar la
                solicitud el importe, era de{' '}
                <strong>${infoReq?.lessmoneyeventual?.previous_total}</strong> pero al registrar los
                eventuales con fecha y hora de{' '}
                <strong>{infoReq?.lessmoneyeventual?.created_at}</strong> por el motivo de{' '}
                <strong>{infoReq?.lessmoneyeventual?.description}</strong> , el monto se actualizo a{' '}
                <strong>${infoReq?.lessmoneyeventual?.current_total}</strong>, se adjunta el{' '}
                <a onClick={openFileSurplus}>Comprobante</a> y el estado de entrega de faltante es{' '}
                <Tag
                  color={
                    infoReq?.lessmoneyeventual?.status === 'Sin confirmar' ? 'error' : 'success'
                  }>
                  {infoReq?.lessmoneyeventual?.status}
                </Tag>
                {infoReq?.lessmoneyeventual?.status === 'Confirmado' && (
                  <>
                    por la persona{' '}
                    <strong>{infoReq?.lessmoneyeventual?.id_person_who_delivers}</strong> con fecha
                    y hora de <strong>{infoReq?.lessmoneyeventual?.confirmation_datetime}</strong>
                  </>
                )}
              </span>
            </Form.Item>
          ) : null}

          {/* CAMPO PARA MOSTRAR LA ENTREGA DE FALTANTE */}
          {!Array.isArray(infoReq?.moremoneyeventual) ? (
            <Form.Item className="text-align-jusify" label="Entrega de faltante">
              <span>
                <strong>{infoReq?.moremoneyeventual?.id_applicant_person}</strong> al ingresar la
                solicitud el importe, era de{' '}
                <strong>${infoReq?.moremoneyeventual?.previous_total}</strong> pero al registrar los
                eventuales con fecha y hora de{' '}
                <strong>{infoReq?.moremoneyeventual?.created_at}</strong> por el motivo de{' '}
                <strong>{infoReq?.moremoneyeventual?.description}</strong> , el monto se actualizo a{' '}
                <strong>${infoReq?.moremoneyeventual?.current_total}</strong>, se adjunta el{' '}
                <a onClick={openFileMissing}>Comprobante</a> y el estado de entrega de faltante es{' '}
                <Tag
                  color={
                    infoReq?.moremoneyeventual?.status === 'Sin confirmar' ? 'error' : 'success'
                  }>
                  {infoReq?.moremoneyeventual?.status}
                </Tag>
                {infoReq?.moremoneyeventual?.status === 'Confirmado' && (
                  <>
                    por la persona{' '}
                    <strong>{infoReq?.moremoneyeventual?.id_person_who_delivers}</strong> con fecha
                    y hora de <strong>{infoReq?.moremoneyeventual?.confirmation_datetime}</strong>
                  </>
                )}
              </span>
            </Form.Item>
          ) : null}
        </Form>
      )}

      {infoReq?.file && (
        <Boton color="primary" variant="outlined" icon={<EyeOutlined />} onClick={openFile}>
          Visualizar Archivo o Imagen
        </Boton>
      )}

      <div className="flex justify-content-end">
        {decodedToken?.role?.name === 'manager' && infoReq?.approved_status === 'pendiente' ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              className="ml-1"
              htmlType="submit"
              disabled={isButtonDisabled}
              onClick={handlePostRejectedRequest}>
              Denegar
            </Boton>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handlePostAuthRequest}>
              Autorizar
            </Boton>
          </>
        ) : (isAdmin || isSuperAdmin) &&
          infoReq?.purchase_status === 'En proceso' &&
          ['pendiente', 'en aprobación por administrador'].includes(infoReq?.approved_status) ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              danger
              htmlType="submit"
              className="ml-1"
              onClick={handlePostRejectedRequest}
              disabled={isButtonDisabled}>
              Denegar
            </Boton>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              onClick={handlePostAutEnv}
              disabled={isButtonDisabled}>
              Autorizar
            </Boton>
          </>
        ) : (isShopping || isAdquisionPL || isAdquisionBH || isSuperAdmin) &&
          isCancel &&
          infoReq?.purchase_table_name === 'pedido-en-compra' ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              htmlType="submit"
              disabled={isButtonDisabled || infoReq?.payment_method_id !== 4}
              onClick={handlePostCancelRequest}>
              Cancelar entrega
            </Boton>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-form"
              disabled={isButtonDisabled || infoReq?.payment_method_id === 4}
              onClick={handlePostConfirmDelivery}>
              Confirmar entrega
            </Boton>
          </>
        ) : infoReq?.purchase_table_name === 'pedido-entregado' && isUser ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              htmlType="submit"
              disabled={isButtonDisabled}
              onClick={handlePostReturnRequest}>
              Realizar devolución
            </Boton>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-form"
              disabled={isButtonDisabled}
              onClick={handlePostConfirmReceived}>
              Confirmar de recibido
            </Boton>
          </>
        ) : (isAdquisionPL || isAdquisionBH || isAdmin || isSuperAdmin) &&
          infoReq?.purchase_table_name === 'pedido-entregado' &&
          infoReq?.payment_method_id === 2 &&
          infoReq?.approved_status === 'aprobada' ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handlePostReturnRequest}>
              Realizar devolución
            </Boton>
          </>
        ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
          infoReq?.returnmoney?.status === 'Sin confirmar' &&
          !Array.isArray(infoReq?.returnmoney) &&
          isReceived ? (
          <>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handleSurplusReceived}>
              Confirmar devolución excedente
            </Boton>
          </>
        ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
          infoReq?.moremoneyeventual &&
          infoReq?.moremoneyeventual?.status === 'Sin confirmar' &&
          isReceived ? (
          <>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handleMissingDelivery}>
              Confirmar entrega de faltante
            </Boton>
          </>
        ) : (isShopping || isAdquisionPL || isAdquisionBH) &&
          infoReq?.lessmoneyeventual &&
          infoReq?.lessmoneyeventual?.status === 'Sin confirmar' &&
          isReceived ? (
          <>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handleMissingDelivery}>
              Confirmar entrega de sobrante
            </Boton>
          </>
        ) : infoReq?.approved_status === 'devolución' &&
          infoReq?.type_status === 'en proceso' &&
          (isShopping || isAdquisionPL || isAdquisionBH || isUser) &&
          infoReq?.purchase_table_name === 'pedido-devolución' ? (
          <>
            <Boton
              color="danger"
              variant="outlined"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handleRejectedReturn}>
              Devolución rechazada
            </Boton>
            <Boton
              type="primary"
              htmlType="submit"
              className="ml-4"
              disabled={isButtonDisabled}
              onClick={handleConfirmedReturn}>
              Confirmar devolución
            </Boton>
          </>
        ) : null}
      </div>
    </>
  );
}

FormDetalleGasto.propTypes = {
  infoReq: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  handleUpdateCompany: PropTypes.func,
  handleEventualWeekend: PropTypes.func,
  handleUpdatePay: PropTypes.func,
  showModalAmount: PropTypes.func,
  showModalExcess: PropTypes.func,
  openFileExcess: PropTypes.func,
  openFileSurplus: PropTypes.func,
  openFileMissing: PropTypes.func,
  handlePostChangePayment: PropTypes.func,
  isNormal: PropTypes.bool,
  isShopping: PropTypes.bool,
  isCancel: PropTypes.bool,
  isPurchaseOrder: PropTypes.bool,
  isReceived: PropTypes.bool,
  isDevolution: PropTypes.bool,
  isDevolutionReturn: PropTypes.bool,
  isAdmin: PropTypes.bool,
  decodedToken: PropTypes.object,
  selectedDate: PropTypes.object,
  isUser: PropTypes.bool,
  columns: PropTypes.array,
  isSuperAdmin: PropTypes.bool,
  isAdquisionBH: PropTypes.bool,
  isAdquisionPL: PropTypes.bool,
  loading: PropTypes.bool,
  openFile: PropTypes.func,
  handlePostRejectedRequest: PropTypes.func,
  isButtonDisabled: PropTypes.bool,
  handlePostAuthRequest: PropTypes.func,
  handlePostAutEnv: PropTypes.func,
  handlePostCancelRequest: PropTypes.func,
  handlePostConfirmDelivery: PropTypes.func,
  handlePostReturnRequest: PropTypes.func,
  handlePostConfirmReceived: PropTypes.func,
  handleSurplusReceived: PropTypes.func,
  handleMissingDelivery: PropTypes.func,
  handleRejectedReturn: PropTypes.func,
  handleConfirmedReturn: PropTypes.func
};
