import React, { useState } from 'react';
import { Table, Tag, Radio } from 'antd';
import currencyFormatter from '../../Utils/currencyFormatter';
import { Target } from '../Molecules/Target';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { PDFReported } from '../Organisms/PDFReported';
import { BotonExcel } from '../Atoms/BotonExcel';

export const TablaReporteCatalogo = ({ data, loading, userRole }) => {
  const [descrip, setDescript] = useState(false);
  const [filterData, setFilterData] = useState('');
  const [filterActive, setFilterActive] = useState(false);

  const infoCat = data?.Caja_chica_or_amex || data?.Caja_chica || data?.Amex_BH || data?.Amex_PL;

  const dataGeneral = infoCat?.map((item) => {
    return {
      key: `${item.amex_or_cajachica}-${item.code}`,
      code: item.code,
      amex_or_cajachica: item.amex_or_cajachica,
      concepto_general: item.concepto_general,
      total: currencyFormatter(item.total),
      specific: item?.specific.map((item2) => ({
        key: item2.code_specific,
        codigo_es: item2.code_specific,
        concepto_es: item2.specific_concept,
        total_es: currencyFormatter(item2.total),
        solicitudes: item2.Purchase_requests.map((item3) => ({
          key: item3.id,
          id_sol: item3.id,
          fecha_sol: item3.created_at,
          descripcion_sol: item3.description,
          metodo_pago: item3.payment_method_id,
          total_sol: currencyFormatter(item3.total)
        }))
      }))
    };
  });

  const filteredDataGeneral = dataGeneral.filter(
    (item) =>
      (filterData === 'a' && item.amex_or_cajachica === 'Caja chica') ||
      (filterData === 'b' && item.amex_or_cajachica === 'Amex') ||
      filterData === ''
  );

  const columns = [
    {
      title: 'CÓDIGO',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: ' CONCEPTO GENERAL',
      dataIndex: 'concepto_general',
      key: 'concepto_general'
    },
    {
      title: 'MÉTODO DE PAGO',
      dataIndex: 'amex_or_cajachica',
      key: 'amex_or_cajachica',
      render: (payment) => {
        let color = payment === 'Amex' ? 'magenta' : payment === 'Caja chica' ? 'purple' : 'red';
        return <Tag color={color}>{payment}</Tag>;
      }
    },
    {
      title: 'TOTAL',
      dataIndex: 'total',
      key: 'total'
    }
  ];

  //Tabla concepto especifico
  const expandedRowRender = (record) => {
    const detallesColumns = [
      { title: 'CÓDIGO', dataIndex: 'codigo_es', key: 'codigo_es' },
      { title: 'CONCEPTO ESPECÍFICO', dataIndex: 'concepto_es', key: 'concepto_es' },
      { title: 'TOTAL', dataIndex: 'total_es', key: 'total_es' }
    ];
    return (
      <Table
        className="detalles-general"
        columns={detallesColumns}
        dataSource={record.specific}
        expandable={{
          expandedRowRender: expandedSolicitudRender
        }}
        pagination={false}
        loading={loading}
      />
    );
  };

  //Tabla solicitudes
  const expandedSolicitudRender = (recordsol) => {
    const solicitudesColumns = [
      {
        title: 'ID SOLICITUD',
        dataIndex: 'id_sol',
        key: 'id_sol',
        render: (text) => (
          <a href={`/detalle-soliciutd-${text}`} target="_blank" rel="noreferrer noopener">
            GTO-{text}
          </a>
        )
      },
      { title: 'TOTAL', dataIndex: 'total_sol', key: 'total_sol' },
      { title: 'FECHA', dataIndex: 'fecha_sol', key: 'fecha_sol' },
      descrip
        ? {
            title: 'DESCRIPCIÓN ',
            dataIndex: 'descripcion_sol',
            key: 'descripcion_sol'
          }
        : null
    ].filter(Boolean);
    return (
      <Table
        className="detalles-general2"
        columns={solicitudesColumns}
        dataSource={recordsol.solicitudes}
        pagination={false}
        loading={loading}
      />
    );
  };

  return (
    <>
      <div className="flex-row overflow-x mb-3 mt-4 card-class title-card">
        {['administrator', 'Super_Admin'].includes(userRole) && (
          <Target
            color={'description-red'}
            loading={loading}
            titulo={'Gastado Total'}
            total={data?.total_global || '0'}
          />
        )}
        {['administrator', 'Super_Admin', 'caja_chica'].includes(userRole) && (
          <Target
            color={'ml-3 description-purple'}
            loading={loading}
            titulo={'Gastado Caja Chica'}
            total={data?.totalCajaChica || data?.total_global || '0'}
          />
        )}
        {['administrator', 'Super_Admin'].includes(userRole) && (
          <Target
            color={'ml-3 description-magenta'}
            loading={loading}
            titulo={'Gastado Amex'}
            total={data?.totalAmex || data?.total_global || '0'}
          />
        )}
        {['administrator', 'Super_Admin', 'adquisiciones_pl'].includes(userRole) && (
          <Target
            color={'ml-3 description-pl'}
            loading={loading}
            titulo={'Gastado Amex PL'}
            total={data?.totalAmexPL || data?.total_global || '0'}
          />
        )}
        {['administrator', 'Super_Admin', 'adquisiciones_bh'].includes(userRole) && (
          <Target
            color={'ml-3 description-bh'}
            loading={loading}
            titulo={'Gastado Amex BH'}
            total={data?.totalBH || data?.total_global || '0'}
          />
        )}
      </div>

      {['administrator', 'Super_Admin'].includes(userRole) && (
        <div className="flex mb-4 align-items-center">
          <span className="mr-2">Filtrar por: </span>
          <Radio.Group
            defaultValue=""
            buttonStyle="solid"
            onChange={(e) => {
              setFilterData(e.target.value);
              setFilterActive(true);
            }}>
            <Radio value="">Ambos</Radio>
            <Radio value="a">Caja chica</Radio>
            <Radio value="b">Amex</Radio>
          </Radio.Group>
        </div>
      )}

      <div className="flex justify-content-between mb-4 align-items-center">
        <div>
          <span className="mr-2">Descripción:</span>
          {descrip === false ? (
            <Boton
              onClick={() => setDescript(true)}
              icon={<EyeInvisibleOutlined />}
              color="danger"
              variant="solid"
            />
          ) : (
            <Boton
              onClick={() => setDescript(false)}
              icon={<EyeOutlined />}
              color="primary"
              variant="outlined"
            />
          )}
        </div>

        <div className="flex justify-content-end">
          <PDFReported
            info={filterActive ? filteredDataGeneral : dataGeneral}
            total={currencyFormatter(
              filterData === ''
                ? data?.total_global
                : filterData === 'a'
                ? data?.totalCajaChica
                : filterData === 'b'
                ? data?.totalAmex
                : 'Error'
            )}
            userRole={userRole}
          />
        </div>
      </div>
      <BotonExcel
        tableId="tabla-reporte-catalogo"
        filename="Reporte Catalogo"
        sheetName="Reporte Catalogo"
      />

      <Table
        columns={columns}
        dataSource={filterActive ? filteredDataGeneral : dataGeneral}
        expandable={{
          expandedRowRender
        }}
        pagination={{
          position: ['bottomCenter']
        }}
        loading={loading}
      />
    </>
  );
};

TablaReporteCatalogo.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  userRole: PropTypes.string
};
