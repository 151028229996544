import React from 'react';
import Modal from '../Atoms/Modal';
import { Form, Input, Upload } from 'antd';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import Boton from '../Atoms/Boton';
import { PaperClipOutlined } from '@ant-design/icons';

export const ModalEgresarPresupuesto = ({
  isModalOpenEgress,
  showModalEgress,
  handleReturnMoney,
  totalCC,
  nameReturn,
  setArchive,
  setIsFileRemoved,
  archive,
  setNameReturn
}) => {
  return (
    <Modal
      title="EGRESAR PRESUPUESTO"
      centered
      open={isModalOpenEgress}
      onCancel={showModalEgress}
      onOk={handleReturnMoney}
      okText="Aceptar"
      cancelText="Cancelar">
      <Form
        labelCol={{
          span: 6
        }}
        labelAlign="left"
        wrapperCol={{
          span: 19
        }}>
        <Form.Item label="Nombre">
          <Input
            disabled
            value={currencyFormatter(totalCC)}
            defaultValue={currencyFormatter(totalCC)}
          />
        </Form.Item>
        <Form.Item label="Nombre">
          <Input
            value={nameReturn}
            placeholder="¿A quien regresa?"
            onChange={(e) => setNameReturn(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Archivo">
          <Upload
            maxCount={1}
            listType="text"
            accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .xls, .xlsx"
            beforeUpload={(file) => {
              setArchive(file);
              setIsFileRemoved(false);
              return false;
            }}
            onRemove={() => {
              setArchive(null);
              setIsFileRemoved(true);
            }}
            fileList={archive ? [archive] : []}
            multiple={false}>
            <Boton color="primary" variant="outlined" icon={<PaperClipOutlined />}>
              Adjuntar archivo o imagen
            </Boton>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalEgresarPresupuesto.propTypes = {
  isModalOpenEgress: PropTypes.bool,
  showModalEgress: PropTypes.func,
  handleReturnMoney: PropTypes.func,
  totalCC: PropTypes.string,
  nameReturn: PropTypes.string,
  setArchive: PropTypes.func,
  setIsFileRemoved: PropTypes.func,
  archive: PropTypes.object,
  setNameReturn: PropTypes.func
};
