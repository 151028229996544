import { Table } from 'antd';
import ReusableModal from '../Atoms/Modal';
import propTypes from 'prop-types';
import parseDate from '../../Utils/parseDate';
import { Link } from 'react-router-dom';

const ModalHistoryInspeccion = ({
  isOpenModalHistoryInspeccion,
  setModalHistoryInspeccion,
  dataHistoryInspeccion
}) => {
  const columsInspeccion = [
    {
      title: 'ID INSPECCIÓN',
      dataIndex: 'idInspeccion',
      key: 'idInspeccion'
    },
    {
      title: 'TIPO DE PRODUCTO',
      dataIndex: 'tipoProducto',
      key: 'tipoProducto'
    },
    {
      title: 'FECHA DE INSPECCIÓN',
      dataIndex: 'fechaInspeccion',
      key: 'fechaInspeccion'
    }
  ];

  const data = dataHistoryInspeccion?.map((item, index) => ({
    key: index,
    idInspeccion: (
      <Link to={`/consultar-inspeccion-calidad/${item?.code_inspection}`} className="text-blue-500">
        {item?.code_inspection}
      </Link>
    ),
    tipoProducto:
      item?.type_product === 'maquilado'
        ? 'Maquilado'
        : item?.type_product === 'limpio'
        ? 'Limpio'
        : null,
    fechaInspeccion: parseDate(item?.created_at, 'short')
  }));

  return (
    <ReusableModal
      open={isOpenModalHistoryInspeccion}
      onCancel={() => setModalHistoryInspeccion(false)}
      title="HISTORIAL DE INSPECCIONES"
      width={600}>
      <Table columns={columsInspeccion} dataSource={data} pagination={false}></Table>
    </ReusableModal>
  );
};

ModalHistoryInspeccion.propTypes = {
  isOpenModalHistoryInspeccion: propTypes.bool,
  setModalHistoryInspeccion: propTypes.func,
  dataHistoryInspeccion: propTypes.array
};
export default ModalHistoryInspeccion;
