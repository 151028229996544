import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { HistoryOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons';
import ModalHistoryRecep from '../Molecules/ModalHistroyRecep';
import ModalHistoryCoutProduct from '../Molecules/ModalHistoryCoutProduct';
import { useState } from 'react';
import ModalCreateRecepcions from './ModalCreateRecepcions';
import ModalConfirmConteo from '../Molecules/ModalConfirmConteo';

const TablaRecpcionInventario = ({ productRecepcion, loading, setLoading, id, decodedToken }) => {
  const [isOpenModalHistoryConfirmation, setModalHistoryConfirmation] = useState(false);
  const [isOpenModalHistoryRecepcion, setModalHistoryRecepcion] = useState(false);
  const [dataHistoryRecep, setDataHistoryRoute] = useState({});
  const [isOpenModalConfirmarConteo, setModalConfirmarConteo] = useState(false);
  const [isOpenReceptionProduct, setReceptionProduct] = useState(false);
  const [propData, setPropData] = useState({});
  let userArea = decodedToken?.user?.display_name_area;
  let userDeparment = decodedToken?.user?.display_name_department;
  let userRole = decodedToken?.role?.name;

  const columsTable2 = [
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'description',
      key: 'description',
      fixed: 'left'
    },
    {
      title: 'OC',
      dataIndex: 'oc',
      key: 'oc',
      width: 150
    }
    // {
    //   title: 'OT',
    //   dataIndex: 'ot',
    //   key: 'ot',
    //   width: 150
    // }
  ];

  if (
    userDeparment === 'ventas' ||
    userArea === 'mesa_de_control' ||
    userArea === 'sgcmc' ||
    userRole === 'Super_Admin' ||
    decodedToken?.sub === 280 ||
    userDeparment === 'direccion'
  ) {
    columsTable2.push({
      title: 'HISTORIALES',
      dataIndex: 'history',
      key: 'history'
    });
    if (userArea === 'sgcmc' || userRole === 'Super_Admin' || decodedToken?.sub === 280) {
      columsTable2.push({
        title: 'ACCIONES',
        dataIndex: 'acciones',
        key: 'acciones'
      });
    }
  } else if (userDeparment === 'almacen') {
    columsTable2.push({
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    });
  }

  const data2 = productRecepcion?.map((item, index) => {
    return {
      key: index,
      description: item?.description,
      oc: item?.code_order_oc,
      ot:
        JSON.parse(item?.code_order_ot).map((ot) => ot).length > 0 ? (
          JSON.parse(item?.code_order_ot)
            .map((ot) => ot + ' ')
            .join(', ')
        ) : (
          <Tag color="red">Sin OT asignadas</Tag>
        ),
      history: (
        <>
          {item?.reception_oc !== 0 ? (
            <Boton
              type="secondary"
              icon={<HistoryOutlined />}
              onClick={() => {
                setModalHistoryRecepcion(true);
                setDataHistoryRoute({
                  idProdutc: item?.product_id_oc
                });
              }}>
              Ver recepciones de inventario
            </Boton>
          ) : (
            <Tag color="red">No se han realizado recepciones de inventario</Tag>
          )}

          {item?.Products_Counts_History !== 0 ? (
            <Boton
              className="mt-2"
              type="tertiary-outlined"
              icon={<HistoryOutlined />}
              onClick={() => {
                setModalHistoryConfirmation(true);
                setDataHistoryRoute({
                  idProdutc: item?.product_id_oc
                });
              }}>
              Ver confirmaciones de conteo
            </Boton>
          ) : null}
        </>
      ),
      acciones: (
        <>
          {item?.deliverys_route?.length > 0 ? (
            item?.deliverys_route?.some(
              (item) =>
                (item?.type_of_destiny === 'Almacen PL' ||
                  item?.type_of_destiny === 'Almacen PM') &&
                item?.status_delivery === 'Completo'
            ) ? (
              <>
                <Boton
                  className="mb-2"
                  type="primary"
                  icon={<FileDoneOutlined />}
                  onClick={() => {
                    setReceptionProduct(true);
                    setPropData({
                      code_order: item?.code_order_oc,
                      product_id: item?.product_id_oc
                    });
                  }}>
                  Confirmar Recepción
                </Boton>

                {item?.reception_oc !== 0 ? (
                  <Boton
                    type="tertiary-outlined"
                    icon={<WarningOutlined />}
                    className="ml-2 mb-2 mt-2"
                    onClick={() => {
                      setModalConfirmarConteo(true);
                      setDataHistoryRoute({
                        idProdutc: item?.product_id_oc
                      });
                    }}>
                    Confirmar Conteo
                  </Boton>
                ) : null}
              </>
            ) : item?.deliverys_route?.some(
                (item) =>
                  item?.type_of_destiny === 'Maquila' && item?.status_delivery === 'Completo'
              ) && item?.deliverys_route?.some((item) => item?.type_of_destiny === 'Cliente') ? (
              <Boton
                type="tertiary-outlined"
                icon={<WarningOutlined />}
                className="mb-2"
                onClick={() => {
                  setModalConfirmarConteo(true);
                  setDataHistoryRoute({
                    idProdutc: item?.product_id_oc
                  });
                }}>
                Confirmar Conteo
              </Boton>
            ) : (
              <Tag color="red">No se han realizado entregas</Tag>
            )
          ) : (
            <Tag color="red">No se han realizado entregas</Tag>
          )}
        </>
      )
    };
  });

  return (
    <>
      <Table columns={columsTable2} dataSource={data2} pagination={false} />
      {isOpenReceptionProduct && (
        <ModalCreateRecepcions
          isOpenReceptionProduct={isOpenReceptionProduct}
          setReceptionProduct={setReceptionProduct}
          propData={propData}
          setLoading={setLoading}
          id={id}
          decodedToken={decodedToken}
          loading={loading}
        />
      )}

      <ModalHistoryRecep
        isOpen={isOpenModalHistoryRecepcion}
        setModalHistoryRecepcion={setModalHistoryRecepcion}
        dataHistoryRecep={dataHistoryRecep}
      />

      {isOpenModalConfirmarConteo && (
        <ModalConfirmConteo
          isOpenModalConfirmarConteo={isOpenModalConfirmarConteo}
          setModalConfirmarConteo={setModalConfirmarConteo}
          dataHistoryRecep={dataHistoryRecep}
          loading={loading}
          setLoading={setLoading}></ModalConfirmConteo>
      )}

      <ModalHistoryCoutProduct
        setModalHistoryConfirmation={setModalHistoryConfirmation}
        isOpenModalHistoryConfirmation={isOpenModalHistoryConfirmation}
        dataHistoryConfirmation={dataHistoryRecep}></ModalHistoryCoutProduct>
    </>
  );
};

TablaRecpcionInventario.propTypes = {
  productRecepcion: PropTypes.array,
  id: PropTypes.string,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  historyRecepcion: PropTypes.array,
  disable: PropTypes.bool,
  decodedToken: PropTypes.object
};

export default TablaRecpcionInventario;
