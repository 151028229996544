import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import { FileSearchOutlined, HistoryOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ModalHistoryInspeccion from '../Molecules/ModalHistoryInspeccion';
import decodeToken from '../../Utils/decodeToken';

const TablaInspeccion = ({ productInspeccion }) => {
  const [isOpenModalHistoryInspeccion, setModalHistoryInspeccion] = useState(false);
  const [dataHistoryInspeccion, setDataHistoryInspeccion] = useState([]);
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let userRole = decodedToken?.role?.name;

  const columsTableRecepInven2 = [
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'description',
      key: 'description',
      fixed: 'left'
    },
    {
      title: 'OC',
      dataIndex: 'oc',
      key: 'oc',
      width: 150
    },
    // {
    //   title: 'OT',
    //   dataIndex: 'ot',
    //   key: 'ot',
    //   width: 150
    // },
    {
      title: 'HISTORIALES',
      dataIndex: 'history',
      key: 'history'
    }
  ];

  if (
    userArea === 'calidad' ||
    userArea === 'sgcmc' ||
    userRole === 'Super_Admin' ||
    decodedToken?.sub === 280
  ) {
    columsTableRecepInven2.push({
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    });
  }

  const data2 = productInspeccion?.map((item, index) => ({
    key: index,
    description: item?.description,
    oc: item?.code_order_oc,
    ot:
      JSON.parse(item?.code_order_ot).map((ot) => ot).length > 0 ? (
        JSON.parse(item?.code_order_ot)
          .map((ot) => ot + ' ')
          .join(', ')
      ) : (
        <Tag color="red">Sin OT asignadas</Tag>
      ),
    history: (
      <>
        <>
          {item?.Inspection?.length > 0 ? (
            <Boton
              type="secondary"
              icon={<HistoryOutlined />}
              onClick={() => {
                setModalHistoryInspeccion(true);
                setDataHistoryInspeccion(item?.Inspection);
              }}>
              Historial de inspecciones de calidad
            </Boton>
          ) : (
            <Tag color="red">No se han echo inspeecciones</Tag>
          )}
        </>
      </>
    ),
    acciones: (
      <>
        <>
          {item?.Products_Counts_History !== 0 ? (
            <Link to={`crear-inspeccion-de-calidad/${item?.product_id_oc}`}>
              <Boton type="primary" icon={<FileSearchOutlined />}>
                Crear Inspección De Calidad
              </Boton>
            </Link>
          ) : (
            <Tag color="red">No hay conteo, no puedes inspeccionar</Tag>
          )}
        </>
      </>
    )
  }));

  return (
    <>
      <Table columns={columsTableRecepInven2} dataSource={data2} pagination={false} />

      <ModalHistoryInspeccion
        dataHistoryInspeccion={dataHistoryInspeccion?.reverse()}
        isOpenModalHistoryInspeccion={isOpenModalHistoryInspeccion}
        setModalHistoryInspeccion={setModalHistoryInspeccion}></ModalHistoryInspeccion>
    </>
  );
};

TablaInspeccion.propTypes = {
  productInspeccion: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

export default TablaInspeccion;
