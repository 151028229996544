import { Input, Select, Form, Spin } from 'antd';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import clienteAxios from '../../Utils/clienteAxios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import Modal from '../Atoms/Modal';
import decodeToken from '../../Utils/decodeToken';

const NewUser = ({
  modalUser,
  setModalUser,
  userAction,
  setUserAction,
  dataUser,
  setDataUser,
  scrollY
}) => {
  let title = 'title';
  if (Object.keys(dataUser).length <= 0) {
    title = 'AGREGAR USUARIO';
  } else {
    title = 'EDITAR USUARIO';
  }
  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userRole = decodedToken?.role?.name;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState([]);
  const [departmentId, setDepartmentId] = useState(0);
  const [companyId, setCompanyId] = useState();
  const [areaId, setAreaId] = useState();
  const [dataApi, setDataApi] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  useEffect(() => {
    setLoadingSpinner(true);
    clienteAxios
      .get(`/users/info/${departmentId}`)
      .then((response) => {
        setDataApi(response?.data);
        setLoadingSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [departmentId]);

  console.log('dataApi', dataApi);

  useEffect(() => {
    setName(dataUser?.name || '');
    setEmail(dataUser?.email || '');
    setRole(
      dataUser?.role_id !== undefined
        ? dataUser?.role_id
        : Object.keys(dataUser).length > 0
        ? 19 || []
        : []
    );
    setCompanyId(dataUser?.company_id || []);
    setDepartmentId(dataUser?.department_id || []);
    setAreaId(dataUser?.area_id || []);
    setLoadingSpinner(false);
  }, [dataUser]);

  let optionDepartments =
    dataApi?.Todos_los_departamentos?.map((department) => {
      return {
        value: department.id,
        label: department.name_department
      };
    }) || [];

  let optionAreas =
    dataApi?.Areas?.map((area) => {
      return {
        value: area.id,
        label: area.name_area
      };
    }) || [];

  let optionRoles = [];
  if (Array.isArray(dataApi?.Roles)) {
    optionRoles = dataApi?.Roles?.map((role) => {
      return {
        value: role.id,
        label: role.display_name
      };
    });
    if (userRole === 'Super_Admin') {
      optionRoles.push({ value: 1, label: 'Administrador' }, { value: 20, label: 'Super Admin' });
    }
  }

  const onCancel = () => {
    setModalUser(false);
    setDataUser({});
    document.body.classList.remove('body-fixed');
    window.scrollTo(0, scrollY);
  };

  const handleOk = () => {
    setModalUser(false);
    document.body.classList.remove('body-fixed');
    window.scrollTo(0, scrollY);
  };

  let createNewUser = {
    name: name,
    email: email,
    role_id: role,
    id_company: companyId,
    id_department: departmentId,
    id_area: areaId
  };

  let updateUser = {
    name: name,
    email: email,
    role_id: parseInt(role),
    id_company: companyId,
    id_department: departmentId,
    id_user: dataUser?.id,
    id_area: areaId
  };

  const OptionEnterprise = [
    { value: 1, label: 'Promo Life' },
    { value: 2, label: 'BH Trade Market' },
    { value: 3, label: 'Promo Zale' },
    { value: 4, label: 'Trade Market 57' }
  ];

  const handleClickCreateUser = () => {
    console.log('createNewUser', createNewUser);

    clienteAxios
      .post(`/users`, createNewUser)
      .then(() => {
        Swal.fire({
          title: 'Usuario creado correctamente',
          text: 'El usuario se ha creado correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          setModalUser(false);
          setUserAction(!userAction);
          setDataUser({});
          document.body.classList.remove('body-fixed');
          window.scrollTo(0, scrollY);
        });
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'Error al crear el usuario',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      });
  };

  function actualizarUser() {
    Swal.fire({
      title: '¿Deseas actualizar este usuario?',
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: 'Si, actualizar',
      cancelButtonText: 'No, cancelar'
    })
      .then((result) => {
        if (result.isConfirmed) {
          clienteAxios.post(`/users/edit`, updateUser).then((result) => {
            console.log(result);
            Swal.fire({
              title: 'Usuario actualizado correctamente',
              text: 'El usuario se ha actualizado correctamente',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              setModalUser(false);
              setUserAction(!userAction);
              setDataUser({});
              document.body.classList.remove('body-fixed');
              window.scrollTo(0, scrollY);
            });
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: 'Error',
          text: 'No se ha podido actualizar el usuario',
          icon: 'error',
          confirmButtonText: 'INTENTAR DE NUEVO'
        });
      });
  }

  return (
    <>
      <Modal
        title={title}
        centered
        open={modalUser}
        onCancel={onCancel}
        onOk={handleOk}
        footer={null}>
        <Spin spinning={loadingSpinner}>
          <div className="mt-4">
            <div className="mt-4">
              <Form
                labelCol={{
                  span: 10
                }}
                labelAlign="left"
                wrapperCol={{
                  span: 14
                }}>
                <Form.Item label="Nombre Completo">
                  <Input
                    type="text"
                    placeholder="Esciba el nombre completo"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Correo Electrónico">
                  <Input
                    type="text"
                    placeholder="Escibe el correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Empresa">
                  <Select
                    placeholder="Selecciona empresa"
                    direction="vertical"
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={setCompanyId}
                    value={companyId}
                    options={OptionEnterprise}
                  />
                </Form.Item>
                <Form.Item label="Departamento">
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={optionDepartments}
                    placeholder="Selecciona departamento"
                    onChange={setDepartmentId}
                    value={departmentId}></Select>
                </Form.Item>
                <Form.Item label="Área">
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Selecciona área"
                    options={optionAreas}
                    onChange={setAreaId}
                    value={areaId}></Select>
                </Form.Item>
                <Form.Item label="Rol">
                  <Select
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={setRole}
                    options={optionRoles}
                    placeholder="Selecciona rol"
                    value={role}></Select>
                </Form.Item>
                <div className="justify-content-end">
                  <Boton
                    type="primary"
                    htmlType="submit"
                    onClick={
                      Object.keys(dataUser).length <= 0 ? handleClickCreateUser : actualizarUser
                    }>
                    {Object.keys(dataUser).length <= 0 ? 'Crear Usuario' : 'Editar Usuario'}
                  </Boton>
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

NewUser.propTypes = {
  modalUser: PropTypes.bool,
  setModalUser: PropTypes.func,
  userAction: PropTypes.bool,
  setUserAction: PropTypes.func,
  dataUser: PropTypes.object,
  setDataUser: PropTypes.func,
  setScrollY: PropTypes.func,
  scrollY: PropTypes.number
};

export default NewUser;
