import React, { useEffect, useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import { Alert, Form, Input, Radio } from 'antd';
import Boton from '../Atoms/Boton';
import clienteAxios from '../../Utils/clienteAxios';
import Swal from 'sweetalert2';

const ModalConfirmConteo = ({
  setModalConfirmarConteo,
  isOpenModalConfirmarConteo,
  dataHistoryRecep,
  loading,
  setLoading
}) => {
  const [form] = Form.useForm();
  const [informationRecep, setInformationRecep] = useState([]);
  const [countinformationRecep, setCountInformationRecep] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loadCounter, setLoadCounter] = useState(0); // Counter to track the loading status
  const [almacen, setAlmacen] = useState('');
  useEffect(() => {
    if (Object.keys(dataHistoryRecep).length !== 0) {
      clienteAxios
        .get(`/rutas-de-entrega/show/${dataHistoryRecep?.idProdutc}`)
        .then((response) => {
          setInformationRecep(response?.data?.data?.ruta);
          setLoadCounter((prev) => prev + 1); // Increment the counter
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpenModalConfirmarConteo]);

  useEffect(() => {
    if (Object.keys(dataHistoryRecep).length !== 0) {
      clienteAxios
        .get(`product/count/confirmation/history/${dataHistoryRecep?.idProdutc}`)
        .then((response) => {
          setCountInformationRecep(response?.data?.Product_count_history?.reverse());
          setLoadCounter((prev) => prev + 1); // Increment the counter
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpenModalConfirmarConteo]);

  useEffect(() => {
    if (loadCounter === 2) {
      // Check if both requests are completed
      let initialValue =
        option_filteredDatastatus_deliveryComplete?.[0]?.value ||
        option_filteredDatastatus_deliveryComplete?.[1]?.value;
      setAlmacen(initialValue);
      form.setFieldsValue({ destiny: initialValue }); // Update form field value
      setDataLoading(true); // Set data loading to true
    }
  }, [loadCounter]);

  let filteredData = [];
  if (informationRecep?.length > 0) {
    const lastRecord = informationRecep?.reduce((acc, curr) => {
      if (!acc[curr.type_of_destiny]) {
        acc[curr.type_of_destiny] = [];
      }
      acc[curr.type_of_destiny].push(curr);
      return acc;
    }, {});

    filteredData = Object.keys(lastRecord).map((key) => {
      const dates = lastRecord[key].map((item) => new Date(item.created_at));
      const maxDate = new Date(Math.max.apply(null, dates));
      return lastRecord[key].find(
        (item) => new Date(item.created_at).getTime() === maxDate.getTime()
      );
    });
  }

  const hasAlmacenPLCompleto = filteredData.some(
    (item) => item.type_of_destiny === 'Almacen PL' && item.status_delivery === 'Completo'
  );

  let filterAlmacenPL = [];
  if (hasAlmacenPLCompleto) {
    filterAlmacenPL = filteredData.filter(
      (item) => item.type_of_destiny === 'Almacen PL' && item.status_delivery === 'Completo'
    );
  }

  const hasMaquilaCompleto = filteredData.some(
    (item) => item.type_of_destiny === 'Maquila' && item.status_delivery === 'Completo'
  );

  let filterMaquila = [];
  if (hasMaquilaCompleto) {
    filterMaquila = filteredData.filter(
      (item) => item.type_of_destiny === 'Maquila' && item.status_delivery === 'Completo'
    );
  }

  const hasAlmacenPMCompleto = filteredData.some(
    (item) => item.type_of_destiny === 'Almacen PM' && item.status_delivery === 'Completo'
  );
  let filterAlmacenPM = [];
  if (hasAlmacenPMCompleto) {
    filterAlmacenPM = filteredData.filter(
      (item) => item.type_of_destiny === 'Almacen PM' && item.status_delivery === 'Completo'
    );
  }

  const hasClienteCompleto = filteredData.some((item) => item.type_of_destiny === 'Cliente');

  let filterAlmacenPLorPM = [];
  if (hasAlmacenPLCompleto && hasAlmacenPMCompleto) {
    filterAlmacenPLorPM = filteredData.filter(
      (item) =>
        (item.type_of_destiny === 'Almacen PL' && item.status_delivery === 'Completo') ||
        (item.type_of_destiny === 'Almacen PM' && item.status_delivery === 'Completo')
    );
  }

  let filterAlmacenPLorMaquila = [];
  if (hasAlmacenPLCompleto && hasMaquilaCompleto && hasClienteCompleto) {
    filterAlmacenPLorMaquila = filteredData.filter(
      (item) =>
        (item.type_of_destiny === 'Almacen PL' && item.status_delivery === 'Completo') ||
        (item.type_of_destiny === 'Maquila' && item.status_delivery === 'Completo')
    );
  }

  let filteredDatastatus_deliveryComplete =
    filterAlmacenPLorPM.length > 0
      ? filterAlmacenPLorPM
      : filterAlmacenPLorMaquila.length > 0
      ? filterAlmacenPLorMaquila
      : filterAlmacenPL.length > 0
      ? filterAlmacenPL
      : filterAlmacenPM.length > 0
      ? filterAlmacenPM
      : filterMaquila.length > 0
      ? filterMaquila
      : ['No hay datos para mostrar'];

  const option_filteredDatastatus_deliveryComplete = filteredDatastatus_deliveryComplete?.map(
    (item) => ({
      value: item?.type_of_destiny,
      label: item?.type_of_destiny
    })
  );

  const onCancel = () => {
    setModalConfirmarConteo(false);
    form.resetFields();
  };

  const handleReceptionProduct = (values) => {
    values.id_product = dataHistoryRecep?.idProdutc;
    values.confirmation_type = verType;
    values.type = almacen;
    handlePostReceptionProduct(values);
  };

  let verType = filteredData?.find((item) => item?.type_of_destiny === almacen)?.type;
  let find = countinformationRecep?.find((item) => item?.type === almacen);

  const handlePostReceptionProduct = (values) => {
    values?.confirmation_type !== undefined
      ? find?.confirmation_type === verType && find?.type === almacen
        ? Swal.fire({
            width: 600,
            icon: 'warning',
            title: 'Ya se realizó el conteo con este destino y tipo de recepción'
          })
        : Swal.fire({
            title: '¿Estás seguro de confirmar el conteo?',
            icon: 'question',
            confirmButtonText: 'Confirmar',
            denyButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              clienteAxios
                .post('/product/count/confirmation', values)
                .then((response) => {
                  console.log('response', response);
                  Swal.fire({
                    title: 'Conteo confirmado',
                    icon: 'success'
                  }).then(() => {
                    setModalConfirmarConteo(false);
                    form.resetFields();
                    setLoading(!loading);
                  });
                })
                .catch((error) => {
                  console.error(error);
                  Swal.fire({
                    icon: 'error',
                    title: error?.response?.data?.message
                  });
                  setLoading(!loading);
                  setModalConfirmarConteo(false);
                  setAlmacen('');
                });
            }
          })
      : Swal.fire({
          width: 600,
          icon: 'error',
          title: 'No puedes confirmar el conteo sin que se tenga confirmado el tipo de recepción'
        });
  };

  return (
    <>
      {dataLoading ? (
        <ReusableModal
          title="CONFIRMAR CONTEO"
          onCancel={onCancel}
          open={isOpenModalConfirmarConteo}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}>
          <Alert
            className="mb-2"
            message="Recuerda haber confirmado el tipo de recepción antes de confirmar el conteo."
            type="warning"
            showIcon
          />

          <Form form={form} onFinish={handleReceptionProduct}>
            <Form.Item
              name="destiny"
              label="Tipo Producto:"
              initialValue={almacen}
              rules={[{ required: true, message: 'Este campo es requerido' }]}>
              <Radio.Group onChange={(e) => setAlmacen(e.target.value)}>
                {option_filteredDatastatus_deliveryComplete?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Tipo de recepción">
              <Input disabled={true} value={verType} className="text-align-center"></Input>
            </Form.Item>

            <Form.Item name="observation" label="Observaciones">
              <Input.TextArea style={{ width: 260 }} className="text-align-center"></Input.TextArea>
            </Form.Item>

            <div className="justify-content-end">
              <Boton danger onClick={onCancel} className="mr-2">
                Cancelar
              </Boton>

              <Boton
                type="primary"
                htmlType="submit"
                disabled={option_filteredDatastatus_deliveryComplete?.length === 0 ? true : false}>
                Confirmar Conteo
              </Boton>
            </div>
          </Form>
        </ReusableModal>
      ) : null}
    </>
  );
};

ModalConfirmConteo.propTypes = {
  setModalConfirmarConteo: PropTypes.func,
  isOpenModalConfirmarConteo: PropTypes.bool,
  dataHistoryRecep: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func
};

export default ModalConfirmConteo;
