import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
// import '../../Assets/Styles/Atoms/Boton.css';

// BUTTON TYPES
// primary   -> boton con fondo azul letras blancas
// secondary  -> boton con fondo blanco letras azul
// tertiary-filled -> boton con fondo verde letras blancas
// tertiary-outlined  -> boton con borde verde letras verde
// danger -> boton con fondo rojo letras blancas

export default function Boton(props) {
  return (
    <Button
      type={props.type}
      color={props.color}
      variant={props.variant}
      shape={props.shape}
      icon={props.icon}
      size={props.size}
      onClick={props.onClick}
      danger={props.danger}
      ghost={props.ghost}
      style={props.style}
      block={props.block}
      loading={props.loading}
      disabled={props.disabled}
      className={props.className}
      htmlType={props.htmlType}>
      {props.children}
    </Button>
  );
}

Boton.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  danger: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  icon: PropTypes.object,
  className: PropTypes.string,
  block: PropTypes.bool,
  htmlType: PropTypes.string,
  warning: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  shape: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  ghost: PropTypes.bool,
  loading: PropTypes.bool
};
