import React from 'react';
// import TablaListaPedidos from '../Components/Organisms/TablaListaPedidos';
import TablaListaPed from '../Components/Organisms/v2TablaListaPedidos';

const Pedidos = () => {
  return (
    <>
      <div className="flex flex-wrap justify-content-between">
        <h1 className="mb-2">pedidos</h1>
      </div>
      {/* <TablaListaPedidos /> */}
      <TablaListaPed />
    </>
  );
};

export default Pedidos;
