import { Input, Space, Table } from 'antd';
import React, { useState, useRef } from 'react';
import { SearchOutlined, StopOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import localeObjectTable from '../../Utils/localeObjectTable';
import PropTypes from 'prop-types';
import Boton from '../Atoms/Boton';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';

const TablaManagers = ({ dataManager, loading }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: '0 8px 8px 8px'
        }}
        onKeyDown={(e) => e.stopPropagation()}>
        <Boton
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
          className="button-close-search-filter-table">
          X
        </Boton>
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Boton
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small">
            Buscar
          </Boton>
          <Boton onClick={() => clearFilters && handleReset(clearFilters)} danger size="small">
            Limpiar
          </Boton>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const tablaReporte = [
    {
      title: 'DEPARTAMENTO',
      dataIndex: 'Name_of_the_Department',
      key: 'Name_of_the_Department',
      ...getColumnSearchProps('Name_of_the_Department'),
      sorter: (a, b) => a.Name_of_the_Department.localeCompare(b.Name_of_the_Department),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'NOMBRE MANAGER',
      dataIndex: 'Name_of_the_Manager',
      key: 'Name_of_the_Manager',
      ...getColumnSearchProps('Name_of_the_Manager'),
      sorter: (a, b) => a.Name_of_the_Manager.localeCompare(b.Name_of_the_Manager),
      sortDirections: ['ascend', 'descend']
    },
    {
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    }
  ];

  const newDataSOL = dataManager?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      Name_of_the_Department: item?.Name_of_the_Department,
      Name_of_the_Manager: item?.Name_of_the_Manager,
      acciones: (
        <>
          <Boton
            className="btn-danger"
            danger
            icon={<StopOutlined />}
            onClick={() => deleteManager(item.id)}
          />
        </>
      )
    };
  });

  const deleteManager = async (id) => {
    Swal.fire({
      title: 'Elliminar Manager',
      text: '¿Estás seguro de elimnar este Manager?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        clienteAxios
          .post(`/sg/deactivate-manager-department`, { id: id })
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: '¡Desactivada!',
              text: 'El Manager ha sido eliminado.',
              confirmButtonText: 'Aceptar'
            });
          })
          .catch((error) => {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: '¡Error!',
              text: 'No se pudo eliminar el Manager.',
              confirmButtonText: 'Aceptar'
            });
          });
      }
    });
  };

  return (
    <>
      <Table
        loading={loading}
        columns={tablaReporte}
        dataSource={newDataSOL}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 5,
          pageSizeOptions: [3, 6, 9, 80, 100],
          showTotal: (total, range) =>
            `Mostrando empresas del ${range[0]} al ${range[1]} de un total de ${total}`
        }}
        className="mt-4 mb-4"
        locale={localeObjectTable}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

TablaManagers.propTypes = {
  dataManager: PropTypes.array,
  dataUsers: PropTypes.array,
  loading: PropTypes.bool
};
export default TablaManagers;
