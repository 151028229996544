import React from 'react';
import { Card } from 'antd';
import currencyFormatter from '../../Utils/currencyFormatter';
import PropTypes from 'prop-types';

export const Target = ({ loading, titulo, total, color }) => {
  const { Meta } = Card;

  return (
    <Card className={color} loading={loading}>
      <Meta title={titulo} description={currencyFormatter(total)} />
    </Card>
  );
};

Target.propTypes = {
  loading: PropTypes.bool,
  titulo: PropTypes.string,
  total: PropTypes.string || PropTypes.number,
  color: PropTypes.string
};
