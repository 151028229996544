import React, { useState, useEffect } from 'react';
import { Input, Radio, Alert, Space, Form, Spin, message, InputNumber } from 'antd';
import { useParams } from 'react-router-dom';
import Boton from '../Components/Atoms/Boton';
import CrearFirmas from '../Components/Organisms/CrearFirmas';
import { useNavigate } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import decodeToken from '../Utils/decodeToken';
import clienteAxios from '../Utils/clienteAxios';
import moment from 'moment';
import Swal from 'sweetalert2';
import TablaProductsIncidence from '../Components/Organisms/TablaProductsIncidence';
import UploadPhoto from '../Components/Molecules/UploadPhoto';
import Card from '../Components/Organisms/Card';
import getSampleQuantity from '../Utils/getSampleQuantity';
import getMaximumOfPiecesToReject from '../Utils/getMaximumPiecesToReject';
import OptionsInspection from '../Components/Molecules/OptionsInspection';
const { TextArea } = Input;

const CrearInspeccionCalidad = () => {
  const [firmaElaboro, setFirmaElaboro] = useState();
  const [loading, setLoading] = useState(true);
  const [pedidoData, setPedidoData] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [productQuantity, setProductQuantity] = useState([{ quantity_selected: 0 }]);
  const [quantityRecep, setQuantityRecep] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [firmaRecepcion, setFirmaRecepcion] = useState('');
  const [firmaEmpaque, setFirmaEmpaque] = useState('');
  const [firmaLogistica, setFirmaLogistica] = useState('');
  const [stateOfQualityData, setStateOfQualityData] = useState({
    total: 0,
    muestra: 0,
    maximoPiezasRechazar: 0
  });
  const [inputSize, setInputSize] = useState('middle');
  const [form] = Form.useForm();

  const updateInputSize = () => {
    if (window.innerWidth <= 422) {
      setInputSize('small');
    } else {
      setInputSize('middle');
    }
  };

  useEffect(() => {
    // Agregar un event listener para detectar cambios en el tamaño de la ventana
    window.addEventListener('resize', updateInputSize);
    // Llamar a la función una vez al cargar el componente para establecer el tamaño inicial
    updateInputSize();
    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener('resize', updateInputSize);
  }, []); // El array vacío como segundo argumento asegura que esto solo se ejecute una vez

  const navigate = useNavigate();
  let { idPedido } = useParams();
  let { idProducto } = useParams();
  let decodedToken = decodeToken(localStorage.getItem('token'));

  let arrayFiltrado = productQuantity.filter(
    (elemento) => elemento !== undefined && elemento?.quantity_selected !== 0
  );

  useEffect(() => {
    clienteAxios
      .get(`/pedido/${idPedido}`)
      .then((response) => {
        let data = response.data;
        setPedidoData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [idPedido]);
  useEffect(() => {
    let objTemp = {};
    objTemp.total = productQuantity[0]?.quantity_selected;
    objTemp.muestra = getSampleQuantity(productQuantity[0]?.quantity_selected);
    objTemp.maximoPiezasRechazar = getMaximumOfPiecesToReject(
      productQuantity[0]?.quantity_selected
    );
    setStateOfQualityData(objTemp);
  }, [productQuantity]);

  let productOrder = pedidoData?.products_orders?.filter(
    (order) => order.id === parseInt(idProducto)
  );

  useEffect(() => {
    form.setFieldsValue({
      ['evidenciaDeProductosAfectados']: fileList
    });
  }, [fileList]);

  const handleClickCreateIncidence = (values) => {
    console.log('handleClickCreateIncidence', values);
    setFormSubmitted(true);
    values.sale_id = pedidoData?.additional_information?.id;
    values.date_inspeccion = moment().format('YYYY-MM-DD');
    values.user_signature_created = firmaElaboro;
    values.quantity_revised = stateOfQualityData?.total;
    values.quantity_denied = stateOfQualityData?.maximoPiezasRechazar;
    values.sample = stateOfQualityData?.muestra;
    values.odoo_product_id = arrayFiltrado[0]?.odoo_product_id;
    values.code_order = arrayFiltrado[0]?.code_order;
    values.quantity_selected = arrayFiltrado[0]?.quantity_selected;
    values.value1 = 'inspection_logistics_true';
    values.inspection_logistics = {
      complete_boxes: values?.logistica0,
      master_box_with_defect: values?.logistica1,
      incorrect_identification_label: values?.logistica2,
      inadequate_stowage_by_weight: values?.logistica3,
      incorrect_documentation: values?.logistica4,
      incorrect_merchandise_vs_invoice: values?.logistica5,
      logistics_signature: firmaLogistica
    };

    values.value2 = 'inspection_packagings_true';
    values.inspection_packagings = {
      merchandise_with_defect: values?.empaque0,
      uncombined_merchandise: values?.empaque1,
      incomplete_pieces: values?.empaque2,
      master_box_not_correct: values?.empaque3,
      incorrect_box_taping: values?.empaque4,
      corrugated_with_defect: values?.empaque5,
      packaging_signature: firmaEmpaque
    };

    values.value3 = 'inspection_receipts_true';
    values.inspection_receipts = {
      merchandise_with_defect: values?.recepcion0,
      wrong_color_or_pantone: values?.recepcion1,
      damage_to_logo: values?.recepcion2,
      wrong_logo: values?.recepcion3,
      incomplete_pieces: values?.recepcion4,
      uncombined_merchandise: values?.recepcion5,
      receipt_signature: firmaRecepcion
    };

    handleUpload(fileList, values);
  };

  const handleUpload = (fileList, valuesOfForm) => {
    console.log('valuesOfForm', valuesOfForm);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });
    Swal.fire({
      title: '¿Estás seguro de crear la inspección?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed && fileList?.length > 0) {
        clienteAxios
          .post('pedido/files/inspections', formData)
          .then((response) => {
            let imageUrlArr = response.data.images;
            message.success('La imagen se subió correctamente.');
            valuesOfForm.files = JSON.stringify(imageUrlArr);
            postQualityInspection(valuesOfForm);
            setLoading(true);
          })
          .catch((error) => {
            setLoading(true);

            console.error(error);
            message.error('Hubo un error al subir la imagen.');
          });
      } else {
        message.error('Debes subir una imagen.');
      }
    });
  };

  const postQualityInspection = (values) => {
    console.log('values', values);
    clienteAxios
      .post(`/pedido/${idPedido}/inspections/${idProducto}`, values)
      .then((response) => {
        console.log(response);
        setLoading(false);
        Swal.fire({
          title: 'Inspección de calidad creada',
          text: 'La inspección de calidad se ha creado correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(() => {
          navigate(`/pedidos/${idPedido}`);
        });
      })
      .catch((error) => {
        setLoading(true);
        console.error(error);
        Swal.fire({
          title: 'Error',
          text: 'Ha ocurrido un error al crear la inspección de calidad',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      });
  };

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        <h1>
          <ArrowLeftOutlined style={{ fontSize: '2rem' }} onClick={() => navigate(-1)} /> crear
          inspección de calidad
        </h1>
        <div id="content">
          <Form
            layout="vertical"
            autoComplete="off"
            onFinish={handleClickCreateIncidence}
            scrollToFirstError={{
              behavior: (actions) => {
                return actions.forEach(({ top }) => {
                  window.scrollTo({
                    top: top - 150,
                    left: 0,
                    behavior: 'smooth'
                  });
                });
              }
            }}>
            <Card>
              <div className="ml-4 mr-4 mb-2">
                <strong>Empresa: </strong>
                <span>{pedidoData?.additional_information?.Company}</span>
              </div>

              <div className="ml-4 mr-4 mb-2">
                <strong>Proveedor: </strong>
                <span>{productOrder?.[0]?.provider_name}</span>
              </div>
            </Card>
            <div className="flex flex-column align-items-center">
              <Card>
                <Form.Item
                  label={<span className="font-weight-500">N° Inspeccion</span>}
                  name="inspection_number"
                  initialValue={0}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor indica el tipo de producto!'
                    }
                  ]}>
                  <InputNumber min={0} className="centered-input" />
                </Form.Item>

                <Form.Item
                  className="ml-5"
                  label={<span className="font-weight-500">Tipo de producto</span>}
                  name="type_product"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor indica el tipo de producto!'
                    }
                  ]}>
                  <Radio.Group
                    size={inputSize}
                    options={[
                      { label: 'Limpio', value: 'limpio' },
                      { label: 'Maquilado', value: 'maquilado' }
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>
              </Card>
            </div>

            <h2 className="">PRODUCTO</h2>
            <TablaProductsIncidence
              visible={false}
              data={productOrder}
              productSelec={true}
              productQuantity={productQuantity}
              setProductQuantity={setProductQuantity}></TablaProductsIncidence>

            <h2 className="mt-3">DATOS DE NIVELES DE CALIDAD</h2>
            <div className="flex flex-column align-items-center">
              <Card>
                <div className="ml-2 mr-2">
                  <strong>TOTAL DE PIEZAS: </strong>
                  <span>{stateOfQualityData?.total}</span>
                </div>

                <div className="ml-2 mr-2">
                  <strong>MUESTRA: </strong>
                  <span>{stateOfQualityData?.muestra}</span>
                </div>

                <div className="ml-2 mr-2">
                  <strong>MÁXIMO DE PIEZAS A RECHAZAR: </strong>
                  <span>{stateOfQualityData?.maximoPiezasRechazar}</span>
                </div>
              </Card>
              <Alert
                description="En esta sección ingresa el número de productos que tienen un defecto en una o más características."
                type="info"
                showIcon
                className="mb-4"
              />
            </div>
            <OptionsInspection
              quantityRecep={quantityRecep}
              setQuantityRecep={setQuantityRecep}
              firmaRecepcion={firmaRecepcion}
              setFirmaRecepcion={setFirmaRecepcion}
              firmaEmpaque={firmaEmpaque}
              setFirmaEmpaque={setFirmaEmpaque}
              firmaLogistica={firmaLogistica}
              setFirmaLogistica={setFirmaLogistica}></OptionsInspection>

            <h2 className="mt-4">EVIDENCIA DE PRODUCTOS AFECTADOS</h2>
            <Form.Item
              className="mt-2"
              name="evidenciaDeProductosAfectados"
              label="Fotografía de los productos afectados">
              <UploadPhoto setFileList={setFileList} fileList={fileList} />
            </Form.Item>
            <h2 className="mt-5 text-align-center" name="observations">
              OBSERVACIONES
            </h2>
            <Form.Item className="mt-3 mb-4 flex justify-content-center" name="observations">
              <TextArea
                placeholder="Escribe aquí tus observaciones..."
                rows={3}
                cols={73}
                allowClear
              />
            </Form.Item>
            <div className="mt-5">
              <CrearFirmas
                nombreElaboro={decodedToken?.user?.name}
                firmaElaboro={firmaElaboro}
                setFirmaElaboro={setFirmaElaboro}
                formSubmitted={formSubmitted}
              />
            </div>

            <div className="justify-content-end mt-3">
              <Space align="end" direction="horizontal">
                <Boton type="primary" htmlType="submit">
                  Crear Inspección De Calidad
                </Boton>
              </Space>
            </div>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default CrearInspeccionCalidad;
