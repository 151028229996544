import { Empty } from 'antd';

const localeObjectTable = {
  emptyText: <Empty description="No hay información." />,
  triggerDesc: 'haz clic para ordenar descendente',
  triggerAsc: 'haz clic para ordenar ascendente',
  cancelSort: 'haz clic para cancelar ordenamiento'
};

export default localeObjectTable;
