import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Table, InputNumber, Form, Tooltip } from 'antd';
import { ImportOutlined, FileTextOutlined, CheckCircleOutlined } from '@ant-design/icons';
import Boton from '../Atoms/Boton';
import PropTypes from 'prop-types';
import currencyFormatter from '../../Utils/currencyFormatter';
import { GlobalFuctions } from '../../Context/GlobalContext';
import decodeToken from '../../Utils/decodeToken';
const TablaCompras = ({
  data,
  tableType,
  buttonsColumn,
  productsSelection,
  tableTitle,
  quantitySelectedObj,
  setQuantitySelectedObj,
  // setTotalSelectedProducts,
  expectedDeliveredQuantityColumn,
  selectionGet
}) => {
  const { fullOrders } = useContext(GlobalFuctions);
  let decodedToken = decodeToken(localStorage.getItem('token'));

  let pedidos = [{}];

  useEffect(() => {
    if (localStorage.getItem('Orders')) {
      localStorage.removeItem('Orders');
    }
  }, []);

  const handleProductQuantitySelect = ({
    code_order,
    productId,
    quantity_selected,
    odoo_product_id,
    index,
    ped_amount,
    productIndex
  }) => {
    let copyOfArr = [...quantitySelectedObj];
    copyOfArr[index][productIndex] = {
      odoo_product_id: odoo_product_id,
      productId: productId,
      // quantity_selected: quantity_selected || ped_amount,
      quantity_selected: quantity_selected !== ' ' ? quantity_selected : ped_amount,
      // me deja ingresar cantidades vacias
      ped_amount: ped_amount,
      code_order: code_order
    };
    //Remove empty index from array
    let newQuantitySelectedObj = copyOfArr.map((order) => order.filter((product) => product != 0));
    //The previous array will lead empty sets of array, so we need to filter them to clenaup the array
    let cleanedUpQuantitySelectedObj = newQuantitySelectedObj.filter((n) => n.length > 0);

    //Sum all the values of the quantity_selected property
    let totalSelectedProductsAux = cleanedUpQuantitySelectedObj.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.reduce((acc, curr) => acc + curr.quantity_selected, 0);
      },
      0
    );

    console.log('totalSelectedProductsAux', totalSelectedProductsAux);
    // setTotalSelectedProducts(totalSelectedProductsAux);
    setQuantitySelectedObj(copyOfArr);
  };

  const expanderInfoOC = (record, orderIndex) => {
    if (data[orderIndex]['products'] && record) {
      data[orderIndex]['products'].map((pedido) => {
        pedidos = fullOrders(pedido, record);
        localStorage.setItem('Orders', JSON.stringify(pedidos));
      });
    }

    const dataTablaOTv2 = filteredDataOC[orderIndex]?.products?.map((ped, productIndex) => {
      let objTemp = {
        key: ped.id,
        descripcion: ped?.description,
        cantidadtotal: ped?.quantity,
        unidaddemedida: 'pieza',
        subtotal: currencyFormatter(ped?.subtotal)
      };

      if (productsSelection) {
        objTemp.cantidadseleccionada = (
          <Form.Item className="mb-0">
            <InputNumber
              min={0}
              max={ped?.amount || ped?.quantity}
              placeholder={0}
              value={
                quantitySelectedObj[orderIndex][productIndex]?.quantity_selected !== 0 &&
                quantitySelectedObj[orderIndex][productIndex]?.quantity_selected !== null
                  ? quantitySelectedObj[orderIndex][productIndex]?.quantity_selected ||
                    ped?.amount ||
                    ped?.quantity_selected
                  : ped?.quantity_selected
              }
              onChange={(value) =>
                handleProductQuantitySelect({
                  odoo_product_id: ped.odoo_product_id,
                  productId: ped.id,
                  quantity_selected: value,
                  ped_amount: ped?.amount,
                  code_order: record.referencia,
                  index: orderIndex,
                  productIndex: productIndex
                })
              }
            />
          </Form.Item>
        );
      } else if (tableType === 'all') {
        objTemp.cantidadseleccionada = ped?.quantity_selected;
      } else {
        objTemp.cantidadrecibida = ped?.quantity_delivered;
      }

      if (expectedDeliveredQuantityColumn) {
        objTemp.cantidadesperada = ped?.amount || ped?.expected_delivery_quantity;
        objTemp.cantidadrealentregada = ped?.delivered_quantity;
      }

      return objTemp;
    });

    let expectedDeliveredQuantityColumnObj = {};

    if (expectedDeliveredQuantityColumn) {
      expectedDeliveredQuantityColumnObj = {
        title: 'CANTIDAD ESPERADA DE ENTREGA',
        dataIndex: 'cantidadesperada',
        key: 'cantidadesperada'
      };
    }

    const columsinfoOT = [
      {
        title: 'DESCRIPCIÓN',
        dataIndex: 'descripcion',
        key: 'descripcion',
        fixed: 'left'
      },
      {
        title: 'CANTIDAD TOTAL',
        dataIndex: 'cantidadtotal',
        key: 'cantidadtotal'
      },
      expectedDeliveredQuantityColumnObj,
      {
        title:
          productsSelection || selectionGet
            ? 'CANTIDAD SELECCIONADA'
            : expectedDeliveredQuantityColumn
            ? 'CANTIDAD REAL ENTREGADA'
            : decodedToken?.role?.name != 'maquilador'
            ? 'CANTIDAD RECIBIDA'
            : null,
        dataIndex:
          productsSelection || selectionGet
            ? 'cantidadseleccionada'
            : expectedDeliveredQuantityColumn
            ? 'cantidadrealentregada'
            : decodedToken?.role?.name != 'maquilador'
            ? 'cantidadrecibida'
            : null,
        key:
          productsSelection || selectionGet
            ? 'cantidadseleccionada'
            : expectedDeliveredQuantityColumn
            ? 'cantidadrealentregada'
            : 'cantidadrecibida'
      }
    ];

    return (
      <Table columns={columsinfoOT} dataSource={dataTablaOTv2} pagination={false} size="small" />
    );
  };

  let columnButtonOC = {};

  if (buttonsColumn) {
    columnButtonOC = {
      title: 'ACCIONES',
      dataIndex: 'acciones',
      key: 'acciones'
    };
  }

  const columnasOC = [
    {
      title: 'REFERENCIA',
      dataIndex: 'referencia',
      key: 'referencia',
      fixed: 'left'
    },

    {
      title: tableType === 'OC' ? 'PROVEEDOR' : tableType === 'OT' ? 'MAQUILADOR' : 'PROVEEDOR',
      dataIndex: 'proveedor',
      key: 'proveedor'
    },
    {
      title: 'DESCRIPCIÓN',
      dataIndex: 'descripcion',
      key: 'descripcion',
      fixed: 'left'
    },
    {
      title: 'CANTIDAD TOTAL',
      dataIndex: 'cantidadtotal',
      key: 'cantidadtotal'
    },
    {
      title:
        productsSelection || selectionGet
          ? 'CANTIDAD SELECCIONADA'
          : expectedDeliveredQuantityColumn
          ? 'CANTIDAD REAL ENTREGADA'
          : decodedToken?.role?.name != 'maquilador'
          ? 'CANTIDAD RECIBIDA'
          : null,
      dataIndex:
        productsSelection || selectionGet
          ? 'cantidadseleccionada'
          : expectedDeliveredQuantityColumn
          ? 'cantidadrealentregada'
          : decodedToken?.role?.name != 'maquilador'
          ? 'cantidadrecibida'
          : null,
      key:
        productsSelection || selectionGet
          ? 'cantidadseleccionada'
          : expectedDeliveredQuantityColumn
          ? 'cantidadrealentregada'
          : 'cantidadrecibida'
    },

    columnButtonOC
  ];

  let filteredDataOC = data;
  if (tableType === 'OC' || tableType === 'OT') {
    filteredDataOC = data?.filter((a) => a?.code_order?.substring(0, 2) === tableType);
  }

  const newDataOC = filteredDataOC?.map((item) => {
    let accionesButtons = {};
    let haveAllProductsBeenDelivered = true;
    let cantidadPedida = item?.products?.map((productospedidos) =>
      parseInt(productospedidos?.quantity)
    );
    let cantidadTotalRecibida = item?.products?.map((productosrecibidos) =>
      parseInt(productosrecibidos?.quantity_delivered)
    );

    let totalCantidadPedida =
      cantidadPedida.length > 0
        ? cantidadPedida.reduce((total, cantidad) => total + cantidad, 0)
        : 0;
    let totalCantidadTotalRecibida =
      cantidadTotalRecibida.length > 0
        ? cantidadTotalRecibida.reduce((total, cantidad) => total + cantidad)
        : 0;

    totalCantidadPedida === totalCantidadTotalRecibida
      ? (haveAllProductsBeenDelivered = true)
      : (haveAllProductsBeenDelivered = false);

    if (buttonsColumn) {
      accionesButtons = {
        acciones: (
          <>
            {decodedToken?.role?.name === 'almacen' || decodedToken?.role?.name === 'maquilador' ? (
              <>
                {haveAllProductsBeenDelivered === true ? (
                  <Tooltip
                    placement="top"
                    title="¡Ya se han hecho todas las recepciones!"
                    color={'var(--secondary-color-red)'}
                    arrowPointAtCenter>
                    <Boton
                      size="small"
                      type="primary"
                      className="mb-2"
                      disabled
                      icon={<ImportOutlined />}>
                      Hacer recepción de inventario
                    </Boton>{' '}
                  </Tooltip>
                ) : (
                  <Boton size="small" type="primary" className="mb-2" icon={<ImportOutlined />}>
                    Hacer recepción de inventario
                  </Boton>
                )}
                <Boton size="small" className="mb-2" type="secondary" icon={<FileTextOutlined />}>
                  Ver recepción de inventario
                </Boton>

                {tableType === 'OT' && decodedToken?.role?.name != 'maquilador' && (
                  <>
                    <Boton
                      size="small"
                      type="tertiary-filled"
                      className="mb-2"
                      icon={<CheckCircleOutlined />}>
                      Cambiar estado de OT
                    </Boton>
                    <Boton type="tertiary-outlined" size="small" icon={<FileTextOutlined />}>
                      Ver estado de OT
                    </Boton>
                  </>
                )}
              </>
            ) : (
              <>
                <Boton size="small" className="mb-2" type="secondary" icon={<FileTextOutlined />}>
                  Ver recepción de inventario
                </Boton>

                {tableType === 'OT' && decodedToken?.role?.name != 'maquilador' && (
                  <>
                    <Boton
                      size="small"
                      type="tertiary-filled"
                      className="mb-2"
                      icon={<CheckCircleOutlined />}>
                      Cambiar estado de OT
                    </Boton>
                  </>
                )}

                {tableType === 'OT' && (
                  <>
                    <Boton type="tertiary-outlined" size="small" icon={<FileTextOutlined />}>
                      Ver estado de OT
                    </Boton>
                  </>
                )}
              </>
            )}
          </>
        )
      };
    }

    return {
      key: item?.id,
      referencia: item?.code_order,
      proveedor: item?.provider_name,
      ...accionesButtons
    };
  });

  return (
    <>
      <h2 className="mb-2 mt-4">{tableTitle || ''}</h2>
      <Table
        columns={columnasOC}
        dataSource={newDataOC}
        pagination={false}
        className="tabla-OC"
        expandable={{
          expandedRowRender: expanderInfoOC,
          defaultExpandedRowKeys: ['0']
        }}
        scroll={{
          x: true
        }}
      />
    </>
  );
};

function validationPropsOfProductsSelection(props, propName, componentName) {
  if (props.productsSelection) {
    if (!props[propName]) {
      return new Error(
        `The prop \`${propName}\` is required if product selection is true. Its value is \`${props[propName]}\`. This happen in \`${componentName}\`.`
      );
    }
  }
}

TablaCompras.defaultProps = {
  tableTitle: '',
  data: [],
  buttonsColumn: true,
  productsSelection: false,
  expectedDeliveredQuantityColumn: false,
  selectionGet: false
};

TablaCompras.propTypes = {
  tableTitle: PropTypes.string,
  data: PropTypes.array.isRequired,
  tableType: PropTypes.oneOf(['OC', 'OT', 'all']).isRequired,
  buttonsColumn: PropTypes.bool,
  productsSelection: PropTypes.bool,
  quantitySelectedObj: validationPropsOfProductsSelection,
  setQuantitySelectedObj: validationPropsOfProductsSelection,
  // setTotalSelectedProducts: validationPropsOfProductsSelection,
  expectedDeliveredQuantityColumn: PropTypes.bool,
  selectionGet: PropTypes.bool,
  setReceptionsAction: PropTypes.func,
  receptionsAction: PropTypes.bool,
  setLoadingDetalle: PropTypes.func,
  loadingDetalle: PropTypes.bool,
  setScrollY: PropTypes.func,
  scrollY: PropTypes.number
};

export default TablaCompras;
