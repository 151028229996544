import React, { useState } from 'react';
import { DatePicker, message, Button } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import es from 'antd/lib/date-picker/locale/es_ES';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import TablaSolicitudesCompra from '../../Components/Organisms/TablaSolicitudesCompra';

export default function FilterRequest({
  startOfMonth,
  endOfMonth,
  setFechainial,
  setFechafinal,
  handleDating,
  isButton,
  setFilterActive,
  setDataFilterMonth,
  filterActive,
  dataFilterMonth,
  dataRequests,
  loading,
  unnamed
}) {
  startOfMonth = dayjs().startOf('month');
  endOfMonth = dayjs().endOf('month');
  const { RangePicker } = DatePicker;

  //recupera el rango de fechas seleccionado en el localstorage

  const fechainicial = localStorage.getItem('fechainicial');
  const fechafinal = localStorage.getItem('fechafinal');
  const [dateFilter, setDateFilter] = useState(false);

  //comprueba si existen fechas en el localstorage si si el estado sea true de lo contrario false con un ternario

  if (fechainicial && fechafinal) {
    dateFilter ? null : setDateFilter(true);
  }

  console.log('dateFilter', dateFilter);

  return (
    <>
      <div className="flex-filter align-items-center mt-3">
        <p className="mr-button mb-button">Solicitudes mostradas de: </p>
        <div className="flex-row align-items-center">
          <RangePicker
            format="DD-MM-YYYY"
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            locale={es}
            defaultValue={[startOfMonth, endOfMonth]}
            onChange={(dates) => {
              if (dates) {
                setFechainial(dates[0].format('YYYY-MM-DD'));
                setFechafinal(dates[1].format('YYYY-MM-DD'));
                //guardar el rango de fechas seleccionado en el localstorage
                localStorage.setItem('fechainicial', dates[0].format('YYYY-MM-DD'));
                localStorage.setItem('fechafinal', dates[1].format('YYYY-MM-DD'));
              }
            }}
          />
          <Button
            color="primary"
            variant="solid"
            shape="circle"
            className="ml-2"
            disabled={isButton}
            onClick={() => {
              handleDating();
            }}
            icon={<SearchOutlined />}
          />
          <Button
            color="danger"
            variant="outlined"
            shape="circle"
            className="ml-2"
            disabled={isButton}
            onClick={() => {
              setFilterActive(false);
              setDataFilterMonth([]);
              message.success('Filtros eliminados con éxito');
              localStorage.removeItem('fechainicial');
              localStorage.removeItem('fechafinal');
              setDateFilter(false);
            }}
            icon={<ClearOutlined />}
          />
        </div>
      </div>
      <TablaSolicitudesCompra
        data={filterActive ? dataFilterMonth : dataRequests}
        loading={loading}
        unnamed={unnamed}
      />
    </>
  );
}

FilterRequest.propTypes = {
  handleDating: PropTypes.func,
  isButton: PropTypes.bool,
  setFilterActive: PropTypes.func,
  setDataFilterMonth: PropTypes.func,
  filterActive: PropTypes.bool,
  dataFilterMonth: PropTypes.array,
  dataRequests: PropTypes.array,
  loading: PropTypes.bool,
  startOfMonth: PropTypes.object,
  endOfMonth: PropTypes.object,
  setFechainial: PropTypes.func,
  setFechafinal: PropTypes.func,
  unnamed: PropTypes.bool
};
