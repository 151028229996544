import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';

export default function StatusBar({ status, substatus, approved }) {
  let localPosition =
    status.trim() === 'pedido-en-proceso'
      ? 0
      : status.trim() === 'pedido-en-compra'
      ? 1
      : status.trim() === 'pedido-entregado' || status.trim() === 'pedido-devolución'
      ? 2
      : status.trim() === 'pedido-recibido'
      ? 3
      : 0;

  let localStatus = substatus === 'normal' ? 'process' : 'error';

  return (
    <div>
      <Steps
        size="small"
        current={localPosition}
        status={
          approved.trim() === 'rechazada' || approved.trim() === 'devolución'
            ? 'error'
            : localPosition === 3
            ? 'finish'
            : localStatus
        }
        responsive={false}
        items={[
          {
            title: approved.trim() === 'rechazada' ? 'Rechazado' : 'En Proceso',
            className: status.trim() === 'pedido-recibido' ? 'custom-step-aprobada' : ''
          },
          {
            title: substatus.trim() === 'cancelado' ? 'Rechazado' : 'Adquisición',
            className: status.trim() === 'pedido-recibido' ? 'custom-step-aprobada' : ''
          },
          {
            title: approved.trim() === 'devolución' ? 'Devolución' : 'Entregado',
            description:
              approved.trim() === 'devolución' && substatus.trim() === 'normal'
                ? 'Completa'
                : substatus.trim() === 'rechazada'
                ? 'Rechazada'
                : substatus.trim() === 'en proceso'
                ? 'En Proceso'
                : '',
            className:
              approved.trim() === 'devolución' && substatus.trim() === 'normal'
                ? 'custom-step-devolucion-completa'
                : approved.trim() === 'devolución' && substatus.trim() === 'en proceso'
                ? 'custom-step-devolucion'
                : approved.trim() === 'devolución' && substatus.trim() === 'rechazada'
                ? 'custom-step-devolucion-rechazada'
                : status.trim() === 'pedido-recibido'
                ? 'custom-step-aprobada'
                : ''
          },
          {
            title: 'Recibido',
            className: status.trim() === 'pedido-recibido' ? 'custom-step-aprobada' : ''
          }
        ]}
      />
    </div>
  );
}

StatusBar.propTypes = {
  status: PropTypes.string,
  substatus: PropTypes.string,
  approved: PropTypes.string
};
