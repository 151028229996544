import { Checkbox, ConfigProvider, DatePicker, Select, Table, Input, Tabs } from 'antd';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import es_ES from 'antd/locale/es_ES';
import decodeToken from '../../Utils/decodeToken';
import Swal from 'sweetalert2';
import clienteAxios from '../../Utils/clienteAxios';

const ModalMultiplesProducts = ({
  openMultipleProducts,
  setOpenMultipleProducts,
  orders_groups,
  code_sale
}) => {
  const [updateDelibery, setUpdateDelibery] = useState([]);
  const [productCheck, setProductCheck] = useState({});
  const [locale] = useState(es_ES);
  const { TextArea } = Input;
  const { Option } = Select;

  let decodedToken = decodeToken(localStorage.getItem('token'));
  let userArea = decodedToken?.user?.display_name_area;
  let isRole = decodedToken?.role?.name;

  const handleCloseModal = () => {
    setOpenMultipleProducts(false);
  };

  const columnsTableTrazaProduc = [
    {
      title: 'Producto',
      dataIndex: 'producto',
      key: 'producto'
    },
    {
      title: 'CHECK',
      dataIndex: 'check',
      key: 'check'
    }
  ];

  const dataUpdateDelibery = [
    {
      destino: 'Almacen PL'
    },
    {
      destino: 'Maquila'
    },
    {
      destino: 'Almacen PM'
    },
    {
      destino: 'Cliente'
    }
  ];

  const filterDataRole =
    userArea === 'sgcmc' || isRole === 'Super_Admin' || decodedToken?.sub === 280
      ? dataUpdateDelibery
      : userArea === 'compras'
      ? dataUpdateDelibery.filter(
          (item) =>
            item.destino === 'Almacen PL' ||
            item.destino === 'Maquila' ||
            item.destino === 'Almacen PM'
        )
      : userArea === 'mesa_de_control'
      ? dataUpdateDelibery.filter((item) => item.destino === 'Cliente')
      : null;

  const optionStatus_delivery = [
    {
      value: 'Completo',
      label: 'Completo'
    },
    {
      value: 'Pendiente',
      label: 'Pendiente'
    },
    {
      value: 'Reprogramado',
      label: 'Reprogramado'
    }
  ];

  const filterOptionStatus_delivery =
    userArea === 'sgcmc' || isRole === 'Super_Admin' || decodedToken?.sub === 280
      ? optionStatus_delivery
      : userArea === 'compras' || userArea === 'mesa_de_control'
      ? optionStatus_delivery.filter(
          (item) => item.value === 'Pendiente' || item.value === 'Reprogramado'
        )
      : null;

  const handleChangeTipe = (index, field, value, destino) => {
    {
      setUpdateDelibery((prevState) => {
        const updatedDelivery = [...prevState];
        updatedDelivery[index] = {
          ...updatedDelivery[index],
          // code_order: propData?.code_order,
          // product_id: propData?.product_id,
          type_of_destiny: destino,
          [field]: value
        };

        setUpdateDelibery(updatedDelivery);
      });
    }
  };

  const CreateMultipleProducts = () => {
    const filterProducts = Object.values(productCheck).filter(
      (product) => product.checked === true
    );
    let postArray = [];
    filterProducts.map((product) => {
      postArray.push({
        code_order: product?.value?.code_order_oc,
        product_id: product?.value?.product_id_oc
      });
    });

    let combineData = updateDelibery.flatMap((item) => {
      return postArray.map((product) => {
        return [item, product];
      });
    });

    combineData = combineData.map((item) => {
      return {
        ...item[0],
        ...item[1]
      };
    });

    handlePostData(combineData);
  };

  let arrayNumberProperties = [];

  const handlePostData = (data) => {
    console.log('data', data);
    let agregar = data?.map((item) => {
      if (!Object.prototype.hasOwnProperty.call(item, 'status_delivery')) {
        return { ...item, status_delivery: 'Pendiente' };
      }
      return item;
    });

    agregar?.length > 0
      ? agregar.forEach((element) => {
          const numProperties = Object.keys(element).length;
          arrayNumberProperties = [...arrayNumberProperties, numProperties];
          if (element !== undefined && arrayNumberProperties.every((numero) => numero > 6)) {
            Swal.fire({
              title: '¿Estás seguro de actualizar la ruta?',
              icon: 'question',
              confirmButtonText: 'Aceptar',
              denyButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                clienteAxios
                  .post(`rutas-de-entrega/${code_sale}`, { delivery_route: agregar })
                  .then((response) => {
                    console.log('response', response);
                    Swal.fire({
                      icon: 'success',
                      title: '¡Ruta actualizada con éxito!'
                    }).then(() => {
                      document.body.classList.remove('body-fixed');
                      window.scrollTo(0, scrollY);
                      // setModalUpdateRoute(false);
                      // setLoading(!loading);
                      setUpdateDelibery([]);
                    });
                  })
                  .catch((error) => {
                    console.log('error', error);
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...'
                    });
                    document.body.classList.remove('body-fixed');
                    window.scrollTo(0, scrollY);
                    // setModalUpdateRoute(false);
                    // setLoading(!loading);
                    setUpdateDelibery([]);
                  });
              }
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '¡No has llenado todos los campos de una fila!'
            });
          }
        })
      : Swal.fire({
          icon: 'error',
          title: '¡No se ha seleccionado ningun producto!'
        });
  };

  const datsSource = orders_groups.map((order, index) => {
    return {
      key: order.product_id_oc,
      producto: order.description,
      check: (
        <>
          <Checkbox
            onChange={(e) =>
              setProductCheck({
                ...productCheck,
                [index]: { checked: e.target.checked, value: order }
              })
            }
          />
        </>
      )
    };
  });

  const itemTabs = [
    {
      key: '1',
      label: 'Productos',
      children: (
        <>
          <Table
            columns={columnsTableTrazaProduc}
            pagination={false}
            dataSource={datsSource}
            className="mb-3"
          />
        </>
      )
    },
    {
      key: '2',
      label: 'Destinos',
      children: (
        <>
          <Table
            columns={[
              {
                title: 'DESTINO',
                dataIndex: 'destino',
                key: 'destino',
                fixed: 'left'
              },
              {
                title: 'FECHA DE ENTREGA',
                dataIndex: 'fecha_entrega',
                key: 'fecha_entrega',
                render: (_, record, index) => {
                  return (
                    <ConfigProvider locale={locale}>
                      <DatePicker
                        allowClear={false}
                        onChange={(date, dateString) =>
                          handleChangeTipe(index, 'date_of_delivery', dateString, record.destino)
                        }
                      />
                    </ConfigProvider>
                  );
                }
              },
              {
                title: 'TIPO',
                dataIndex: 'tipo',
                key: 'tipo',
                render: (_, record, index) => (
                  <>
                    <Select
                      style={{ width: 100 }}
                      onChange={(value) => handleChangeTipe(index, 'type', value, record.destino)}
                      options={[
                        {
                          value: 'Parcial',
                          label: 'Parcial'
                        },
                        {
                          value: 'Total',
                          label: 'Total'
                        }
                      ]}
                    />
                  </>
                )
              },
              {
                title: 'ESTADO',
                dataIndex: 'estado',
                key: 'estado',
                render: (_, record, index) => (
                  <>
                    <Select
                      style={{ width: 100 }}
                      options={filterOptionStatus_delivery}
                      onChange={(value) =>
                        handleChangeTipe(index, 'status_delivery', value, record.destino)
                      }
                    />
                  </>
                )
              },
              {
                title: 'OBSERVACIÓN',
                dataIndex: 'observation',
                key: 'observation',
                render: (_, record, index) => (
                  <>
                    <TextArea
                      rows={2}
                      onChange={(e) =>
                        handleChangeTipe(index, 'observation', e.target.value, record.destino)
                      }
                    />
                  </>
                )
              },
              {
                title: 'TIPO DE ENVÍO',
                dataIndex: 'type_send',
                key: 'type_send',
                render: (_, record, index) => (
                  <Select
                    style={{ width: 100 }}
                    onChange={(value) =>
                      handleChangeTipe(index, 'shipping_type', value, record.destino)
                    }>
                    <Option value="Interno">Interno</Option>
                    <Option value="Externo">Externo</Option>
                    <Option value="Paqueteria">Paqueteria</Option>
                  </Select>
                )
              }
            ]}
            dataSource={filterDataRole.map((item, index) => ({
              ...item,
              key: index
            }))}
            pagination={false}
            scroll={{ y: 300, x: 1000 }}
          />
        </>
      )
    }
  ];

  return (
    <>
      <ReusableModal
        title="Trazabilidad de productos"
        open={openMultipleProducts}
        visible={true}
        okText="Crear"
        onOk={CreateMultipleProducts}
        onCancel={handleCloseModal}
        width={1000}>
        <>
          <Tabs defaultActiveKey="1" items={itemTabs} />
        </>
      </ReusableModal>
    </>
  );
};

ModalMultiplesProducts.propTypes = {
  openMultipleProducts: PropTypes.bool,
  setOpenMultipleProducts: PropTypes.func,
  orders_groups: PropTypes.array,
  code_sale: PropTypes.string
};

export default ModalMultiplesProducts;
