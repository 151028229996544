import React, { useEffect, useState } from 'react';
import TablaEgresoEfectivo from '../Components/Organisms/TablaEgresoEfectivo';
import TablaIngresoEfectivo from '../Components/Organisms/TablaIngresoEfectivo';
import { ArrowLeftOutlined } from '@ant-design/icons';
import clienteAxios from '../Utils/clienteAxios';
import { useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

const HistorialPresupuesto = () => {
  const [egressHistory, setEgressHistory] = useState([]);
  const [incomeHistory, setIncomeHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    clienteAxios
      .get(`/sg/view-budget-return`)
      .then((response) => {
        let data = response.data.history;
        data.reverse();
        setEgressHistory(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    clienteAxios
      .get(`/sg/view-income`)
      .then((response) => {
        let data = response.data.Information;
        data.reverse();
        setIncomeHistory(data);
        setLoad(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const items = [
    {
      key: '1',
      label: 'Ingresado',
      children: <TablaIngresoEfectivo data={incomeHistory} loading={load} />
    },
    {
      key: '2',
      label: 'Egresado',
      children: <TablaEgresoEfectivo data={egressHistory} loading={loading} />
    }
  ];

  return (
    <>
      <h1 className="mb-3">
        <ArrowLeftOutlined className="arrow-size" onClick={() => navigate('/reporte-general')} />
        HISTORIAL DE PRESUPUESTO
      </h1>
      <Tabs defaultActiveKey="1" items={items} />;
    </>
  );
};

export default HistorialPresupuesto;
