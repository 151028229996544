import React, { useEffect, useState } from 'react';
import ReusableModal from '../Atoms/Modal';
import PropTypes from 'prop-types';
import clienteAxios from '../../Utils/clienteAxios';
import { Button, Col, Row, Table, Tag } from 'antd';
import parseDate from '../../Utils/parseDate';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';

const ModalHistoryRecep = ({ isOpen, setModalHistoryRecepcion, dataHistoryRecep }) => {
  const [informationRecep, setInformationRecep] = useState([]);
  const [loading, setLoading] = useState('');
  const [modalFirma, setModalFirma] = useState(false);
  const [dataSignature, setDataSignature] = useState('');

  useEffect(() => {
    if (Object.keys(dataHistoryRecep).length !== 0) {
      setLoading(true);
      clienteAxios
        .get(`history/confirmation/route/product/${dataHistoryRecep?.idProdutc}`)
        .then((response) => {
          setInformationRecep(response?.data?.HistoryConfirmationRoutes?.reverse());
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isOpen]);

  console.log('informationRecep', informationRecep);
  const onCancel = () => {
    setModalHistoryRecepcion(false);
  };

  const onCancelFirma = () => {
    setModalFirma(false);
  };

  const columsHistory = [
    {
      title: 'ALMACÉN',
      dataIndex: 'almacen',
      key: 'almacen'
    },
    {
      title: 'TIPO',
      dataIndex: 'tipo',
      key: 'tipo'
    },
    {
      title: 'FECHA DE RECEPCIÓN',
      dataIndex: 'fecha_recepcion',
      key: 'fecha_recepcion'
    },
    {
      title: 'RESPONSABLE',
      dataIndex: 'responsable',
      key: 'responsable'
    },
    {
      title: 'FIRMA',
      dataIndex: 'firma',
      key: 'firma'
    }
  ];

  const data = informationRecep.map(
    (item, index) => (
      console.log('item', item),
      {
        key: index,
        almacen: item?.destination,
        tipo: (
          <>
            <Tag color={item?.reception_type === 'Total' ? 'green' : 'orange'}>
              {item?.reception_type}
            </Tag>
          </>
        ),
        fecha_recepcion: (
          <>
            <Row>
              <Col xs={24} md={12}>
                {parseDate(item?.created_at, 'short')}
              </Col>
              <Col xs={24} md={12}>
                {moment(item?.created_at).format('LT  ')}
              </Col>
            </Row>
          </>
        ),
        responsable: item?.name,
        firma: (
          <>
            {item?.signature === '' || item?.signature === null ? (
              <Tag color="red">Sin firma</Tag>
            ) : (
              <>
                <div className="flex justify-content-between ">
                  <img
                    src={item?.signature}
                    alt="firma"
                    style={{ width: '100px', height: '50px' }}
                  />
                  <Button
                    icon={<EyeOutlined />}
                    type="link"
                    onClick={() => {
                      setModalFirma(true);
                      setDataSignature(item?.signature);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )
      }
    )
  );

  return (
    <>
      <ReusableModal title="HISTORIAL DE RECEPCIONES" open={isOpen} onCancel={onCancel} width={900}>
        <Table
          columns={columsHistory}
          dataSource={data}
          loading={loading}
          pagination={false}></Table>
      </ReusableModal>

      <ReusableModal
        title="FIRMA"
        open={modalFirma}
        onCancel={onCancelFirma}
        width={400}
        footer={null}>
        <div style={{ border: '1px solid #000', padding: '10px' }}>
          <img src={dataSignature} alt="firma" />
        </div>
      </ReusableModal>
    </>
  );
};

ModalHistoryRecep.propTypes = {
  isOpen: PropTypes.bool,
  setModalHistoryRecepcion: PropTypes.func,
  dataHistoryRecep: PropTypes.object
};

export default ModalHistoryRecep;
